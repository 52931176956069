<template>
  <div>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :table-data="calTableData"
      :table-loading="tableLoading"
    >
    </grid-layout>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
export default {
  name: "measure",
  components: {
    GridLayout,
    HeadLayout,
  },
  props: {
    tableData: {
      default: [],
      type: Array
    }
  },
  data() {
    return {
      tableLoading: true,
      dataTotal: null,
      teamForm: {},
      searchExpression: '',
      searchAlias: '',
      query: {
        expression: '',
        alias: ''
      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    searchColumns() {
      return [
        // {
        //   prop: "expression",
        //   span: 6,
        //   placeholder: this.$t("cip.index.measure.expression"),
        // },
        // {
        //   prop: "alias",
        //   span: 6,
        //   placeholder: this.$t("cip.index.measure.alias"),
        // },
      ]
    },
    tableOptions() {
      return {
        index: false,
        menu: false,
        customAddBtn: true,
        column: [
          {
            label: this.$t('cip.index.measure.expression'),
            prop: 'expression',
            overHidden: true
          },
          {
            label: this.$t('cip.index.measure.alias'),
            prop: 'alias',
            overHidden: true
          },
          {
            label: this.$t('cip.index.measure.description'),
            prop: 'description',
            overHidden: true
          },
          {
            label: this.$t('cip.index.measure.dataType'),
            prop: 'dataType',
            align: 'center',
            overHidden: true
          },
          // {
          //   label: this.$t('cip.index.measure.auxiliaryCalc'),
          //   prop: 'auxiliaryCalc',
          //   cell: true,
          //   align: 'center',
          // },
        ],
      }
    },
    calTableData() {
      return this.tableData.filter(e => {
        console.log('calTableData:',e)
        return (!this.searchExpression || e.expression.indexOf(this.searchExpression) !== -1) && (!this.searchAlias || e.alias.indexOf(this.searchAlias) !== -1)
      })
    }
  },
  mounted() {
    this.tableLoading = false;
  },
  methods: {
    gridHeadSearch(query) {
      this.searchExpression = query.expression
      this.searchAlias = query.alias
    },
    getSaveInfo() {
      return this.tableData;
    },
    gridHeadEmpty(query) {
      this.searchExpression = query.expression
      this.searchAlias = query.alias
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .avue-crud .el-table {
  height: calc(100vh - 600px) !important;
  max-height: calc(100vh - 600px) !important;
}
</style>
