<template>
  <div>
    <el-steps :active="step"
              finish-status="success"
              simple>
      <el-step :title="$t('cip.plat.wfDesign.model.title.designFormsHeadTitle')"
               icon="el-icon-edit"></el-step>
      <el-step :title="$t('cip.plat.wfDesign.model.title.designProcessHeadTitle')"
               icon="el-icon-upload">
        <template #title>
          {{$t('cip.plat.wfDesign.model.title.designProcessHeadTitle')}}
          <el-tooltip v-show="step == '1'"
                      :content="$t('cip.plat.wfDesign.model.field.fullScreen')">
            <i class="el-icon-full-screen"
               @click="handleFullScreen"></i>
          </el-tooltip>
        </template>
      </el-step>
      <el-step :title="$t('cip.plat.wfDesign.model.title.doneHeadTitle')"
               icon="el-icon-circle-check"></el-step>
    </el-steps>

    <div v-show="step == 0">
      <avue-form style="margin-bottom: 66px;padding: 12px"
                 ref="form1"
                 class="form-design"
                 :option="step1.option"
                 v-model="step1.form">
        <template #tip>
          <el-link type="primary"
                   :underline="false"
                   @click="$router.push('/plugin/workflow/design/form')">{{$t('cip.plat.wfDesign.model.msg.form')}}</el-link>
        </template>
        <template #form>
          <avue-form v-if="option && ((option.column && option.column.length > 0) || (option.group && option.group.length > 0))"
                     ref="form2"
                     v-model="form"
                     :option="option"></avue-form>
        </template>
      </avue-form>
    </div>
    <div v-if="step == 1">
      <wf-design id="bpmn2"
                 ref="bpmn2"
                 style="height: calc(100vh - 290px); background: white;"
                 :options="step2.option"></wf-design>
    </div>
    <div v-if="step == 2">
      <wf-design ref="bpmn3"
                 style="height: calc(100vh - 290px)"
                 :options="step3.option"></wf-design>
    </div>

    <div class="foot-item"
         :style="{width: layoutType == 'functional'?'100%':isCollapse? 'calc(100% - 68px)': 'calc(100% - 220px)' }">
      <el-button type="primary"
                 size="medium"
                 v-if="step > 0"
                 @click="step--">{{$t('cip.plat.wfDesign.model.btn.prev')}}</el-button>
      <el-button type="primary"
                 size="medium"
                 v-if="step < 2"
                 @click="handleNextStep">{{$t('cip.plat.wfDesign.model.btn.next')}}</el-button>
      <el-button type="primary"
                 size="medium"
                 v-if="step == 2"
                 @click="handleSave">{{$t('cip.cmn.btn.saveBtn')}}</el-button>
    </div>
  </div>
</template>

<script>
import { getDetail as getFormByKey } from '@/api/plugin/workflow/form'
import { submit, getDetail } from '@/api/plugin/workflow/model'
import { getList as buttonList } from '@/api/plugin/workflow/button'
import { getList as formList } from '@/api/plugin/workflow/form'
import { getList as conditionList } from '@/api/plugin/workflow/condition'

import { fullscreenToggel } from "@/util/util";

import { mapGetters } from 'vuex'

export default {
  name: 'design',
  computed: {
    ...mapGetters(['tag', 'isCollapse', 'language','layoutType']),
  },
  watch: {
    '$route.params.id': {
      handler(val) {
        if (!val || val == 0) return
        getDetail(val).then(res => {
          this.process = res.data.data
          const { formKey, xml, exForm } = this.process
          this.$set(this.step2.option, 'xml', xml)
          this.$set(this.step2.option, 'process', this.process)
          if (formKey.startsWith('wf_ex_')) { // 外置表单
            const column = []
            exForm.forEach(ex => {
              column.push({
                label: ex.name,
                prop: ex.id,
                readable: true,
                writable: true
              })
            })
            this.$set(this.step1.form, 'column', column)
            this.$set(this.step1.form, 'formType', 2)
            this.$set(this.step1.form, 'exFormKey', formKey.substring(6))
          } else if (formKey.startsWith('wf_indep_')) {
            this.$set(this.step1.form, 'formType', 3)
          } else {
            this.$set(this.step1.form, 'formKey', formKey)
          }
        })
      },
      immediate: true
    },
    language(val) {
      const option = {
        lang: val
      }
      if (this.$refs.bpmn2) {
        this.$refs.bpmn2.getData('xml', false, false).then(data => {
          option.xml = data
          this.$set(this.step2, 'option', {
            ...this.step2.option,
            ...option
          })
        })
      } else this.$set(this.step2, 'option', {
        ...this.step2.option,
        ...option
      })
    }
  },
  data() {
    const _this = this
    return {
      form: {},
      option: {},
      step: 0,
      step1: {
        form: {},
        option: {
          menuBtn: false,
          labelWidth: 120,
          group: [{
            labelPosition: 'left',
            label: this.$t(`cip.plat.wfDesign.model.field.selectForm`),
            icon: 'el-icon-warning-outline',
            arrow: false,
            column: [{
              label: this.$t(`cip.plat.wfDesign.model.field.formType`),
              prop: 'formType',
              type: 'radio',
              dataType: 'number',
              props: {
                label: "dictValue",
                value: "dictKey"
              },
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=form_type",
              span: 24,
              value: 1,
              event: {
                change: (val) => {
                  if (!val) return
                  if (val == 1) {
                    this.findObject(this.step1.option.group[0].column, 'exFormKey').display = false
                    this.findObject(this.step1.option.group[1].column, 'column').display = false
                    this.findObject(this.step1.option.group[0].column, 'formKey').display = true
                    this.findObject(this.step1.option.group[0].column, 'tip').display = true
                    this.findObject(this.step1.option.group[1].column, 'form').display = true
                    this.step1.option.group[1].display = true
                  } else if (val == 2) {
                    this.findObject(this.step1.option.group[0].column, 'exFormKey').display = true
                    this.findObject(this.step1.option.group[1].column, 'column').display = true
                    this.findObject(this.step1.option.group[0].column, 'formKey').display = false
                    this.findObject(this.step1.option.group[0].column, 'tip').display = false
                    this.option = {}
                    this.step1.option.group[1].display = true
                  } else if (val == 3) {
                    this.findObject(this.step1.option.group[0].column, 'exFormKey').display = false
                    this.findObject(this.step1.option.group[1].column, 'column').display = false
                    this.findObject(this.step1.option.group[0].column, 'formKey').display = false
                    this.findObject(this.step1.option.group[0].column, 'tip').display = false
                    this.option = {}
                    this.step1.option.group[1].display = false
                  }
                }
              }
            }, {
              label: this.$t(`cip.plat.wfDesign.model.field.exFormKey`),
              prop: 'exFormKey',
              display: false,
              rules: [{ required: true, message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.model.field.exFormKey`), }]
            }, {
              label: this.$t(`cip.plat.wfDesign.model.field.formKey`),
              prop: 'formKey',
              type: 'select',
              props: {
                label: 'name',
                value: 'formKey'
              },
              dicData: [],
              event: {
                change: (val) => {
                  _this.option = { menuBtn: false, readonly: true }
                  if (val) {
                    getFormByKey({ formKey: val }).then(res => {
                      _this.option = { ...eval('(' + res.data.data.content.replace(/this/g, '_this') + ')'), menuBtn: false, readonly: true }
                      _this.findObject(this.step1.option.group[1].column, 'form').display = true
                    }).catch(() => {
                      _this.findObject(this.step1.option.group[1].column, 'form').display = false
                    })
                  } else {
                    _this.findObject(this.step1.option.group[1].column, 'form').display = false
                  }
                }
              },
              rules: [{ required: true, message: this.$t(`cip.cmn.rule.selectWarning`)+this.$t(`cip.plat.wfDesign.model.field.formKey`), }],
              display: true,
              filterable: true,
            }, {
              labelWidth: 0,
              prop: 'tip',
              formslot: true
            }]
          }, {
            label: this.$t(`cip.plat.wfDesign.model.field.formView`),
            icon: 'el-icon-view',
            display: true,
            arrow: false,
            column: [{
              prop: 'form',
              labelWidth: 0,
              span: 24,
              formslot: true,
              display: false
            }, {
              prop: 'column',
              labelWidth: '0',
              tip: this.$t(`cip.plat.wfDesign.model.msg.settings`),
              tipPlacement: 'top',
              span: 24,
              type: 'dynamic',
              children: {
                align: 'center',
                column: [{
                  label: this.$t(`cip.plat.wfDesign.model.field.label`),
                  prop: 'label',
                  rules: [{ required: true, message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.model.field.label`), }]
                }, {
                  label: this.$t(`cip.plat.wfDesign.model.field.prop`),
                  prop: 'prop',
                  rules: [{ required: true, message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.model.field.prop`), }]
                }, {
                  label: this.$t(`cip.plat.wfDesign.model.field.readable`),
                  prop: 'readable',
                  type: 'switch',
                  disabled: true,
                  value: true
                }, {
                  label: this.$t(`cip.plat.wfDesign.model.field.writable`),
                  prop: 'writable',
                  type: 'switch',
                  disabled: true,
                  value: true
                }]
              },
              display: false
            }]
          }]
        },
      },
      step2: {
        option: {
          config: false,
          mode: 'edit',
          engine: 'flowable',
          toolbar: ['open', 'create', 'fit', 'zoom-in', 'zoom-out', 'undo', 'redo', 'import', 'preview'],
          script: {
            script: {
              enable: false,
              alert: this.$t(`cip.plat.wfDesign.model.msg.script`),
            },
            shell: {
              enable: false,
              alert: this.$t(`cip.plat.wfDesign.model.msg.shell`),
              pattern: '(rm|mv|kill|ifconfig|docker|reboot|dd|wget|shutdown|halt|poweroff|init|:(){:|:&};:|^foo^bar)'
            }
          }
        }
      },
      step3: {
        option: {
          config: false,
          mode: 'view',
          simulation: true,
          minimap: true,
          engine: 'flowable'
        }
      },
      process: {},
      fullscreen: false
    }
  },
  mounted() {
    this.getButtonList()
    this.getUserListV2()
    this.getFormList()
    this.getConditionList()
  },
  methods: {
    handleNextStep() {
      switch (this.step) {
        case 0:
          this.$refs.form1.validate((valid, done) => {
            if (valid) {
              const { formType, formKey, exFormKey, column } = this.step1.form
              if (formType == 1) { // 内置表单
                this.process.formKey = formKey
                this.$set(this.step2.option, 'form', this.option)
              } else if (formType == 2) { // 外置表单
                this.process.formKey = "wf_ex_" + exFormKey
                this.$set(this.step2.option, 'exForm', {
                  exFormKey, column
                })
              } else if (formType == 3) { // 独立表单
                this.$set(this.step2.option, 'indepForm', {
                  mode: 'indep',
                  list: this.formList
                })
              }
              this.step++
              done()
            }
          })
          break;
        case 1:
          if (this.step1.form.formType == 3) { // 节点独立表单
            const registry = this.$refs.bpmn2.getElementRegistry().getAll()
            let errorList = []
            registry.forEach(ele => {
              this.validateIndepFormOption(ele, errorList)
            })
            if (errorList.length > 0) {
              errorList = new Set(errorList)
              let message = ''
              errorList.forEach(err => {
                const { businessObject } = err
                const { id, name } = businessObject
                message += `<p>${name || id} </p>` + this.$t(`cip.plat.wfDesign.model.msg.node`)
              })
              this.$message({
                type: 'error',
                dangerouslyUseHTMLString: true,
                message
              })
              return
            }
          }
          this.$refs.bpmn2.getData('xml').then(data => {
            this.$set(this.step2.option, 'xml', data)
            this.$set(this.step3.option, 'xml', data)
            this.process.xml = data
            this.step++
          })
          break;
      }
    },
    handleSave() {
      const registry = this.$refs.bpmn3.getElementRegistry().getAll()
      const { businessObject } = registry[0]
      const { id, name, documentation } = businessObject
      const description = (documentation && documentation.length > 0) ? documentation[0].text : null

      const { formType } = this.step1.form
      if (formType == 3) { // 节点独立表单
        const startEvent = registry.find(r => r.type == 'bpmn:StartEvent')
        if (startEvent) {
          const indepFormKey = startEvent.businessObject.extensionElements.values.find(v => v.$type == 'flowable:indepFormKey')
          if (indepFormKey) this.process.formKey = 'wf_indep_' + indepFormKey.value
        }
      }

      const params = {
        ...this.process,
        modelKey: id,
        name,
        description
      }

      if (this.process.id) {
        this.$confirm(this.$t(`cip.plat.wfDesign.model.msg.save`), {
          distinguishCancelAndClose: true,
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: 'warning'
        }).then(() => {
          params.newVersion = false

          submit(params).then(() => {
            this.$message.success(this.$t(`cip.cmn.msg.success.operateSuccess`))
            this.$store.commit('DEL_TAG', this.tag)
            this.$router.push("/plugin/workflow/design/model")
          })
        }).catch(action => {
          if (action == 'cancel') {
            params.newVersion = true

            submit(params).then(() => {
              this.$message.success(this.$t(`cip.cmn.msg.success.operateSuccess`))
              this.$store.commit('DEL_TAG', this.tag)
              this.$router.push("/plugin/workflow/design/model")
            })
          }
        })
      } else {
        submit(params).then(() => {
          this.$message.success(this.$t(`cip.cmn.msg.success.operateSuccess`))
          this.$store.commit('DEL_TAG', this.tag)
          this.$router.push("/plugin/workflow/design/model")
        })
      }
    },
    validateIndepFormOption(element, errorList) {
      const { type, businessObject, children } = element
      const indepFormKey = 'flowable:IndepFormKey'
      const indepFormSummary = 'flowable:IndepFormSummary'
      if ('bpmn:StartEvent' == type) {
        const extensionElements = businessObject.extensionElements
        if (extensionElements && extensionElements.values && extensionElements.values.length > 0) {
          const summary = extensionElements.values.find(v => v.$type == indepFormSummary)
          if (!extensionElements.values.find(v => v.$type == indepFormKey) && (!summary || summary == '0')) errorList.push(element)
        } else errorList.push(element)
      } else if ('bpmn:UserTask' == type) {
        const summary = businessObject['indepFormSummary']
        if (!businessObject['indepFormKey'] && (!summary || summary == '0')) errorList.push(element)
      } else if ('bpmn:SubProcess' == type) {
        children.forEach(ele => this.validateIndepFormOption(ele, errorList))
      }
    },
    getButtonList() {
      buttonList(1, -1, { status: 1 }).then(res => {
        const list = res.data.data.records.map(l => {
          return {
            label: l.name,
            prop: l.buttonKey,
            display: l.display
          }
        })
        this.$set(this.step2.option, 'button', list)
      })
    },
    getFormList() {
      formList(1, -1, { 'status_equal': '1' }).then(res => {
        this.formList = res.data.data.records
        this.findObject(this.step1.option.group, 'formKey').dicData = this.formList
      })
    },
    getUserListV2() {
      this.$set(this.step2.option, 'user', {
        version: 'v2',
        userUrl: '/api/sinoma-user/pagePost',
        roleUrl: '/api/sinoma-system/role/listPost?current=1&size=10',
        deptUrl: '/api/sinoma-system/dept/deptChildTreePost',
        postUrl: '/api/sinoma-system/duty/listPage',
        customUrl: '/api/sinoma-workflow/design/condition/list'
      })
    },
    getConditionList() {
      conditionList(1, -1, { type: 'flow', status: 1 }).then(res => {
        this.$set(this.step2.option, 'condition', res.data.data.records)
      })
    },
    handleFullScreen() {
      fullscreenToggel()
      this.$store.commit('SET_COLLAPSE')
    },
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-steps--simple{
  background: #FFFFFF;
  border-radius: 0;
  border-bottom: 1px solid #e4e7ed;
}
::v-deep .bjs-powered-by {
  display: none;
}
::v-deep #pane-process {
  background-color: #FFFFFF;
}
::v-deep #pane-node {
  background-color: #FFFFFF;
  //height: calc(100% - 200px);
  //padding: 100px !important;
}

//::v-deep #pane-node div {
//  margin-top: 0 !important;
//}

::v-deep .djs-palette {
  width: 94px !important;
}
.foot-item {
  position: fixed;
  bottom: 0;
  //margin-left: -20px;
  right: 0;
  z-index: 101;
  height: 66px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.avue-form ::v-deep{
  margin-bottom: 66px;
}
.wf-design {
  border-radius: 4px;
  border: none;
  .avue-group__title {
    margin-top: 8px;
  }
}

.bjs-container .bjs-powered-by{
  display: none;
}
.el-collapse {
  padding: 0 8px;
  background-color: #FFFFFF;
}
.el-aside {
  width: auto !important;
  overflow: hidden !important;
}
.el-tabs__content {
  overflow-y: auto !important;
}
.avue-form__item-- .el-form-item__content {
  height: 30vh;
  overflow: auto;
}

::v-deep .avue-dynamic {
  height: calc(100vh - 505px) !important;
  overflow: auto;
}
</style>
