<template>
  <div>
    <transition name="el-zoom-in-center">
      <div class="formBox" v-if="formShow">
        <div class="formTitleBox">
          <div class="leftBack">
            <div class="backBox" @click="formShow = false">
              <i class="el-icon-back"></i>
              返回
            </div>
            <span style="font-size: 20px; margin-left: 40px">{{
                formTitle
              }}</span>
          </div>
          <div class="rightBtn">
            <el-button-group>
              <el-button size="mini" v-if="stepActive == 1" @click="preview"
              >预览
              </el-button
              >
              <el-button
                size="mini"
                v-if="stepActive == 0"
                @click="stepActive = 1"
              >下一步
              </el-button
              >
              <el-button
                size="mini"
                v-if="stepActive == 1"
                @click="stepActive = 0"
              >上一步
              </el-button
              >
              <el-button size="mini" @click="submitAllForm">保存</el-button>
              <el-button size="mini" @click="formShow = false">取消</el-button>
            </el-button-group>
          </div>
        </div>
        <div class="formContentBox">
          <el-steps :active="stepActive" simple finish-status="success">
            <el-step title="填写主表信息" icon="el-icon-edit"></el-step>
            <el-step title="填写子表信息" icon="el-icon-upload"></el-step>
          </el-steps>
          <div class="formMain">
            <div v-if="stepActive == 0">
              <div class="formTopic">填报要求</div>
              <div style="padding: 12px">
                <el-form ref="form" :model="dataForm" label-width="150px">
                  <el-row>
                    <el-col :span="20">
                      <el-form-item label="标题：">
                        <el-input v-model="dataForm.title" type="title" placeholder="请输入标题">
                        </el-input>
                      </el-form-item
                      >
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="20">
                      <el-form-item label="表名称：">
                        <el-input v-model="dataForm.tableName" type="tableName" placeholder="请输入表名称">
                        </el-input>
                      </el-form-item
                      >
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="20">
                      <el-form-item label="角色：">
                        <el-select v-model="dataForm.roleId" placeholder="请选择角色" @change="chooseRole">
                          <el-option
                            v-for="item in roleData"
                            :key="item.id"
                            :label="item.roleName"
                            :value="item.id">
                          </el-option>
                        </el-select>
                      </el-form-item
                      >
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="采集周期：">
                        <el-radio-group v-model="dataForm.acquisitionCycle" @input="chooseCycle">
                          <el-radio label="1">每天</el-radio>
                          <el-radio label="2">每周</el-radio>
                          <el-radio label="3">每月</el-radio>
                          <el-radio label="4">每年</el-radio>
                          <el-radio label="5">单次</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row v-if="dataForm.acquisitionCycle == 2 || dataForm.acquisitionCycle == 3 || dataForm.acquisitionCycle == 4">
                    <el-col :span="20">
                      <el-form-item label="采集时间：">
                        <el-date-picker
                          v-model="dataForm.acquisitionTime"
                          type="date"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          placeholder="请选择采集时间"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
<!--                    <el-col :span="20" v-if="dataForm.acquisitionCycle == 2">-->
<!--                      <el-form-item label="采集时间：">-->
<!--                        <el-select v-model="dataForm.acquisitionTime" placeholder="请选择采集时间">-->
<!--                          <el-option-->
<!--                            v-for="item in weekOptions"-->
<!--                            :key="item.value"-->
<!--                            :label="item.label"-->
<!--                            :value="item.value">-->
<!--                          </el-option>-->
<!--                        </el-select>-->
<!--                      </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="20" v-if="dataForm.acquisitionCycle == 3">-->
<!--                      <el-form-item label="采集时间：">-->
<!--                        <el-select v-model="dataForm.acquisitionTime" placeholder="请选择采集时间">-->
<!--                          <el-option-->
<!--                            v-for="item in dayOptions"-->
<!--                            :key="item.value"-->
<!--                            :label="item.label"-->
<!--                            :value="item.value">-->
<!--                          </el-option>-->
<!--                        </el-select>-->
<!--                      </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="20" v-if="dataForm.acquisitionCycle == 4">-->
<!--                      <el-form-item label="采集时间：">-->
<!--                        <el-select v-model="month" placeholder="请选择月份" style="width: 50% !important;">-->
<!--                          <el-option-->
<!--                            v-for="item in monthOptions"-->
<!--                            :key="item.value"-->
<!--                            :label="item.label"-->
<!--                            :value="item.value">-->
<!--                          </el-option>-->
<!--                        </el-select>-->
<!--                        <el-select v-model="day" placeholder="请选择日份" style="width: 50% !important;">-->
<!--                          <el-option-->
<!--                            v-for="item in dayOptions"-->
<!--                            :key="item.value"-->
<!--                            :label="item.label"-->
<!--                            :value="item.value">-->
<!--                          </el-option>-->
<!--                        </el-select>-->
<!--                      </el-form-item>-->
<!--                    </el-col>-->
                  </el-row>
                  <el-row>
                    <el-col :span="10">
                      <el-form-item label="通知时间：">
                        通知周期动态变化，详细查看任务规则页面<span
                        style="margin-left: 20px"
                      ></span
                      >填报周期：
                        <el-input
                          v-model="dataForm.reportingPeriod"
                          style="width: 100px"
                        ></el-input>
                        天
                      </el-form-item>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="汇总周期：">
                        <el-input
                          v-model="dataForm.summaryPeriod"
                          style="width: 100px"
                        ></el-input>
                        天
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="上传附件：">
                        <el-upload
                          class="upload-demo"
                          action="api/sinoma-resource/oss/endpoint/put-file"
                          :on-success="handleSuccess"
                          :on-remove="handleRemove"
                          :headers="headers"
                          multiple
                          :limit="1"
                          :file-list="fileList"
                        >
                          <el-button size="small" type="primary"
                          >点击上传
                          </el-button
                          >
                          <div slot="tip" class="el-upload__tip">
                            支持扩展名：.rar .zip .doc .docx .pdf .jpg...
                          </div>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="20">
                      <el-form-item label="填写说明：">
                        <el-input v-model="dataForm.acquisitionDescription" type="textarea">
                        </el-input>
                      </el-form-item
                      >
                    </el-col>
                  </el-row>
                </el-form>
              </div>
              <div class="formTopic">填报单位</div>
              <div
                style="padding: 12px; display: flex; justify-content: center"
              >
                <el-radio v-model="radio1" label="1" border
                >常用分组一
                </el-radio
                >
                <el-radio v-model="radio1" label="2" border
                >常用分组二
                </el-radio
                >
                <el-radio v-model="radio1" label="3" border
                >常用分组三
                </el-radio
                >
                <el-radio v-model="radio1" label="4" border
                >常用分组四
                </el-radio
                >
              </div>
              <div class="shuttleBackBox">
                <div class="shutleBack">
                  <div class="shutleTitle">组织架构</div>
                  <div class="shutContent">
                    <el-tree
                      ref="tree"
                      :data="deptTree"
                      :props="defaultProps"
                      node-key="id"
                      @node-click="handleNodeClick"
                    ></el-tree>
                  </div>
                </div>
                <div class="leftRightBtn">
                  <el-button-group>
                    <el-button
                      type="primary"
                      size="medium"
                      icon="el-icon-arrow-left"
                    ></el-button>
                    <el-button
                      type="primary"
                      size="medium"
                      icon="el-icon-arrow-right"
                    ></el-button>
                  </el-button-group>
                </div>
                <div class="shutleBack">
                  <div class="shutleTitle">填报单位</div>
                  <div class="shutContent">
                    <div
                      class="deptBox"
                      v-for="(item, index) in middleShutData"
                      :key="index"
                    >
                      <span>{{ item.organize }}</span>
                      <i
                        class="el-icon-delete"
                        style="
                          color: #f56c6c;
                          font-size: 20px;
                          line-height: unset;
                          cursor: pointer;
                        "
                        @click="deleteMiddleShut(item, index)"
                      ></i>
                    </div>
                  </div>
                </div>
                <div class="leftRightBtn">
                  <el-button-group>
                    <el-button
                      type="primary"
                      size="medium"
                      icon="el-icon-arrow-left"
                    ></el-button>
                    <el-button
                      type="primary"
                      size="medium"
                      icon="el-icon-arrow-right"
                    ></el-button>
                  </el-button-group>
                </div>
                <div class="shutleBack">
                  <div class="shutleTitle">汇总单位</div>
                  <div class="shutContent">
                    <div
                      class="deptBox"
                      v-for="(item, index) in rightShutData"
                      :key="index"
                    >
                      <span>{{ item.organize }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="stepActive == 1" style="height: 100%">
              <div
                style="
                  padding: 12px 0;
                  display: flex;
                  justify-content: space-between;
                  height: calc(100% - 24px);
                "
              >
                <div class="leftClass">
                  <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-circle-plus-outline"
                    style="width: 100%"
                    @click="addClass"
                  >添加分类
                  </el-button
                  >
                  <div class="classDataBox">
                    <div
                      :class="classActive == index?'classActive':''"
                      class="classLittle"
                      v-for="(item, index) in classList"
                      :key="index"
                      @click="chooseTableData(item, index)"
                    >
                      <i
                        class="el-icon-edit"
                        style="line-height: unset; color: #e6a23c"
                        @click="editClass(item,index)"
                      ></i>
                      <span>{{ item.groupName }} - {{ item.columnNum }}列</span>
                      <i
                        class="el-icon-delete"
                        style="line-height: unset; color: #f56c6c"
                        @click="deleteClass(index)"
                      ></i>
                    </div>
                  </div>
                </div>
                <div class="rightTable">
                  <el-table
                    :data="properties"
                    border
                    ref="table"
                    size="medium"
                    style="width: 100%; height: 100%"
                  >
                    <el-table-column
                      prop="date"
                      label="排序"
                      align="center"
                      type="index"
                      width="80"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="fieldCode"
                      label="数据项编号"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.fieldCode"
                          clearable
                          placeholder="请输入数据项编号"
                        />
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="fieldName"
                      label="数据项名称"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.fieldName"
                          clearable
                          placeholder="请输入数据线名称"
                        />
                      </template>
                    </el-table-column>
                    <el-table-column prop="type" label="类型" align="center">
                      <template slot-scope="scope">
                        <el-select
                          v-model="scope.row.type"
                          placeholder="请选择类型"
                        >
                          <el-option
                            v-for="item in typeOptions"
                            :key="item.dictKey"
                            :label="item.dictValue"
                            :value="item.dictKey"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="isEnable"
                      label="是否必填"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-select
                          v-model="scope.row.isEnable"
                          placeholder="请选择是否必填"
                        >
                          <el-option
                            v-for="item in yesOrNoOptions"
                            :key="item.dictKey"
                            :label="item.dictValue"
                            :value="item.dictKey"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="isSummary"
                      label="是否汇总"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-select
                          v-model="scope.row.isSummary"
                          placeholder="请选择是否汇总"
                        >
                          <el-option
                            v-for="item in yesOrNoOptions"
                            :key="item.dictKey"
                            :label="item.dictValue"
                            :value="item.dictKey"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="inputPrompt"
                      label="输入提示"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.inputPrompt"
                          clearable
                          placeholder="请输入输入提示"
                        />
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="inputLength"
                      label="录入长度"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.inputLength"
                          clearable
                          placeholder="请输入录入长度"
                        />
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="dictionaryCode"
                      label="字典编码"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.dictionaryCode"
                          clearable
                          placeholder="请输入字典编码"
                        />
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="inputWay"
                      label="输入方式"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-select
                          v-model="scope.row.inputWay"
                          placeholder="请选择输入方式"
                        >
                          <el-option
                            v-for="item in inputWayOptions"
                            :key="item.dictKey"
                            :label="item.dictValue"
                            :value="item.dictKey"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="calculationRules"
                      label="计算公式"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.calculationRules"
                          clearable
                          placeholder="请输入计算公式"
                        />
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                      <template slot-scope="scope">
                        <el-button
                          type="text"
                          @click="addTable(scope.row, scope.$index)"
                        >新增
                        </el-button
                        >
                        <el-button
                          type="text"
                          @click="deleteTable(scope.row, scope.$index)"
                        >删除
                        </el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <el-dialog :title="classTitle" :visible.sync="classShow" width="30%">
      <el-form ref="classForm" :model="classForm" label-width="80px" :rules="classRules">
        <el-form-item label="分类名称" prop="groupName">
          <el-input
            v-model="classForm.groupName"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类列数" prop="columnNum">
          <el-input
            v-model="classForm.columnNum"
            placeholder="请输入分类列数"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="classShow = false">取 消</el-button>
        <el-button type="primary" @click="submitClass">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="预览" :visible.sync="previewShow" width="70%">
      <preview ref="preview"></preview>
    </el-dialog>
  </div>
</template>
<script>
// import {attributeDetail, attributeSave, dictionaryBiz} from "@/api/reportSet"
import {dateFormat} from "@/util/date";
import preview from "./components/preview.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    preview,
  },
  data() {
    return {
      formShow: false,
      previewShow: false,
      previewFormOption: {},
      formTitle: "",
      stepActive: 0,
      radio1: 1,
      classActive: 0,
      month: '',
      day: '',
      defaultProps: {
        children: "children",
        label: "fullName",
      },
      classType:'',
      middleShutData: [],
      rightShutData: [],
      fileList: [],
      headers: {},
      dataForm: {
        id: '',
        classificationId: '',
        title: '',
        tableName: '',
        acquisitionCycle: '',
        acquisitionTime: '',
        acquisitionDescription: '',
        reportingPeriod: '',
        summaryPeriod: '',
        attachDescription: ''
      },
      radio: 1,
      classForm: {
        groupName: "",
        columnNum: "",
        properties: []
      },
      classRules: {
        groupName: [
          {required: true, message: '请输入分裂名称', trigger: 'blur'}
        ],
        columnNum: [
          {required: true, message: '请输入分裂列数', trigger: 'blur'}
        ],
      },
      classTitle: "",
      classShow: false,
      fnDeptTree: [],
      classList: [],
      properties: [],
      typeOptions: [],
      weekOptions: [
        {
          value: "1",
          label: "星期一",
        },
        {
          value: "2",
          label: "星期二",
        },
        {
          value: "3",
          label: "星期三",
        }, {
          value: "4",
          label: "星期四",
        },
        {
          value: "5",
          label: "星期",
        },
        {
          value: "6",
          label: "星期六",
        },
        {
          value: "7",
          label: "星期天",
        },
      ],
      dayOptions: [
        {
          value: "1",
          label: "一号",
        },
        {
          value: "2",
          label: "二号",
        },
        {
          value: "3",
          label: "三号",
        },
        {
          value: "4",
          label: "四号",
        },
        {
          value: "5",
          label: "五号",
        },
        {
          value: "6",
          label: "六号",
        },
        {
          value: "7",
          label: "七号",
        },
        {
          value: "8",
          label: "八号",
        },
        {
          value: "9",
          label: "九号",
        },
        {
          value: "10",
          label: "十号",
        },
      ],
      monthOptions: [
        {
          value: "1",
          label: "一月",
        },
        {
          value: "2",
          label: "二月",
        },
        {
          value: "3",
          label: "三月",
        },
        {
          value: "4",
          label: "四月",
        },
      ],
      yesOrNoOptions: [],
      inputWayOptions: [
        {
          value: "1",
          label: "填报",
        },
        {
          value: "2",
          label: "计算",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["deptTree", "roleData", "userInfo"]),
  },
  mounted() {
    console.log(this.deptTree,'724')
    this.fnDeptTree = this.fnTree(this.deptTree);
    this.headers = {
      "Sinoma-Auth": "bearer " + this.userInfo.access_token,
    };
    this.getDictory();
  },
  methods: {
    getDictory() {
      dictionaryBiz("report_data_type").then((res) => {
        this.typeOptions = res.data.data;
      });
      dictionaryBiz("yesandno").then((res) => {
        this.yesOrNoOptions = res.data.data;
      });
      dictionaryBiz("report_input_way").then((res) => {
        this.inputWayOptions = res.data.data;
      });
    },
    resetData() {
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      })
      this.dataForm = {
        id: '',
        classificationId: '',
        title: '',
        tableName: '',
        acquisitionCycle: '',
        acquisitionTime: '',
        acquisitionDescription: '',
        reportingPeriod: '',
        summaryPeriod: '',
        attachDescription: ''
      }
      this.stepActive = 0;
      this.classList = [];
      this.middleShutData = [];
      this.rightShutData = [];
    },
    chooseCycle() {
      this.dataForm.acquisitionTime = null;
    },
    // 保存填报设置
    submitAllForm() {
      if (this.dataForm.acquisitionCycle == 1 || this.dataForm.acquisitionCycle == 5) {
        this.dataForm.acquisitionTime = dateFormat(new Date(), "yyyy-MM-dd") + ' 00:00:00'
      }
      this.dataForm.fillList = this.middleShutData;
      this.dataForm.summaryList = this.rightShutData;
      // this.dataForm.attachDescription = JSON.stringify(this.dataForm.attachDescription);
      let data = {
        acquisitionFormId: this.dataForm.id,
        acquisitionAttribute: this.dataForm,
        properties: this.classList
      }
      attributeSave(data).then((res) => {
        if (res.data.code == 200) {
          this.dataForm = res.data.data.acquisitionAttribute;
          if (this.dataForm.acquisitionCycle == 4) {
            let timeData = this.dataForm.acquisitionTime.split(',');
            this.month = timeData[0];
            this.day = timeData[1];
          }
          this.rightShutData = res.data.data.acquisitionAttribute.summaryList;
          let middleData = new Array();
          let middleShutData = res.data.data.acquisitionAttribute.fillList;
          middleShutData.forEach((item) => {
            let data = this.fnDeptTree.filter((i) => {
              return item.unitId == i.id
            })
            middleData.push(data[0]);
          })
          this.middleShutData = middleData;
          let classList = res.data.data.properties;
          classList.forEach((item) => {
            item.id = '';
          })
          this.classList = classList;
          this.properties = this.classList[0].properties;
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.formShow = false;
        }
      })
    },
    // 根据ID查表单详情
    getAttributeDetail() {
      attributeDetail(this.dataForm.id).then((res) => {
        if (res.data.code == 200) {
          this.dataForm = res.data.data.acquisitionAttribute;
          if (this.dataForm.acquisitionCycle == 4) {
            let timeData = this.dataForm.acquisitionTime.split(',');
            this.month = timeData[0];
            this.day = timeData[1];
          }
          this.rightShutData = res.data.data.acquisitionAttribute.summaryList;
          let middleShutData = res.data.data.acquisitionAttribute.fillList;
          middleShutData.forEach((item) => {
            let data = this.fnDeptTree.filter((i) => {
              return item.unitId == i.id
            })
            this.middleShutData.push(data[0]);
          })
          let classList = res.data.data.properties;
          classList.forEach((item) => {
            item.id = '';
          })
          this.classList = classList;
          this.properties = this.classList[0].properties;
        }
      })
    },
    // 选择角色查出角色名
    chooseRole(e) {
      let data = this.roleData.filter((item) => {
        return item.id == e
      })
      this.dataForm.roleName = data[0].roleName;
    },
    // 删除上传文件
    handleRemove(file, fileList) {
      let list = new Array();
      fileList.forEach((i) => {
        if (i.response) {
          list.push({
            name: i.name,
            url: i.response.data.link
          });
        } else {
          list.push({
            name: i.name,
            url: i.url
          });
        }
      })
      this.fileList = list;
      this.dataForm.attachDescription = JSON.stringify(list);
    },
    // 新增上传文件
    handleSuccess(response, file, fileList) {
      let list = new Array();
      fileList.map((i) => {
        if (i.response) {
          list.push({
            name: i.name,
            url: i.response.data.link
          });
        } else {
          list.push({
            name: i.name,
            url: i.url
          });
        }
      })
      this.fileList = list;
      this.dataForm.attachDescription = JSON.stringify(list);
    },
    // 预览填报设置表单
    preview() {
      this.previewShow = true;
      this.$refs.preview.formOption = this.classList;
      this.$refs.preview.getPreView();
    },
    // 选择分类列表
    chooseTableData(item, index) {
      this.classActive = index;
      if (item.properties.length == 0) {
        item.properties.push({
          acquisitionFormId: this.dataForm.id,
          fieldCode: "",
          fieldName: "",
          isEnable: "",
          isSummary: "",
          inputWay: "",
          calculationRules: "",
          type: "",
          inputLength: "",
          dictionaryCode:''
        })
      }
      this.properties = item.properties;
    },
    // 选择填报单位查出汇总单位
    handleNodeClick(node) {
      let middleFilter = this.middleShutData.filter((item) => {
        return item.id == node.id;
      });
      if (middleFilter.length != 0) return;
      this.middleShutData.push(node);
      this.countUnit();
    },
    // 删除填报单位
    deleteMiddleShut(item, index) {
      this.middleShutData.splice(index, 1);
      this.countUnit();
    },
    // 计算填报单位
    countUnit() {
      let rightName = new Array();
      let rightIds = new Array();
      let middleIds = new Array();
      this.middleShutData.forEach((item) => {
        item.organizeIds.forEach((i) => {
          rightIds.push(i);
        });
        middleIds.push(item.id);
      });
      rightIds = [...new Set(rightIds)];
      this.rightShutIds = this.getArrDifference(middleIds, rightIds);
      this.middleShutData.forEach((item) => {
        if (item.hasChildren == true){
          this.rightShutIds.push(item.id)
        }
      })
      this.rightShutIds.forEach((item) => {
        let data = this.fnDeptTree.filter((i) => {
          return i.id == item
        })
        rightName.push(data[0])
      })
      this.rightShutData = rightName;
    },
    // 数组取插值
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (value, i, arr) {
        return arr.indexOf(value) === arr.lastIndexOf(value);
      });
    },
    // 新增分类列表
    addClass() {
      this.classType = 'add';
      this.resetClassForm();
      this.classShow = true;
      this.classTitle = "新增分类";
    },
    // 重置分类表单
    resetClassForm() {
      this.$nextTick(() => {
        this.$refs.classForm.resetFields();
      })
      this.classForm = {
        acquisitionFormId: this.dataForm.id,
        columnNum: "",
        groupName: "",
        properties: []
      };
    },
    // 删除分类列表
    deleteClass(index) {
      // this.properties = [];
      this.$refs.table.tableData = [];
      this.classList.splice(index, 1);
      console.log(this.$refs.table,'993')
    },
    // 编辑分类列表
    editClass(item,index) {
      this.classIndex = index;
      this.classType = 'edit';
      this.classShow = true;
      this.classTitle = "编辑分类";
      this.classForm = item;
    },
    // 提交分类列表
    submitClass() {
      if (this.classType == 'add') {
        this.classList.push(this.classForm);
      } else {
        this.classList[this.classIndex].groupName = this.classForm.groupName;
        this.classList[this.classIndex].columnNum = this.classForm.columnNum;
      }
      this.classShow = false;
    },
    // 新增右侧列表配置
    addTable(row, index) {
      this.properties.splice(index + 1, 0, {
        acquisitionFormId: this.dataForm.id,
        fieldCode: "",
        fieldName: "",
        isEnable: "",
        isSummary: "",
        inputWay: "",
        calculationRules: "",
        type: "",
        inputLength: "",
        dictionaryCode:''
      });
    },
    // 删除右侧列表配置
    deleteTable(row, index) {
      this.properties.splice(index, 1);
    },
    fnTree(arr) {
      let data = JSON.parse(JSON.stringify(arr));
      let newData = [];
      const callback = (item) => {
        (item.children || (item.children = [])).map((v) => {
          callback(v);
        });
        delete item.children;
        newData.push(item);
      };
      data.map((v) => callback(v));
      return newData;
    },
  },
};
</script>
<style lang="scss" scoped>
.formBox {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.formTitleBox {
  width: calc(100% - 24px);
  border-bottom: 1px solid #bbbbbb;
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.leftBack {
  cursor: pointer;
  display: flex;
}

.backBox {
  position: relative;
  line-height: 28px;
}

.backBox:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 16px;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #dcdfe6;
}

.rightBtn {
  display: flex;
}

.formContentBox {
  padding: 12px;
  height: calc(100% - 94px);
}

.formMain {
  width: 100%;
  height: 100%;
  overflow: auto;
  height: calc(100% - 28px);
}

.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

.shuttleBackBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.shutleBack {
  width: 27%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.leftRightBtn {
  margin: 211px 12px;
}

.shutleTitle {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}

.shutContent {
  padding: 6px;
  height: 400px;
  overflow: auto;
}

.shutImg {
  width: 40px;
  height: 40px;
  display: block;
  margin: 207px 12px;
}

.deptBox {
  height: 40px;
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  justify-content: space-between;
  display: flex;
}

.leftClass {
  width: calc(15% - 48px);
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  padding: 12px 24px;
}

.classDataBox {
  height: calc(100% - 34px);
  overflow: auto;
}

.classLittle {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 14px);
  border: 1px solid #bbbbbb;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 4px;
  margin: 12px 0;
  cursor: pointer;
  font-size: 15px;
  padding: 0 6px;
}

.rightTable {
  width: 84%;
}

.classActive {
  background-color: #E9EDF3;
}
</style>
