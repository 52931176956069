var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "待办", name: "second" } }),
          _c("el-tab-pane", { attrs: { label: "已办", name: "third" } }),
        ],
        1
      ),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: {
          "head-title": _vm.$t(`cip.desk.workflow.title.indexHeadTodo`),
          "search-columns": _vm.searchColumns,
        },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
        model: {
          value: _vm.searchForm,
          callback: function ($$v) {
            _vm.searchForm = $$v
          },
          expression: "searchForm",
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "head-title": _vm.$t(`cip.desk.workflow.title.indexHeadTodo`),
          "table-options": _vm.option,
          "table-data": _vm.data,
          "table-loading": _vm.loading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
        },
        scopedSlots: _vm._u([
          {
            key: "customBtn",
            fn: function ({ row }) {
              return [
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "0 3px" },
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.rowTrackPending(row)
                      },
                    },
                  },
                  [_vm._v("详情")]
                ),
                row.businessType == 0
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleFlow(row)
                          },
                        },
                      },
                      [_vm._v("流程图")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm.bpmnVisible
        ? _c(
            "common-dialog",
            {
              attrs: {
                width: "70%",
                customClass: "wf-dialog",
                dialogTitle: _vm.$t(`cip.desk.workflow.title.indexHeadVisible`),
                showBtn: false,
              },
              on: {
                cancel: function ($event) {
                  _vm.bpmnVisible = false
                },
              },
            },
            [
              _c("wf-design", {
                ref: "bpmn",
                staticStyle: { height: "60vh" },
                attrs: { options: _vm.bpmnOption },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }