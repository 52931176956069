<template>
  <el-dialog
    title="应急物资选择"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :visible.sync="showDialog"
    width="60%"
    class="qmDialog suppliesDSelect"
    @close="closeDialog"
  >
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>

    <grid-layout
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
    >
      <template #customBtn="{ row }">
        <el-button
          style="margin: 0 3px"
          type="text"
          size="small"
          @click="rowSelect(row)"
          >选择
        </el-button>
      </template>
    </grid-layout>
  </el-dialog>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";

import { getList } from "@/api/safe/thirdcompany";
import { erplanmaterialPage } from "@/api/contingency/emergencyPlan";
import { ermaterialList } from "@/api/contingency/emergencySupplies";
import {
  erexercisematerialSubmit,
  erexercisematerialGetDetail,
  erexercisematerialgetMaxSeqNum,
} from "@/api/contingency/emergencyDrill";
import { mapGetters } from "vuex";
export default {
  components: {
    GridLayout,
  },
  data() {
    return {
      showDialog: false,
      title: "",
      searchColumns: [
        // {
        //   label: "物资名称",
        //   prop: "materialName",
        //   span: 6,
        //   placeholder: "物资名称",
        // },
      ],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableOption: {
        selection: false,
        column: [
          {
            label: "物资名称",
            prop: "materialName",
            align: "center",
          },
          {
            label: "规格型号",
            prop: "materialSpe",
            align: "center",
          },
          {
            label: "数量",
            prop: "quantity",
            align: "center",
          },
          {
            label: "位置",
            prop: "location",
            align: "center",
          },
          {
            label: "购置日期",
            prop: "purchaseDate",
            align: "center",
          },
        ],
      },
      tableData: [],
      submitBtn: false,
      emptyBtn: false,
      priId: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    init(exeId) {
      this.exeId = exeId;
      this.showDialog = true;
      this.onLoad(this.page, {});
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.searchForm = searchForm;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      // params.excludeRrjId = this.exeId
      // erplanmaterialPage(
      //   page.currentPage,
      //   page.pageSize,
      //   Object.assign(params, this.searchForm)
      // ).then((res) => {
      //   const data = res.data.data;
      //   this.page.total = res.data.data.total;
      //   this.$refs.gridLayOut.page.total = data.total;
      //   this.tableData = data.records;
      //   this.tableLoading = false;
      //   //重新渲染表格，解决勾选框错行问题
      //   this.$nextTick(() => {
      //     this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
      //   });
      //   this.$refs.gridLayOut.selectionClear();
      // });
      ermaterialList({ orgId: this.userInfo.dept_id }).then((res) => {
        const data = res.data.data;
        this.tableData = data;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
      });
    },
    // 选择公司
    rowSelect(row) {
      this.showDialog = false;
      let params = {};
      params.quantity = row.quantity;
      params.materialName = row.materialName;
      params.location = row.location;
      params.exeId = this.exeId;

      erexercisematerialSubmit(params).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
          this.$emit("callback");
        }
      });
    },
    closeDialog() {
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.showDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.suppliesDSelect .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
</style>
