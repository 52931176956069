<template>
  <div class="SNOWS-common-layout">
    <div class="SNOWS-common-layout-center">
      <head-layout head-title="流程待办" />

      <el-row class="SNOWS-common-search-box" style="margin-bottom: 0" :gutter="16">
        <el-form @submit.native.prevent>
          <el-col :span="4">
            <el-form-item>
              <el-input v-model="flowTitle" placeholder="请输入流程标题查询" clearable
                        @keyup.enter.native="search()"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-date-picker v-model="pickerVal" type="daterange" start-placeholder="开始日期"
                end-placeholder="结束日期" :picker-options="pickerOptions" value-format="timestamp"
                clearable :editable="false" @change="search">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item>
              <el-select class="main-select-tree" placeholder="选择所属分类" ref="selectTree" v-model="CategoryText"  style="width: 560px;">
                <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id" style="display: none;" />
                <el-tree class="main-select-el-tree" ref="selecteltree"
                         :data="categoryList"
                         node-key="id"
                         highlight-current
                         @node-click="handleNodeClick"
                         :props="defaultProps"
                         default-expand-all />
              </el-select>
<!--              @node-click="handleNodeClick"
              :current-node-key="value"
              :expand-on-click-node="expandOnClickNode"
              <el-select @change="search" v-model="flowCategory" placeholder="选择所属分类" clearable>
                <el-option v-for="item in categoryList" :key="item.id" :label="item.fullName"
                  :value="item.id">
                </el-option>
              </el-select>-->
            </el-form-item>
          </el-col>
          <template v-if="true">
            <el-col :span="4">
              <el-form-item>
                <user-select @change="search" v-model="creatorUserId" placeholder="选择发起人员" />
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item>
                <el-select v-model="urgent" @change="search" placeholder="选择紧急程度" clearable>
                  <el-option v-for="(item,i) in urgentList" :key="i" :label="item.fullName"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </template>
          <el-col :span="2">
            <el-button-group>
              <el-button size="mini" type="primary" icon="el-icon-search" @click="search()"></el-button>
              <el-button size="mini" icon="reset-refresh icon-refresh" @click="refresh()"></el-button>
            </el-button-group>
          </el-col>
        </el-form>
      </el-row>
      <div class="SNOWS-common-layout-main SNOWS-flex-main">
        <SNOWS-table v-loading="listLoading" :data="list" size="medium">
          <el-table-column align="center"prop="fullName" label="流程标题" show-overflow-tooltip />
          <el-table-column align="center"prop="flowName" label="所属流程"  />
          <el-table-column align="center"prop="startTime" label="发起时间"
            :formatter="sinoma.tableDateFormat" />
          <el-table-column align="center" prop="userName" label="发起人员" width="130" />
          <el-table-column align="center" prop="thisStep" label="审批节点" width="130" />
          <el-table-column align="center" prop="flowUrgent" label="紧急程度" width="100">
            <template slot-scope="scope">
              <el-tag type="warning" v-if="scope.row.flowUrgent==2">{{ scope.row.flowUrgent | urgentText() }}</el-tag>
              <el-tag type="danger" v-else-if="scope.row.flowUrgent==3">{{ scope.row.flowUrgent | urgentText() }}</el-tag>
              <el-tag type="primary" v-else>{{ scope.row.flowUrgent | urgentText() }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="status" label="流程状态" >
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.status==2">审核通过</el-tag>
              <el-tag type="danger" v-else-if="scope.row.status==3">审核退回</el-tag>
              <el-tag type="info" v-else-if="scope.row.status==4">流程撤回</el-tag>
              <el-tag type="info" v-else-if="scope.row.status==5">审核终止</el-tag>
              <el-tag type="primary" v-else>等待审核</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="creatorTime" label="接收时间">
            <template slot-scope="scope">
              {{scope.row.creatorTime | toDate() }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="toDetail(scope.row)">审批</el-button>
              <el-button size="mini" type="text" @click="toProcess(scope.row)">流程追踪</el-button>
            </template>
          </el-table-column>
        </SNOWS-table>
        <pagination :total="total" :page.sync="listQuery.currentPage"
          :limit.sync="listQuery.pageSize" @pagination="initData" />
      </div>
    </div>
    <FlowBox v-if="formVisible" ref="FlowBox" @close="closeForm" />
    <FlowChart v-if="processVisible" ref="flowChart"  @close="closeForm"/>
    <BatchList v-if="batchListVisible" :categoryList="categoryList" ref="BatchList"
      @close="batchListVisible=false" />
  </div>
</template>

<script>
import {FlowBeforeInfo, FlowBeforeList} from '@/api/workFlow/FlowBefore'
import { FlowEngineListAll } from '@/api/workFlow/FlowEngine'
import { getFlowCateTree } from "@/util/util";
import FlowBox from '../components/FlowBox'
import FlowChart from "@/views/workFlow/components/FlowChart";
import BatchList from './BatchList'
import Process from '@/components/Process/Preview'
export default {
  name: 'workFlow-flowTodo',
  components: { FlowBox, BatchList, Process,FlowChart },
  data() {
    return {
      list: [],
      total: 0,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      processVisible: false,
      showAll: false,
      listLoading: true,
      listQuery: {
        currentPage: 1,
        pageSize: 20,
        sort: 'desc',
        sidx: ''
      },
      urgent: '',
      urgentList: [
        {
          id: 1,
          fullName: '普通'
        }, {
          id: 2,
          fullName: '重要'
        }, {
          id: 3,
          fullName: '紧急'
        }
      ],
      formVisible: false,
      batchListVisible: false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      flowTitle: '',
      flowTemplateJson:{},
      // keyword: '',
      fullName:'',
      enCode:'',
      pickerVal: [],
      startTime: '',
      endTime: '',
      flowId: '',
      flowCategory: '',
      creatorUserId: '',
      categoryList: [],
      flowEngineList: [],
      CategoryText:''
    }
  },
  filters: {
    getCategoryText(id, categoryList) {
      let item = categoryList.filter(o => o.code == id)[0]
      return item && item.name ? item.name : ''
    }
  },
  created() {
    this.getDictionaryData()
    this.getFlowEngineList()
    this.initData()
  },
  methods: {
    handleNodeClick(data){
      this.flowCategory = data.id
      this.CategoryText = data.name
      this.search()
    },
    toProcess(item){
      let data = {
        id: item.processId,
        enCode: item.flowCode,
        flowId: item.flowId,
        opType: 1,
        taskNodeId: item.thisStepId,
        taskId: item.id
      }
      this.processVisible = true
      this.$nextTick(()=>{
        this.$refs.flowChart.process(data)
      })
    },
    search() {
      if (this.pickerVal && this.pickerVal.length) {
        this.startTime = this.pickerVal[0]
        this.endTime = this.pickerVal[1]
      } else {
        this.startTime = ''
        this.endTime = ''
      }
      this.listQuery = {
        currentPage: 1,
        pageSize: 10,
        sort: 'desc',
        sidx: ''
      }
      this.initData()
    },
    getFlowEngineList() {
      // FlowEngineListAll().then((res) => {
      //   this.flowEngineList = res.data.list
      // })
    },
    getDictionaryData() {
      this.categoryList =  getFlowCateTree()
    },
    initData() {
      this.listLoading = true
      let query = {
        ...this.listQuery,
        keyword: this.keyword,
        flowTitle: this.flowTitle,
        startTime: this.startTime,
        endTime: this.endTime,
        flowId: this.flowId,
        flowUrgent: this.urgent,
        flowCategory: this.flowCategory,
        creatorUserId: this.creatorUserId
      }
      FlowBeforeList(1, this.listQuery.currentPage,this.listQuery.pageSize,query).then(async res => {
        this.list = res.data.data.records
        this.total = res.data.data.total
        // this.total = res.data.pagination.total
        this.listLoading = false
      })
    },
    toDetail(item) {
      let data = {
        id: item.processId,
        enCode: item.flowCode,
        flowId: item.flowId,
        opType: 1,
        taskNodeId: item.thisStepId,
        taskId: item.id
      }
      this.formVisible = true
      this.$nextTick(() => {
        this.$refs.FlowBox.init(data)
      })
    },
    goBatch() {
      this.batchListVisible = true
      this.$nextTick(() => {
        this.$refs.BatchList.init()
      })
    },
    closeForm(isRefresh) {
      this.formVisible = false
      if (isRefresh) this.refresh()
    },
    refresh() {
      this.pickerVal = ''
      this.startTime = ''
      this.endTime = ''
      this.keyword = ''
      this.flowTitle= ''
      this.flowId = ''
      this.urgent = ''
      this.CategoryText = ''
      this.flowCategory = ''
      this.creatorUserId = ''
      this.listQuery = {
        currentPage: 1,
        pageSize: 10,
        sort: 'desc',
        sidx: ''
      }
      this.initData()
    }
  }
}
</script>
<style lang="scss" scoped>
.SNOWS-flex-main {
  padding: 0 10px 10px 10px;
}

.pagination-container {
  padding-top: 10px;
  border-top: 1px solid #EBEEF5;
}
</style>
