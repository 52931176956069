<template>
  <div>
    <el-dialog
      :title="title"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showDialog"
      width="40%"
      class="qmDialog"
      @close="closeDialog"
      v-if="showDialog"
      :destroy-on-close="true"
    >
      <avue-form
        :option="options"
        ref="addForm"
        v-model="form"
        @submit="submitForm"
      >
        <template slot="userName">
          <el-input
            placeholder="请选择人员名称"
            v-model="form.userName"
            suffix-icon="el-icon-search"
            @focus="clickOpenPersonSelection"
          >
          </el-input>
        </template>
      </avue-form>
      <span slot="footer">
        <el-button size="small" @click="closeDialog">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="submitBtn"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 人员选择弹窗 -->
    <el-dialog title="人员选择" :visible.sync="deptShow" width="80%" top="8vh">
      <UserDetpDialog
        ref="UserDetpDialog"
        @select-data="selectDataConfirm"
      ></UserDetpDialog>
    </el-dialog>
  </div>
</template>

  <script>
  import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
import userSelect from "@/views/business/safe/components/user-select";
import { mapGetters } from "vuex";
import {
  erexercisememberSubmit,
  erexercisememberGetDetail,
} from "@/api/contingency/emergencyDrill";
export default {
  components: { userSelect , UserDetpDialog},
  data() {
    return {
      showDialog: false,
      dialogLoading: false,
      form: {},
      title: "",
      dialogType: "",
      selectData: [], // 选中的人员
      exeId: "",
      detailsId: "",
      deptShow: false, //选择人员弹框
      deptCategory: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    options() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "人员名称",
            prop: "userName",
            span: 12,
            formslot: true,
            placeholder: "请选择人员名称",
            rules: [
              {
                required: true,
                message: "请选择人员名称",
              },
              { max: 120, message: '人员名称不能超过120个字符', trigger: 'blur' }
            ],
          },
          {
            label: "联系方式",
            prop: "tele",
            span: 12,
            placeholder: "请选择人员名称",
            rules: [
              {
                required: true,
                message: "请输入联系方式",
                trigger: "blur",
              },
              { pattern: /^1[3456789]\d{9}$/, message: "手机号码格式错误" },
            ],
          },
          {
            label: "部门组织",
            prop: "orgName",
            disabled: true,
            span: 24,
            placeholder: "请选择人员名称",
            // rules:[
            //   { max: 120, message: '部门组织不能超过120个字符', trigger: 'blur' }
            // ]
          },
          {
            label: "岗位职责",
            prop: "postName",
            span: 24,
            placeholder: "请输入此人在应急预案中的岗位职责说明",
            rules:[
              { max: 120, message: '岗位职责不能超过120个字符', trigger: 'blur' }
            ]
          },
        ],
      };
    },
  },
  methods: {
    // 初始化弹框
    init(type, row) {
      this.showDialog = true;
      this.dialogType = type;
      this.deptCategory = [];
      this.deptCategory.push(this.userInfo.deptCategory);
      if (type == "add") {
        this.detailsId = "";
        this.exeId = row.exeId;
        this.form = { exeId: row.exeId };
        this.title = "人员新增";
      } else if (type == "edit") {
        this.title = "人员编辑";
        this.detailsId = row.id;
        this.getDetails();
      } else if (type == "view") {
        this.title = "人员查看";
        this.detailsId = row.id;
        this.getDetails();
      }
    },
    // 获取详情
    getDetails() {
      erexercisememberGetDetail(this.detailsId).then((res) => {
        this.form = res.data.data;
        this.exeId = this.form.exeId;
      });
    },
    // 点击打开人员选择弹框
    clickOpenPersonSelection() {
      this.deptShow = true;
      // this.$refs.userSelect.init(this.selectData, "contingency");
    },
    // 人员选择弹框
    selectDataConfirm(row) {
      if(row){
        if(this.isRepeat(row)){ // 没找到 则添加
          this.deptShow = false;
          this.form.userName = row.realName;
          this.form.tele = row.phone;
          this.form.orgName = row.deptName;
          this.form.orgId = row.deptId;
          this.form.userId = row.id;
        }else{ // 找到了则提醒重新添加人员
          this.$message.warning('已存在该人员，请重新添加');
        }
      }
    },
    // 选择筛选是否和父级table重复
    isRepeat(selectUser) {
      let tableData = this.$parent.tableData;
      const res = tableData.some((el) => el.userId == selectUser.id);
      // res == true 就是找到了同个人员
      return !res;
    },
    // 人员选择弹框
    handleUserSelectConfirm(data) {
      this.selectData = data;
      if (data && data.length != 0) {
        if (this.isRepeat(data[0])) {
          // 没找到 则添加
          this.form.userName = data[0].realName;
          this.form.tele = data[0].phone;
          this.form.orgName = data[0].deptName;
          this.form.orgId = data[0].deptId;
          this.form.userId = data[0].id;
        } else {
          // 找到了则提醒重新添加人员
          this.$message.warning("已存在该人员，请重新添加");
        }
      }
    },
    // 拉黑提交
    submitBtn() {
      this.$refs.addForm.submit();
    },
    submitForm() {
      this.$refs.addForm.validate((valid, done) => {
        if (valid) {
          this.dialogLoading = true;
          this.dialogSubmit(done);
        }
      });
    },
    // 保存
    dialogSubmit(done) {
      erexercisememberSubmit(this.form)
        .then((res) => {
          const { msg, data } = res.data;
          if (res.data.code == 200) {
            this.$parent.searchList(this.exeId);
            this.$message.success(
              this.$t("cip.cmn.msg.success.operateSuccess")
            );
          } else {
            this.$message.success(msg);
          }
          this.$emit("callback");
          this.form = {};
          this.$refs.addForm.resetFields();
          this.showDialog = false;
          this.dialogLoading = false;
          done();
        })
        .catch((err) => {})
        .finally(() => {
          done();
          this.dialogLoading = false;
        });
    },
    closeDialog() {
      this.form = {};
      this.$refs.addForm.resetFields();
      this.showDialog = false;
    },
  },
};
</script>
