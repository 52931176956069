<template>
  <div style="background-color: #ffffff">
    <head-layout
      :head-title="headTitle"
      :head-btn-options="headBtnOptions"
      @head-save="headSave"
      @head-submit="headSubmit"
      @head-execute-submit="headExecuteSubmit"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <avue-form :option="formOptions" ref="addForm" v-model="addForm">
        <template slot="exeCode">
          <el-input
            placeholder="请输入计划编号"
            v-model="addForm.exeCode"
            :disabled="addForm.isAutomatic || pageDisabled || addForm.id"
          >
            <span slot="append">
              自动生成
              <el-switch
                :disabled="pageDisabled || addForm.id"
                v-model="addForm.isAutomatic"
                active-color="#13ce66"
                @change="handleSwitch"
              >
              </el-switch>
            </span>
          </el-input>
        </template>
        <template slot="planName">
          <el-input
            placeholder="请选择应急预案"
            v-model="addForm.planName"
            :disabled="pageDisabled || addForm.erStatus == 'PREPARE'"
            @focus="selectPlan"
          >
            <i slot="suffix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </template>
        <template slot="files">
          <el-upload
            :disabled="pageDisabled"
            :class="{ uoloadSty: showImg, disUoloadSty: noneUploadFile }"
            action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
            accept=".doc,.docx,.pdf,.zip,.png,.jpg"
            :limit="1"
            :file-list="fileArray"
            :headers="headers"
            :on-success="uploadSuccess"
            :on-remove="handleRemove"
            :on-exceed="uploadExceed"
            :on-preview="handleFilePreview"
            :before-upload="handleBeforeUpload"
          >
            <el-button size="small"
              ><i class="el-icon-upload2"></i> 上传文件</el-button
            >
            <div slot="tip" class="el-upload__tip">
              支持扩展名: .zip,.doc,.docx,.pdf,.png,.jpg
            </div>
          </el-upload>
        </template>
      </avue-form>
      <!-- tab标签 -->
      <tabsPane v-show="addForm.id" ref="tabsPane"></tabsPane>
      <!-- 选择预案 -->
      <emergencyPlanDialog
        ref="emergencyPlanDialog"
        @changeEmergencyPlan="changeEmergencyPlan"
      ></emergencyPlanDialog>
      <fileView ref="fileView" title="附件预览"></fileView>
    </div>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import tabsPane from "@/views/business/contingency/emergencyDrill/components/tabsPane";
import emergencyPlanDialog from "@/views/business/contingency/emergencyDrill/components/emergencyPlanDialog.vue";
import {
  erexerciseAdd,
  erexerciseGetDetail,
  erexerciseresultSubmit,
} from "@/api/contingency/emergencyDrill";
import { erplanGetDetail } from "@/api/contingency/emergencyPlan";
import { getPageCode } from "@/api/system/serialNumber";
import { mapGetters } from "vuex";
import website from "@/config/website";
export default {
  components: {
    HeadLayout,
    tabsPane,
    emergencyPlanDialog,
  },
  data() {
    return {
      headTitle: "",
      formType: "",
      addForm: {
        isAutomatic: true,
      },
      preparePlan: false, // 默认从演练新增 为true 证明从预案点击演练计划而来
      fileArray: [],
      noneUploadFile: false
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    pageDisabled() {
      // 如果查看 或者 提交状态（未执行）则禁用 已执行禁用
      if (
        this.formType == "view" ||
        (this.addForm.erStatus && this.addForm.erStatus != "PREPARE")
      ) {
        return true;
      } else {
        return false;
      }
    },
    headBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.formType) && !this.pageDisabled) {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
        result.push({
          label: "提交",
          emit: "head-submit",
          type: "button",
          btnOptType: "refer",
        });
      }
      // 如果是点击执行进入 此时状态 APPROVING
      if (this.addForm.erStatus == "APPROVING") {
        result.push({
          label: "提交",
          emit: "head-execute-submit",
          type: "button",
        });
      }
      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
    formOptions() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
            label: "计划编号",
            prop: "exeCode",
            span: 8,
            formslot: true,
            placeholder: "请输入计划编号",
            rules: [
              {
                required: true,
                message: "请输入计划编号",
                trigger: "blur",
              },
            ],
          },
          {
            label: "计划名称",
            prop: "exeName",
            span: 8,
            disabled: this.pageDisabled,
            placeholder: "请输入计划名称",
            rules: [
              {
                required: true,
                message: "请输入计划名称",
                trigger: "blur",
              },
              {
                max: 120,
                message: "计划名称不能超过120个字符",
                trigger: "blur",
              },
            ],
          },
          {
            label: "应急预案",
            prop: "planName",
            span: 8,
            formslot: true,
            placeholder: "请输入应急预案",
            rules: [
              {
                required: true,
                message: "请输入应急预案",
              },
            ],
          },
          {
            label: "计划开始日期",
            prop: "startDate",
            span: 8,
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            placeholder: "请选择计划开始日期",
            disabled: this.pageDisabled,
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
              },
            },
            rules: [
              {
                required: true,
                message: "请选择计划开始日期",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  const endDate = this.addForm.endDate;
                  if (endDate && value > endDate) {
                    callback(new Error("起始时间不能晚于结束时间"));
                  } else if (endDate && value == endDate) {
                    callback(new Error("起始时间不能等于结束时间"));
                  } else {
                    callback();
                  }
                },
                trigger: "blur",
              },
            ],
          },
          {
            label: "计划结束日期",
            prop: "endDate",
            span: 8,
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            placeholder: "请选择计划结束日期",
            disabled: this.pageDisabled,
            pickerOptions: {
              disabledDate: this.endPickerOptions,
            },
            rules: [
              {
                required: true,
                message: "请选择计划结束日期",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  const startDate = this.addForm.startDate;
                  if (startDate && value < startDate) {
                    callback(new Error("结束时间不能早于起始时间"));
                  } else if (startDate && value == startDate) {
                    callback(new Error("结束时间不能等于起始时间"));
                  } else {
                    callback();
                  }
                },
                trigger: "blur",
              },
            ],
          },
          {
            label: "实际执行日期",
            prop: "exeDate",
            span: 8,
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            placeholder: "请选择实际执行日期",
            disabled: this.pageDisabled,
            display:
              this.addForm.erStatus == "APPROVING" ||
              this.addForm.erStatus == "FINISHED", // 当前页面提交APPROVING和完成均展示该字段
            rules: [
              {
                required: true,
                message: "请选择实际执行日期",
                trigger: "blur",
              },
            ],
          },
          {
            label: "备注说明",
            prop: "remark",
            disabled: this.pageDisabled,
            span: 24,
            type: "textarea",
            placeholder: "请输入备注说明",
            rules: [
              {
                max: 250,
                message: "备注说明不能超过250个字符",
                trigger: "blur",
              },
            ],
          },
          // {
          //   label: "附件上传",
          //   prop: "fileArray",
          //   dataType: "object",
          //   headers: this.headers,
          //   type: "upload",
          //   accept: ".zip,.doc,.docx,.pdf",
          //   limit: 1,
          //   fileSize: 50000,
          //   disabled: this.pageDisabled,
          //   placeholder: "请上传文件",
          //   propsHttp: {
          //     name: "originalName",
          //     url: "link",
          //     res: "data",
          //   },
          //   fileText: "上传文件",
          //   tip: "支持扩展名：.zip .doc .docx .pdf",
          //   span: 24,
          //   action:
          //     "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
          // },
          {
            label: "附件",
            prop: "files",
            formSlot: true,
            span: 24,
          },
        ],
      };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    // 初始化新增/编辑/查看页面
    async init() {
      let type = this.$route.query.type;
      this.formType = type;
      this.headTitle = "应急演练基本信息";
      if (this.formType == "add") {
        await this.handleSwitch();
        let preparePlan = this.$route.query.preparePlan;
        this.preparePlan = preparePlan;
        if (preparePlan) {
          // 代表从预案中跳转过来新增的 此时的id为预案planId
          this.changeEmergencyPlan({ id: this.$route.query.id });
        }
      } else if (this.formType == "edit") {
        this.formId = this.$route.query.id;
        this.getDetails(this.formId);
      } else if (this.formType == "view") {
        this.formId = this.$route.query.id;
        this.noneUploadFile = true
        this.getDetails(this.formId);
      } else if (this.formType == "execute") {
        this.formId = this.$route.query.id;
        this.noneUploadFile = true
        this.getDetails(this.formId);
      }
    },
    // 基本信息预案编号是否自动生成
    async handleSwitch() {
      if (this.addForm.isAutomatic) {
        await this.getPageCode();
      } else {
        this.addForm.exeCode = "";
      }
    },
    async getPageCode() {
      // 获取编码
      await getPageCode({ ruleCode: "ER_CODE" }).then((res) => {
        this.$set(this.addForm, "exeCode", res.data.data || "");
        this.$forceUpdate();
      });
    },
    // 设置结束时间不能小于起始时间
    endPickerOptions(time) {
      if (this.addForm.startDate) {
        return time.getTime() < Date.parse(this.addForm.startDate);
      } else {
        return null;
      }
    },
    uploadExceed(limit, files, fileList, column) {
      // console.log(limit, files, fileList, column)
      this.$message.error("只能上传一个文件，请先删除当前文件");
    },
    // 上传之前的函数
    handleBeforeUpload(file) {
      let fileName = file.name;
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length).toLowerCase();
      let arr = [".zip", ".doc", ".docx", ".pdf"];
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
        return false;
      }
      if (!arr.includes(lastName)) {
        this.$message.warning("不支持上传此类型文件");
        return false;
      }
    },
    // 上传成功
    uploadSuccess(response, file, fileList) {
      // fieldName 表单中向后端传递的字段名
      this.fileArray = fileList.map((item) => {
        if (item.response) {
          item.response.data.url = item.response.data.link;
          item.response.data.name = item.response.data.originalName
          return item.response.data;
        } else {
          return item;
        }
      });
    },
    // 删除文件
    handleRemove(file, fileList) {
      this.fileArray = fileList.map((item) => {
        if (item.response) {
          return item.response.data;
        } else {
          return item;
        }
      });
    },
    handleFilePreview(item) {
      this.$refs.fileView.showFile(item.link)
    },
    headSave() {
      this.saveOrSubmitFor("PREPARE");
    },
    // 保存提交改变状态
    saveOrSubmitFor(erStatus) {
      this.$refs.addForm.validate((valid, done) => {
        if (valid) {
          let emergencyOrganization =
            this.$refs.tabsPane.$refs.emergencyOrganization;
          if (emergencyOrganization && emergencyOrganization.imageUrl) {
            this.addForm.orgImgUrl = emergencyOrganization.imageUrl
              ? emergencyOrganization.imageUrl
              : this.addForm.orgImgUrl;
          }
          if (this.fileArray && this.fileArray.length != 0) {
            this.addForm.files = JSON.stringify(this.fileArray);
          }
          this.saveLoading = true;
          this.addForm.erStatus = erStatus;
          this.addForm.orgId = this.userInfo.dept_id;
          erexerciseAdd(this.addForm)
            .then((res) => {
              const { msg, data } = res.data;
              if (res.data.code == 200) {
                this.addForm = data;
                this.formId = data.id;
                this.getDetails();
                if (erStatus == "PREPARE") {
                  // 如果提交是保存 则 根据planId 查出预案的
                  this.$refs.addForm.disabled = false;
                } else {
                  this.$refs.addForm.disabled = true;
                }
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
              } else {
                this.$message.error(msg);
              }
              // this.$refs.addForm.resetFields();
              done();
              this.saveLoading = false;
            })
            .catch((err) => {
              done();
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
    // 提交判断
    submitValidate() {
      if (!this.addForm.id) {
        this.$message.error("请先填写基本信息并保存");
        return true;
      }
      let emergencyOrganization =
        this.$refs.tabsPane.$refs.emergencyOrganization;
      if (emergencyOrganization && emergencyOrganization.imageUrl) {
        this.addForm.orgImgUrl = emergencyOrganization.imageUrl;
      } else {
        this.$message.error("请上传组织架构图！");
        return true;
      }
      if (
        this.$refs.tabsPane.$refs.emergencyOrganization.tableData &&
        this.$refs.tabsPane.$refs.emergencyOrganization.tableData.length == 0
      ) {
        this.$message.error("请先填写应急组织中人员清单数据再提交");
        return true;
      }
      // if (
      //   this.$refs.tabsPane.$refs.disposalProcedures.tableData &&
      //   this.$refs.tabsPane.$refs.disposalProcedures.tableData.length == 0
      // ) {
      //   this.$message.error("请先填写处置程序数据再提交");
      //   return true;
      // }
      // if (
      //   this.$refs.tabsPane.$refs.disposalMeasures.tableData &&
      //   this.$refs.tabsPane.$refs.disposalMeasures.tableData.length == 0
      // ) {
      //   this.$message.error("请先填写处置措施数据再提交");
      //   return true;
      // }
      if (
        this.$refs.tabsPane.$refs.emergencySupplies.tableData &&
        this.$refs.tabsPane.$refs.emergencySupplies.tableData.length == 0
      ) {
        this.$message.error("请先填写应急物资数据再提交");
        return true;
      }
    },
    // 保存后提交
    headSubmit() {
      if (!this.submitValidate()) {
        // 提交改为 APPROVING 待执行 FINISHED 已完成 只能查看
        this.saveOrSubmitFor("APPROVING");
      }
    },
    // 执行进入的提交
    headExecuteSubmit() {
      let executionResults = this.$refs.tabsPane.$refs.executionResults;
      if (executionResults) {
        executionResults.$refs.resultsForm.validate((valid, done) => {
          if (valid) {
            let fileList = executionResults.resultsForm.fileList;
            let imgArray = [];
            if (fileList.length != 0) {
              fileList.map((ele) => {
                delete ele.url;
                imgArray.push(ele);
              });
            }
            let obj = {
              exeId: this.addForm.id,
              exeSummary: executionResults.resultsForm.exeSummary,
              problemDesc: executionResults.resultsForm.problemDesc,
              treatmentDesc: executionResults.resultsForm.treatmentDesc,
              files: JSON.stringify(imgArray),
              signFileUrl: JSON.stringify(executionResults.signFileUrl),
              rateFileUrl: JSON.stringify(executionResults.rateFileUrl)
            };
            erexerciseresultSubmit(obj)
              .then((res) => {
                const { msg, data } = res.data;
                if (res.data.code == 200) {
                  this.$message.success(
                    this.$t("cip.cmn.msg.success.operateSuccess")
                  );
                  this.saveOrSubmitFor("FINISHED");
                } else {
                  this.$message.error(msg);
                }
                // this.$refs.addForm.resetFields();
                done();
              })
              .catch((err) => {
                done();
              })
              .finally(() => {});
          } else {
            this.$message.warning("请完善执行结果信息");
          }
        });
      }
    },
    headCancel() {
      this.$refs.addForm.resetFields();
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    //   获取当前时间
    getNowDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    // 获取详情
    getDetails() {
      erexerciseGetDetail(this.formId).then((res) => {
        this.addForm = { ...res.data.data };
        if (this.addForm.files && this.addForm.files.length != 0) {
          this.fileArray = JSON.parse(this.addForm.files);
        }
        if (this.addForm.erStatus == "APPROVING") {
          this.addForm.exeDate = this.getNowDate();
        }
        this.$refs.tabsPane.init(
          this.addForm.id,
          this.pageDisabled,
          this.addForm.erStatus,
          this.addForm.orgImgUrl
        );
      });
    },
    // 打开选择应急预案的弹框
    selectPlan() {
      this.$refs.emergencyPlanDialog.init();
    },
    // 打开预案弹框选择的数据回显 返回id
    changeEmergencyPlan(row) {
      this.getPlanDetail(row.id);
    },
    // 根据ID查预案表单详情
    getPlanDetail(id) {
      erplanGetDetail(id).then((res) => {
        this.addForm.planName = res.data.data.planName;
        this.addForm.planId = res.data.data.id;
        this.addForm.planType = res.data.data.planType;
        this.addForm.startDate = this.getNowDate();
        this.addForm.endDate = res.data.data.endDate;
        this.addForm.orgImgUrl = res.data.data.orgImgUrl;

        if (this.preparePlan) {
          // 如果是从预案中过来默认名称填上
          this.addForm.exeName = res.data.data.planName;
          this.saveOrSubmitFor("PREPARE");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.formContentBox {
  padding: 12px;
  min-height: calc(100% - 76px);
}
.disUoloadSty {
  ::v-deep .el-upload--picture-card{
    display: none;
  }
  ::v-deep .el-upload__tip{
    display: none;
  }
  ::v-deep .el-upload--text{
    display: none;
  }
}
</style>
