<template>
  <div class="formContent">
    <head-layout head-title="相关方"
                 :head-btn-options="headBtnOptions"
                 @head-save="subMit('ruleForm')">
    </head-layout>
    <el-form
      style="padding: 10px"
      :model="dataForm"
      :rules="rules"
      ref="ruleForm"
      :disabled="disabled"
      label-width="200px"
    >
<!--      <div style="display: flex; justify-content: flex-end">-->
<!--        <el-button-->
<!--          type="primary"-->
<!--          style="margin-bottom: 12px"-->
<!--          @click="subMit('ruleForm')"-->
<!--          v-if="this.$route.query.type != '' && this.$route.query.type != 'view'"-->
<!--          >{{$t('cip.cmn.btn.saveBtn')}}</el-button-->
<!--        >-->
<!--      </div>-->
      <el-row>
        <!--        发送方来源-->
        <el-col span="12">
          <el-form-item
            :label="
              this.$t('cip.plat.message.template.parties.field.sendFromType')
            "
            prop="sendFromType"
          >
            <el-radio-group v-model="dataForm.sendFromType" :disabled="this.$route.query.type == 'view' || this.$route.query.type == ''">
              <el-radio :label="1">{{
                this.$t(
                  "cip.plat.message.template.parties.field.presetpPersonnel"
                )
              }}</el-radio>
              <el-radio :label="2" v-if="mouldShow">{{
                this.$t(
                  "cip.plat.message.template.parties.field.inputParameter"
                )
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <!--        发送方-->
        <el-col span="12" v-if="dataForm.sendFromType == 1">
          <el-form-item
            :label="this.$t('cip.plat.message.template.parties.field.sendAccount')"
            prop="sendSetValue"
          >
            <el-input
              v-model="dataForm.sendSetValue"
              :placeholder="
                this.$t('cip.cmn.rule.inputWarning') +
                this.$t('cip.plat.message.template.parties.field.sendAccount')
              "
              :disabled="this.$route.query.type == 'view'"
              readonly
              @click.native="showPersonDialog"
            ></el-input>
          </el-form-item>
        </el-col>
        <!--        输入参数-->
        <el-col span="12" v-if="dataForm.sendFromType == 2">
          <el-form-item
            :label="
              this.$t('cip.plat.message.template.parties.field.inputParameter')
            "
            prop="sendSetParam"
          >
            <el-select
              v-model="dataForm.sendSetParam"
              :placeholder="
                this.$t('cip.cmn.rule.inputWarning') +
                this.$t(
                  'cip.plat.message.template.parties.field.inputParameter'
                )
              "
              :disabled="this.$route.query.type == 'view'"
            >
              <el-option
                v-for="item in sendSetParamOptions"
                :key="item.paramKey"
                :label="item.paramName"
                :value="item.paramKey"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <!--        接收方来源-->
        <el-col span="12">
          <el-form-item
            :label="
              this.$t('cip.plat.message.template.parties.field.receiveFromType')
            "
            prop="receiveFromType"
          >
            <el-radio-group v-model="dataForm.receiveFromType" :disabled="this.$route.query.type == 'view' || this.$route.query.type == ''">
              <el-radio :label="1">{{
                this.$t(
                  "cip.plat.message.template.parties.field.presetpPersonnel"
                )
              }}</el-radio>
              <el-radio :label="2" v-if="mouldShow">{{
                this.$t(
                  "cip.plat.message.template.parties.field.inputParameter"
                )
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <!--        人员过滤方式-->
        <el-col span="12" v-if="dataForm.receiveFromType == 1">
          <el-form-item
            :label="
              this.$t('cip.plat.message.template.parties.field.receiveSetType')
            "
            prop="receiveSetType"
          >
            <el-select
              v-model="dataForm.receiveSetType"
              @change="chooseSetType"
              :placeholder="
                this.$t('cip.cmn.rule.inputWarning') +
                this.$t(
                  'cip.plat.message.template.parties.field.receiveSetType'
                )
              "
              :disabled="this.$route.query.type == 'view'"
            >
              <el-option
                v-for="item in receiveSetTypeOptions"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!--        输入参数-->
        <el-col span="12" v-if="dataForm.receiveFromType == 2">
          <el-form-item
            :label="
              this.$t('cip.plat.message.template.parties.field.inputParameter')
            "
            prop="receiveSetParam"
          >
            <el-select
              v-model="dataForm.receiveSetParam"
              :placeholder="
                this.$t('cip.cmn.rule.inputWarning') +
                this.$t(
                  'cip.plat.message.template.parties.field.inputParameter'
                )
              "
              :disabled="this.$route.query.type == 'view'"
            >
              <el-option
                v-for="item in sendSetParamOptions"
                :key="item.paramKey"
                :label="item.paramName"
                :value="item.paramKey"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <!--        角色-->
        <el-col
          :span="12"
          v-if="(dataForm.receiveSetType == 1 || dataForm.receiveSetType == 3) && dataForm.receiveFromType == 1"
        >
          <el-form-item
            :label="this.$t('cip.plat.message.template.parties.field.role')"
            prop="role"
          >
            <avue-input-tree
              default-expand-all
              v-model="dataForm.role"
              :multiple="true"
              :placeholder="
                this.$t('cip.cmn.rule.selectWarning') +
                this.$t('cip.plat.message.template.parties.field.parentId')
              "
              :dic="roleDic"
            ></avue-input-tree>
          </el-form-item>
        </el-col>
        <!--        组织-->
        <el-col
          :span="12"
          v-if="(dataForm.receiveSetType == 2 || dataForm.receiveSetType == 3) && dataForm.receiveFromType == 1"
        >
          <el-form-item
            :label="this.$t('cip.plat.message.template.parties.field.organ')"
            prop="organ"
          >
            <avue-input-tree
              default-expand-all
              v-model="dataForm.organ"
              :multiple="true"
              :placeholder="
                this.$t('cip.cmn.rule.selectWarning') +
                this.$t('cip.plat.message.template.parties.field.organ')
              "
              :dic="organDic"
            ></avue-input-tree>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <!--        所属岗位-->
        <el-col :span="12" v-if="dataForm.receiveSetType == 5 && dataForm.receiveFromType == 1">
          <el-form-item
            :label="this.$t('cip.plat.message.template.parties.field.postId')"
            prop="postId"
          >
            <!--            <el-select v-model="dataForm.postId"-->
            <!--                       :placeholder="this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.message.template.parties.field.postId')">-->
            <!--              <el-option-->
            <!--                v-for="item in postIdOptions"-->
            <!--                :key="item.id"-->
            <!--                :label="item.postName"-->
            <!--                :value="item.id">-->
            <!--              </el-option>-->
            <!--            </el-select>-->
            <avue-input-tree
              default-expand-all
              v-model="dataForm.postId"
              :multiple="true"
              :placeholder="
                this.$t('cip.cmn.rule.selectWarning') +
                this.$t('cip.plat.message.template.parties.field.postId')
              "
              :dic="postIdOptions"
            ></avue-input-tree>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div v-if="dataForm.receiveSetType == 4 && dataForm.receiveFromType == 1">
      <head-layout
        style="margin-bottom: 10px"
        :head-title="
          $t('cip.plat.message.template.parties.title.appointHeadTitle')
        "
        :head-btn-options="gridHeadBtn"
        @head-add-selection="headAddSelection"
        @head-romve="handleDelete"
      ></head-layout>
      <el-table
        :data="dataForm.receiveSetValue"
        :tableLoading="tableLoading"
        @selection-change="selectionChange"
        size="medium"
        style="width: 100%"
      >
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column type="index" :label="this.$t('cip.plat.message.template.parties.field.sort')" align="center" width="55">
        </el-table-column>
        <el-table-column
          prop="userName"
          :label="$t('cip.plat.sys.user.field.realName')"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="deptName"
          :label="$t('cip.plat.sys.user.field.deptName')"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="roleName"
          :label="$t('cip.plat.sys.user.field.roleName')"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="contentFormat"
          :label="$t('cip.plat.sys.user.field.contentFormat')"
          align="center"
        >
          <template slot-scope="scope">
            <el-input
              class="item"
              :disabled="type == 'detail' || type == 'view'"
              v-model="scope.row.contentFormat"
              :placeholder="$t('cip.plat.sys.user.field.contentFormat')"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column :label="this.$t('cip.plat.message.template.parties.field.operate')" align="center" v-if="this.$route.query.type != 'view' && this.$route.query.type != ''">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="deleteRow(scope.$index)"
              type="text"
              size="small"
            >
              {{$t('cip.cmn.btn.delBtn')}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      v-dialog-drag
      :title="dialogTitle"
      :visible.sync="showDialog"
      class="avue-dialog avue-dialog--top"
      width="80%"
    >
      <design-person
        ref="designPerson"
        :member-table-data.sync="dataForm.receiveSetValue"
        :headForm.sync="headForm"
      ></design-person>
    </el-dialog>
    <left-person
      ref="leftDialog"
      :title="dialogTitle"
      :select-data="selectItem"
    >
    </left-person>
  </div>
</template>
<script>
import {
  getParmsList,
  selectById,
  saveOrUpdate,
} from "@/api/message/templateParties";
import { getDictionary } from "@/api/system/dictbiz";
import { getRoleTreeById } from "@/api/system/role";
import { getDeptTree } from "@/api/system/dept";
import { getPostList } from "@/api/system/post";
import DesignPerson from "@/views/message/template/common/designPerson";
import LeftPerson from "@/views/message/template/common/leftPerson";
export default {
  name: "templateParties",
  props: {
    headForm: {
      type: Object,
      default: {},
    },
    type: {
      type: String,
      default: "view",
    },
    disabled:{
      type: Boolean,
      default: false,
    }
  },
  components: {
    DesignPerson,
    LeftPerson
  },
  computed: {
    gridHeadBtn() {
      let result = [];
      if (["add", "edit"].includes(this.type)) {
        result = [
          {
            btnOptType: "add",
            label: this.$t("cip.cmn.btn.addBtn"),
            emit: "head-add-selection",
          },
          {
            label: this.$t("cip.cmn.btn.delBtn"),
            emit: "head-romve",
            type: "button",
            icon: "",
          },
        ];
      }
      return result;
    },
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push({
          label: this.$t('cip.cmn.btn.saveBtn'),
          emit: "head-save",
          type: "button",
          icon: ""
        })
      }
      return result;
    },
  },
  data() {
    return {
      dataForm: {
        sendFromType: null,
        sendSetValue: null,
        sendSetParam: null,
        receiveFromType: null,
        receiveSetType: null,
        receiveSetParam: null,
        role: null,
        organ: null,
        postId: null,
      },
      dialogTitle: this.$t('cip.plat.sys.user.title.indexHeadTitle'),
      showDialog: false,
      tableLoading: false,
      mouldShow: false,
      sendSetParamOptions: [],
      receiveSetTypeOptions: [],
      roleDic: [],
      organDic: [],
      postIdOptions: [],
      selectionList: [],
      rules: {
        sendFromType: [
          {
            required: true,
            message:
              this.$t("cip.cmn.rule.inputWarning") +
              this.$t("cip.plat.message.template.parties.field.sendFromType"),
            trigger: "blur",
          },
        ],
        sendSetValue: [
          {
            required: true,
            message:
              this.$t("cip.cmn.rule.inputWarning") +
              this.$t("cip.plat.message.template.parties.field.sendAccount"),
            trigger: "blur",
          },
        ],
        sendSetParam: [
          {
            required: true,
            message:
              this.$t("cip.cmn.rule.inputWarning") +
              this.$t("cip.plat.message.template.parties.field.inputParameter"),
            trigger: "blur",
          },
        ],
        receiveFromType: [
          {
            required: true,
            message:
              this.$t("cip.cmn.rule.inputWarning") +
              this.$t(
                "cip.plat.message.template.parties.field.receiveFromType"
              ),
            trigger: "blur",
          },
        ],
        receiveSetType: [
          {
            required: true,
            message:
              this.$t("cip.cmn.rule.inputWarning") +
              this.$t("cip.plat.message.template.parties.field.receiveSetType"),
            trigger: "blur",
          },
        ],
        inputParameter: [
          {
            required: true,
            message:
              this.$t("cip.cmn.rule.inputWarning") +
              this.$t("cip.plat.message.template.parties.field.inputParameter"),
            trigger: "blur",
          },
        ],
        role: [
          {
            required: true,
            message:
              this.$t("cip.cmn.rule.selectWarning") +
              this.$t("cip.plat.message.template.parties.field.parentId"),
            trigger: "blur",
          },
        ],
        organ: [
          {
            required: true,
            message:
              this.$t("cip.cmn.rule.selectWarning") +
              this.$t("cip.plat.message.template.parties.field.organ"),
            trigger: "blur",
          },
        ],
        postId: [
          {
            required: true,
            message:
              this.$t("cip.cmn.rule.selectWarning") +
              this.$t("cip.plat.message.template.parties.field.postId"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getDictionaryData();
  },
  methods: {
    //打开选择窗口
    showPersonDialog(){
      this.$refs.leftDialog.openDialog();
    },
    //选择发送方
    selectItem(row, done) {
      this.dataForm.sendSetValue = row.realName;
      done();
    },
    chooseSetType(e) {
      if (e == 3) {
        this.dataForm.organ = new Array();
      }
      if (e == 4) {
        this.dataForm.receiveSetValue = new Array();
      }
    },
    subMit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dataForm.templateId = this.headForm.id;
          let data = this.dataForm;
          if (data.receiveSetType == 1) {
            if (typeof data.role == "string") {
              data.role = data.role.split(",");
            }
            let roleList = data.role.map((e) => ({
              roleId: e,
            }));
            data.receiveSetValue = JSON.stringify(roleList);
          }
          if (data.receiveSetType == 2) {
            if (typeof data.organ == "string") {
              data.organ = data.organ.split(",");
            }
            let organList = data.organ.map((e) => ({
              organId: e,
            }));
            data.receiveSetValue = JSON.stringify(organList);
          }
          if (data.receiveSetType == 3) {
            if (typeof data.role == "string") {
              data.role = data.role.split(",");
            }
            if (typeof data.organ == "string") {
              data.organ = data.organ.split(",");
            }
            let roleList = data.role.map((e) => ({
              roleId: e,
            }));
            let organList = data.organ.map((e) => ({
              organId: e,
            }));
            let roleorgn = {
              orgnList: organList,
              roleList: roleList,
            };
            data.receiveSetValue = JSON.stringify(roleorgn);
          }
          if (data.receiveSetType == 4) {
            data.receiveSetValue = JSON.stringify(data.receiveSetValue);
          }
          if (data.receiveSetType == 5) {
            if (typeof data.postId == "string") {
              data.postId = data.postId.split(",");
            }
            let postlist = data.postId.map((e) => ({
              postId: e,
            }));
            data.receiveSetValue = JSON.stringify(postlist);
          }
          saveOrUpdate(data).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(
                this.$t("cip.cmn.msg.success.operateSuccess")
              );
              this.initData();
            }
          });
        }
      });
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    deleteRow(index) {
      this.$confirm(this.$t(`cip.cmn.msg.warning.delWarning`), {
        confirmButtonText: this.$t(`cip.cmn.btn.defBtn`),
        cancelButtonText: this.$t(`cip.cmn.btn.celBtn`),
        type: "warning",
      }).then(() => {
        this.dataForm.receiveSetValue.splice(index, 1);
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess"),
        });
      });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        this.selectionList.forEach((e) => {
          this.dataForm.receiveSetValue.forEach((v, i) => {
            if (e.userCode === v.userCode) {
              this.dataForm.receiveSetValue.splice(i, 1);
            }
          });
        });
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess"),
        });
      });
    },
    headAddSelection() {
      this.showDialog = true;
    },
    initData() {
      this.tableLoading = true;
      selectById(this.headForm.id).then((res) => {
        if (JSON.stringify(res.data.data) == '{}') {
          this.tableLoading = false;
          return;
        }
        this.dataForm = res.data.data;
        if (this.dataForm.receiveSetType == 1) {
          let rolelist = JSON.parse(this.dataForm.receiveSetValue);
          let rolestr = "";
          if (rolelist.length > 0) {
            for (var i = 0; i < rolelist.length; i++) {
              rolestr += rolelist[i].roleId + ",";
            }
          }
          this.dataForm.role = rolestr;
        }
        if (this.dataForm.receiveSetType == 2) {
          let orglist = JSON.parse(this.dataForm.receiveSetValue);
          let orgstr = "";
          if (orglist.length > 0) {
            for (var i = 0; i < orglist.length; i++) {
              orgstr += orglist[i].organId + ",";
            }
          }
          this.dataForm.organ = orgstr;
        }
        if (this.dataForm.receiveSetType == 3) {
          let obj = JSON.parse(this.dataForm.receiveSetValue);
          let rolestr = "";
          let orgnstr = "";
          if (obj.roleList.length > 0) {
            rolestr = obj.roleList;
          }
          if (obj.orgnList.length > 0) {
            orgnstr = obj.orgnList;
          }
          this.dataForm.role = rolestr;
          this.dataForm.organ = orgnstr;
        }
        if (this.dataForm.receiveSetType == 4) {
          this.dataForm.receiveSetValue = JSON.parse(
            this.dataForm.receiveSetValue
          );
        }
        if (this.dataForm.receiveSetType == 5) {
          let list = JSON.parse(this.dataForm.receiveSetValue);
          let poststr = "";
          if (list.length > 0) {
            for (var i = 0; i < list.length; i++) {
              poststr += list[i].postId + ",";
            }
          }
          this.dataForm.postId = poststr.split(",");
        }
        this.tableLoading = false;
      });
    },
    onloadparms() {
      if (!this.headForm || !this.headForm.id) {
        return;
      }
      getParmsList({
        templateId: this.headForm.id,
      }).then((res) => {
        this.sendSetParamOptions = res.data.data;
      });
    },
    getDictionaryData() {
      getDictionary({
        code: "receive_set_type",
      }).then((res) => {
        this.receiveSetTypeOptions = res.data.data;
      });
      getRoleTreeById().then((res) => {
        this.roleDic = res.data.data;
        this.recurTree(this.roleDic);
      });
      getDeptTree().then((res) => {
        this.organDic = res.data.data;
        this.recurTree(this.organDic);
      });
      getPostList().then((res) => {
        this.postIdOptions = res.data.data;
        this.recurTree(this.postIdOptions);
      });
    },
    recurTree(data) {
      data.forEach((item) => {
        if (item.title) {
          item.label = item.title;
        }
        if (item.children) {
          this.recurTree(item.children);
        }
        if (item.postName) {
          item.label = item.postName;
        }
        if (item.id) {
          item.value = item.id;
        }
      });
    },
    changeMould(mouldShow) {
      this.mouldShow = mouldShow;
    },
  },
};
</script>
<style lang="scss" scoped>
.formContent {
  background-color: #ffffff;
}
</style>
