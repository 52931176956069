<template>
  <div>
      <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
        <el-col :span="12">
          <el-form-item label-width="120px" label="appKey" prop="appKey">
            <el-input v-model="formData.appKey" :placeholder="$t('cip.plat.message.channel.msg.appKey')" show-word-limit clearable
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="120px" label="masterSecret" prop="masterSecret">
            <el-input v-model="formData.masterSecret" :placeholder="$t('cip.plat.message.channel.msg.masterSecret')" clearable
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
  </div>
</template>
<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      formData: {
        appKey: "",
        masterSecret: "",
      },
      rules: {
        appKey: [{
          required: true,
          message: this.$t('cip.plat.message.channel.msg.appKey'),
          trigger: 'blur'
        }],
        masterSecret: [{
          required: true,
          message: this.$t('cip.plat.message.channel.msg.masterSecret'),
          trigger: 'blur'
        }],
      },
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        // TODO 提交表单
      })
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
    },
  }
}

</script>
<style>
</style>
