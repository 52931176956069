
// 系统字典链接
export const DICT = "/api/sinoma-system/dict/dictionary?code=";

//业务字典链接
export const DICT_BIZ = "/api/sinoma-system/dict-biz/dictionary?code=";

//是否启用
export const IS_ENABLE = {
  Y: 'y',
  N: 'n',
}
//是否入库
export const STOCK_IN_STATUS = {
  N: '1',
  Y: '2',
}

