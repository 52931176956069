<template>
  <el-dialog
    :title="title"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :visible.sync="showDialog"
    width="40%"
    class="qmDialog"
    @close="closeDialog"
    v-if="showDialog"
    :destroy-on-close="true"
  >
    <avue-form
      :option="options"
      ref="addForm"
      v-model="form"
      @submit="submitForm"
    ></avue-form>
    <span slot="footer">
      <el-button size="small" @click="closeDialog">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="submitBtn"
        v-loading="dialogLoading"
      >
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
  </el-dialog>
</template>
    
    <script>
import {
  erplantreatmentSubmit,
  erplantreatmentGetDetail,
  erplantreatmentgetMaxSeqNum
} from "@/api/contingency/emergencyPlan";
export default {
  data() {
    return {
      showDialog: false,
      dialogLoading: false,
      form: {},
      title: "",
      dialogType: "",
      planId: "",
      detailsId: "",
    };
  },
  computed: {
    options() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "序号",
            prop: "seqNum",
            span: 12,
            placeholder: "请输入序号",
            rules: [
              {
                required: true,
                message: "请输入序号",
                trigger: "blur",
              },
              { pattern: /^[0-9]*[1-9][0-9]*$/, message: '请输入正整数' }
            ],
          },
          {
            label: "措施描述",
            prop: "treatmentDesc",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入措施描述",
                trigger: "blur",
              },
              { max: 250, message: '措施描述不能超过250个字符', trigger: 'blur' }
            ],
          },
        ],
      };
    },
  },
  methods: {
    init(type, row) {
      this.showDialog = true;
      this.dialogType = type;
      if (type == "add") {
        this.detailsId = "";
        this.planId = row.planId;
        this.form = { planId: row.planId };
        this.getMaxSeqNum()
        this.title = "措施新增";
      } else if (type == "edit") {
        this.title = "措施编辑";
        this.detailsId = row.id;
        this.getDetails();
      } else if (type == "view") {
        this.title = "措施查看";
        this.detailsId = row.id;
        this.getDetails();
      }
    },
    // 获取最大序号值
    getMaxSeqNum(){
      erplantreatmentgetMaxSeqNum({planId:this.planId}).then(res=>{
        if(res.data.code == 200){
          this.form.seqNum = JSON.stringify(res.data.data)==='{}' ? 1 : res.data.data + 1
        }        
      })
    },
    // 获取详情
    getDetails() {
      erplantreatmentGetDetail(this.detailsId).then((res) => {
        this.form = res.data.data;
        this.planId = this.form.planId;
      });
    },
    // 拉黑提交
    submitBtn() {
      this.$refs.addForm.submit();
    },
    submitForm() {
      this.$refs.addForm.validate((valid, done) => {
        if (valid) {
          this.dialogLoading = true;
          this.dialogSubmit(done);
        }
      });
    },
    // 保存
    dialogSubmit(done) {
      erplantreatmentSubmit(this.form)
        .then((res) => {
          const { msg, data } = res.data;
          if (res.data.code == 200) {
            this.$parent.init(this.planId);
            this.$message.success(
              this.$t("cip.cmn.msg.success.operateSuccess")
            );
          } else {
            this.$message.success(msg);
          }
          this.$emit("callback");
          this.form = {};
          this.$refs.addForm.resetFields();
          done();
          this.showDialog = false;
          this.dialogLoading = false;
        })
        .catch((err) => {})
        .finally(() => {
          this.form = {};
          done();
          this.dialogLoading = false;
        });
    },
    closeDialog() {
      this.form = {};
      this.$refs.addForm.resetFields();
      this.showDialog = false;
    },
  },
};
</script>
    