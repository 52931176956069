<template>
  <div>
    <el-dialog
      v-dialog-drag
      :title="$t(`cip.index.app.category.listHeader`)"
      :modal="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showAddIndexWithCategoryDialog"
      v-if="showAddIndexWithCategoryDialog"
      width="60%"
      :fullscreen="fullscreen"
      class="qmDialog"
    >
      <div class="positionabs">
        <i class="el-icon-full-screen" @click="fullClick()"></i>
      </div>
      <avue-form :option="option" v-model="form" ref="formLayout"></avue-form>
      <queryIndexManageWithCategory ref="queryIndexManageWithCategory" :shou-index-manage="true"></queryIndexManageWithCategory>
      <span slot="footer">
        <el-button size="small" @click="showAddIndexWithCategoryDialog = false">{{ $t(`cip.cmn.btn.celBtn`) }}</el-button>
        <el-button size="small" type="primary" @click="save">{{ $t(`cip.cmn.btn.defBtn`) }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import GridLayout from "@/views/components/layout/grid-layout.vue";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import CommonTree from "@/views/components/com_tree/index.vue";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {mapGetters} from "vuex";
import ServerNameEnum from "@/util/ServerNameEnum";
import {sysCategoryWithIndexSave} from "@/api/index/appSystemCategory";
import queryIndexManageWithCategory from "@/views/business/index/appCategory/common/queryIndexManageWithCategory";

const CONTROLLER_NAME = "/appSystemCategory";
export default {
  name: "AddIndexManageWithCategoryWindow",
  components: {
    queryIndexManageWithCategory,
    GridLayout,
    HeadLayout,
    CommonTree
  },
  data() {
    return {
      page: {
        currentPage: 1,
        dataTotal: 0,
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
      },
      showAddIndexWithCategoryDialog: false,
      fullscreen: false,
      form: {
        parentId: 0
      }
    }
  },
  computed: {
    ...mapGetters(["permission"]),
    option() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: this.$t("cip.index.app.category.treeHeader"),
            prop: "parentId",
            type: "tree",
            props: {
              label: 'categoryName',
              value: 'id',
              key: 'parentId',
            },
            placeholder: this.$t(`cip.index.app.category.treeHeader`),
            dicUrl: `${ServerNameEnum.SERVER_INDEX_}/${CONTROLLER_NAME}/tree`,
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.app.category.treeHeader'),
              trigger: "change"
            }],

          }
        ]
      }
    },
  },
  created() {

  },
  methods: {
    openDialog(parentId){
      if (parentId) {
        this.form.parentId = parentId;
      }
      this.showAddIndexWithCategoryDialog = true;
    },
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    save() {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          this.form.indexMappingVOS = this.$refs.queryIndexManageWithCategory.ids();
          if (Array.isArray(this.form.indexMappingVOS) && this.form.indexMappingVOS.length === 0) {
            this.$message({
              message: this.$t("cip.cmn.rule.selectWarning"),
              type: "warning",
            });
            return;
          }
          sysCategoryWithIndexSave(this.form).then(res => {
            const {msg, data} = res.data;
            if (data.code === 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'));
            } else {
              this.$message.success(msg);
            }
            this.showAddIndexWithCategoryDialog = false;
            this.$refs.formLayout.$refs.form.allDisabled = false
            this.$emit('saveExt');
          })
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }
      });

    }
  }
}
</script>
