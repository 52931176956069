<template>
  <div>
    <div class="headers">
      <grid-head-layout
        ref="gridHeadLayout"
        :grid-head-btn="gridHeadBtn"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
        @head-addParam="addParams"
      ></grid-head-layout>
    </div>
    <el-table
      v-if="showSearchTable"
      :data="seeParams"
      border
      style="width: 100%; margin-top: 10px"
    >
      <el-table-column label="字段名称">
        <template slot-scope="scope">
          <el-select
            filterable
            clearable
            v-model="scope.row.fieldName"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in columns"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>

      <el-table-column label="类型">
        <template slot-scope="scope">
          <el-select
            filterable
            clearable
            v-model="scope.row.queryType"
            placeholder="请选择"
            @change="changeQueryType($event, scope.row)"
          >
            <el-option
              v-for="(item, index) in queryTypeList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('cip.dc.dataobject.field.enterValue')"
        width="360"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.queryType && scope.row.queryType !== '-1'">
            <div v-if="scope.row.queryType == '3'">
              <el-input
                clearable
                v-model="scope.row.startValue"
                placeholder="开始"
                style="width: 50%"
              ></el-input>
              <el-input
                clearable
                v-model="scope.row.endValue"
                placeholder="结束"
                style="width: 50%"
              ></el-input>
            </div>
            <el-input
              v-if="scope.row.queryType !== '3'"
              v-model="scope.row.inputValue"
              placeholder="请输入值"
            ></el-input>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="80">
        <template slot-scope="scope">
          <el-button type="text" @click="delParams(scope.row)"
          >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <head-layout :head-title="$t(`cip.index.omission.viewResult`)"> </head-layout>
    <grid-layout
      ref="gridLayOut"
      class="dynTable"
      :page="page"
      :table-options="tableOptions"
      :table-data.sync="tableData"
      :table-loading="tableLoading"
      :data-total="dataTotal"
      @page-current-change="handleCurrentChange"
      @page-size-change="handleSizeChange"
      @page-refresh-change="onLoad"
      v-if="!showLine"
    >
    </grid-layout>
    <div id="line" style="height: 300px; width: 100%" v-if="showLine"></div>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import CommonTree from "@/views/components/com_tree/index"
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
// import {removeCategoryWithIndex} from "@/api/index/appSystemCategory";
// import {appIndexDataApi} from '@/api/index/indexManage'
import {selectPage} from "@/api/index/appInterface";
import {appIndexDataApiIntf} from "@/api/index/indexManage";

export default {
  name: "index",
  components: {

    GridLayout,
    HeadLayout,
    CommonTree
  },

  data() {
    return {
      showSearchTable: false,
      // 查询条件
      seeParams: [
        {
          fieldName: "", //字段名称
          queryType: "", //,
          inputValue: "", //输入值,
          startValue: "", //开始值,
          endValue: "", //结束值
        },
      ],
      columns: [],
      queryTypeList: [
        { label: "等于", value: "1", type: "0" },
        { label: "不等于", value: "2", type: "0" },
        { label: "区间", value: "3", type: "1" },
        { label: "大于", value: "4", type: "0" },
        { label: "小于", value: "5", type: "0" },
        { label: "大于等于", value: "6", type: "0" },
        { label: "小于等于", value: "7", type: "0" },
        { label: "包含", value: "8", type: "1" },
        { label: "不包含", value: "9", type: "1" },
      ],
      xdata: [[], [], [], []],
      ydata: [[], [], [], []],
      legend: [[], [], [], []],
      timeType:'year',
      format:'yyyy',
      page: {
        total: 1000,
        currentPage: 1,
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE
      },
      showLine: true,
      dictData:[],
      tableData: [],
      tableLoading: false,
      selectionList: [],
      query: {},
      dataTotal: 0,
      dataForm: {},
      tableOptionsDyn:[]

    }
  },
  computed: {
    ...mapGetters(["permission"]),
    gridHeadBtn() {
      return [
        // {
        //   label: this.$t(`cip.cmn.btn.addBtn`),
        //   emit: "head-addParam",
        //   type: "button",
        //   icon: "",
        // },
      ]
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: this.$t(`cip.index.omission.indexLabel`),//'序号',
        align: 'center',
        menu: false,
        selection:false,
        column: this.tableOptionsDyn
      }
    },
      searchColumns() {
      return [
        {
          label: "",
          prop: "intfCode",
          span: 4,
          props: {
            label: "intfName",
            value: "intfCode"
          },
          filterable: true,
          type:'select',
          dicData:this.dictData,
          placeholder: this.$t(`cip.index.intf.intfName`),//'接口名称',
          change:(val)=>{
            const data=this.dictData.filter(item=>{

              return item.intfCode==val.value
            })
            this.$refs.gridHeadLayout.searchForm.timeStart='';
            this.$refs.gridHeadLayout.searchForm.timeEnd=''
            const timeType=data[0].timeDimension
            if(timeType=='M'){
              this.timeType='month'
              this.format='yyyy-MM'
            }else if(timeType=='Y'){
              this.timeType='year'
              this.format='yyyy'
            }else if(timeType=='H'){
             this.timeType='datetime'
              this.format='yyyy-MM-dd HH-mm-ss'
            }else if(timeType=="D"){
              this.timeType="date"
              this.format='yyyy-MM-dd'
            }
            this.clearParams()
            if (data[0].isCache == 1) {
              // 缓存， 使用查询接口
              this.showSearchTable = true
            } else {
              this.showSearchTable = false
            }
          }
        },
        {
          label: this.$t('cip.index.intf.timeSelect'),
          labelWidth: 130,
          prop: "timeStart",
          type: this.timeType,
          format:this.format,
          valueFormat: this.format,
          placeholder:this.$t(`cip.index.intf.timeSelectStart`),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.index.intf.timeSelect'),
            trigger: "blur"
          }],
          span: 4,

        },
        {
          label: this.$t('cip.index.intf.timeSelect'),
          labelWidth: 130,
          prop: "timeEnd",
          type: this.timeType,
          format: this.format,
          valueFormat: this.format,
          placeholder: this.$t(`cip.index.intf.timeSelectEnd`),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.index.intf.timeSelect'),
            trigger: "blur"
          }],
          span: 4,

        },
      ]
    },

  },

  created() {
    selectPage(   1,
      9999,
      ).then(res=>{
      const data = res.data.data;
        this.dictData=data.records
    })
    // this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {indexCode: this.$route.query.indexCode});
  },
  methods: {
    clearParams() {
      this.columns.length = 0;
      this.seeParams.length = 0;
      this.seeParams.push({
        fieldName: "", //字段名称
        queryType: "", //,
        inputValue: "", //输入值,
        startValue: "", //开始值,
        endValue: "", //结束值
      })
    },
    changeQueryType(e, row) {
      row.inputValue = ""; //输入值,
      row.startValue = ""; //开始值,
      row.endValue = ""; //结束值
    },
    addParams() {
      this.seeParams.push({
        fieldName: "", //字段名称
        queryType: "", //,
        inputValue: "", //输入值,
        startValue: "", //开始值,
        endValue: "", //结束值
      });
    },
    delParams(row) {
      this.seeParams.forEach((element, index) => {
        if (element == row) {
          this.seeParams.splice(index, 1);
        }
      });
    },
    gridHeadSearch(query) {
      if( new Date(query.timeStart).getTime()> new Date(query.timeEnd).getTime()) return this.$message.error('开始时间不能大于结束时间！！')


      this.query = query;
      this.onLoad(this.page);
    },
    gridHeadEmpty(query) {
      this.query = query;
      this.clearParams()
      this.onLoad(this.page);
    },
    assembleData2(data, property) {
      let arr = [];
      data.forEach((element) => {
        arr.push(element[property]);
      });
      return arr;
    },
    assembleData(data, property) {
      return data.reduce((acc, obj) => {
        let key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        //返回的数据
        acc[key].push(obj.proDcsVal);
        return acc;
      }, {});
    },
    genLineChart(data, lineNum) {
  let that = this;
  this.xdata[lineNum] = [];
  this.ydata[lineNum] = [];
  this.legend[lineNum] = [];
  let charsServe=[]
  let charsName=[]
  let xchars=[]
  let minMax=[]
      Object.keys(data[0]).map((item,index)=>{
        this.tableOptionsDyn.map(idm=>{
          if(item==idm.prop){
            charsName.push(idm.label)
            let chars={
              // name: 'simulation_value',
              name: idm.label,
              type: "line",
              data: [],
              triggerLineEvent: true,
              emphasis: { focus: "series" },
            }
            data.map(itx=>{

              if(idm.prop!='_time'){
                minMax.push(Number(itx[idm.prop]))
              }
              chars.data.push(itx[idm.prop])
            })


            charsServe.push(chars)


          }
        })

      })
      data.map(item=>{

        xchars.push(item._time)
      })
      console.log(charsServe,xchars,'xchars')
      minMax.sort((a,b)=>{
        return a-b
      })
      let min=Number(minMax[0])
      // let max=Number(minMax[minMax.length-1])
      // console.log(minMax,max,min,'minMax')

  let myChart = this.$echarts.getInstanceByDom(
    document.getElementById("line")
  );
  let option = {
    tooltip: {
      // trigger: 'line'+lineNum,
      // // position: function (pt) {
      // //   return [pt[0], '10%'];
      // // }
    },
    legend: {
      data: charsName,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: xchars,
    },
    yAxis: {
      type: "value",

      min:min,

    },
    series: charsServe,
  };
  //检测是否已经存在echarts实例，如果不存在，则不再去初始化
  if (myChart == null) {

    myChart = this.$echarts.init(
      document.getElementById("line")
    );
  }
  myChart.setOption(option);
  window.addEventListener("resize", function () {
    myChart.resize();
  });
},

//每页多少条数据
    handleSizeChange(val) {
      this.page.currentPage = 1;
      this.page.pageSize = val.pageSize;
      this.onLoad(this.page,{sysCategoryId: this.sysCategoryId});
    },
    //当前第几页
    handleCurrentChange(val) {
      this.page.currentPage = val.currentPage;
      this.onLoad(this.page, {sysCategoryId: this.sysCategoryId})
    },


    selectionChange(list) {
      this.selectionList = list;
    },

    onLoad(page, params = {},isMode=false) {

      this.tableOptionsDyn = [];
      this.showLine=true;
      const _this = this;
      _this.tableLoading = true;
      _this.dataTotal = 0;
      //
      // if(this.query.timeSelect[0].length<5){
      //   this.query.timeSelect[0]= this.query.timeSelect[0]+'-01-01 00:00:00'
      //   this.query.timeSelect[1]=this.query.timeSelect[1]+'-12-31 23:59:59'

      // }

        if(this.timeType=='year'){
          this.query.timeStart=this.query.timeStart+'-01-01 00:00:00'
          this.query.timeEnd=this.query.timeEnd+'-12-31 23:59:59'
        }else if(this.timeType=='month'){
          this.query.timeStart=this.query.timeStart+'-01 00:00:00'
          this.query.timeEnd=this.query.timeEnd+'-31 23:59:59'
        }else if(this.timeType=='date'){
          this.query.timeStart=this.query.timeStart+' 00:00:00'
          this.query.timeEnd=this.query.timeEnd+' 23:59:59'
        }



      const data={
        intfCode: this.query.intfCode,
        timeStart: this.query.timeStart,
        timeEnd:  this.query.timeEnd,
        seeParams: JSON.stringify(this.seeParams),
      }
      appIndexDataApiIntf(
        page.currentPage,
        page.pageSize,
        Object.assign(params,data)
      ).then((res) => {
        const data = res.data.data;
        _this.tableOptionsDyn.push({
          label:this.$t(`cip.index.app.category.time`),//'时间',
          prop:'_time'
        })
        _this.getTableColumnCell(data.indexInfo,isMode);
        _this.dataTotal = data.total;
        _this.page.total=data.total
        _this.tableData = data.records;

        _this.$nextTick(() => {
          _this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
          _this.tableLoading = false;
        });
      });
    },
    getTableColumnCell(data,isMode=false){
      this.columns.length = 0;
      const _this = this;
      if (data && data.length>0) {
       data.forEach((key,index) =>{

             if(isMode){
               this.tableOptionsDyn.push({
                 label: key[Object.keys(key)[0]],
                 prop:Object.keys(key)[0]+'_simulation'
               })
               // 搜索条件
               this.columns.push({
                 label: key[Object.keys(key)[0]],
                 value: Object.keys(key)[0]+'_simulation',
               })
             }else {
               this.tableOptionsDyn.push({
                 label: key[Object.keys(key)[0]],
                 prop:Object.keys(key)[0]
               })
               // 搜索条件
               this.columns.push({
                 label: key[Object.keys(key)[0]],
                 value:Object.keys(key)[0]
               })
             }

             //
             // this.tableOptionsDyn=this.tableOptionsDyn.filter(item=>{
             //   console.log(item)
             //   return (item.prop&&item.prop.indexOf('_simulation')!=-1)
             // })
        })
        this.showLine=false

      }
    },
    headShowLine() {
      let _this = this;
      _this.showLine = !_this.showLine;
      console.log("show:" + _this.showLine);
      if (_this.showLine) {
        _this.$nextTick(() => {
          _this.genLineChart(this.tableData, 0);
        });
      }
      _this.dataTotal = 10;
    },

  },
  assembleData2(data, property) {
    let arr = [];
    data.forEach((element) => {
      arr.push(element[property]);
    });
    return arr;
  },

}
</script>
