<template>
  <div class="center">
    <el-container>
      <wf-category @node-click="nodeClick"
                   @list-change="findObject(option.column, 'categoryId').dicData = $event"></wf-category>
      <el-main>
        <!--列表查询条件和头部按钮-->

        <head-layout :head-btn-options="headBtnOptions"
                     :head-title="$t('cip.plat.wfDesign.form.title.indexHeadTitle')"
                     @head-add="headAdd"
                     @head-change="handleChangeCategory"></head-layout>

        <grid-head-layout ref="gridHeadLayout"
                          :search-columns="searchColumns"
                          @grid-head-search="gridHeadSearch"
                          @grid-head-empty="gridHeadEmpty"></grid-head-layout>
        <!--列表label-->
        <grid-layout ref="gridLayOut"
                     :table-options="option"
                     :table-data="data"
                     :table-loading="loading"
                     :data-total="page.total"
                     :grid-row-btn="gridRowBtn"
                     :page="page"
                     @page-current-change="onLoad"
                     @page-size-change="onLoad"
                     @page-refresh-change="onLoad"
                     @row-edit="rowEdit"
                     @row-del="rowDel"
                     @row-design="handleDesign"
                     @row-copy="handleCopy"
                     @row-history="handleHistory"></grid-layout>
      </el-main>
    </el-container>
    <!--    <basic-container>
    <el-container>
      <el-aside width="200px">
        <wf-category @node-click="nodeClick"
                     @list-change="findObject(option.column, 'categoryId').dicData = $event"></wf-category>
      </el-aside>
      <el-main style="margin-left: 10px;">
        <avue-crud ref="crud"
                   :option="option"
                   :table-loading="loading"
                   :data="data"
                   :page.sync="page"
                   :search.sync="query"
                   :permission="permissionList"
                   v-model="form"
                   @row-update="rowUpdate"
                   @row-save="rowSave"
                   @row-del="rowDel"
                   @search-change="searchChange"
                   @search-reset="searchReset"
                   @selection-change="selectionChange"
                   @current-change="currentChange"
                   @size-change="sizeChange"
                   @refresh-change="onLoad(page, query)"
                   @on-load="onLoad">
          <template #menuLeft>
            <el-button type="success"
                       size="mini"
                       icon="el-icon-connection"
                       v-if="permission.wf_design_model_change_category"
                       @click="handleChangeCategory"> 更改分类
            </el-button>
          </template>
          <template slot="menu"
                    slot-scope="{row}">
            <el-button v-if="permission.wf_design_form_design"
                       type="text"
                       size="mini"
                       icon="el-icon-edit"
                       @click="handleDesign(row)">设计</el-button>
            <el-button v-if="permission.wf_design_form_copy"
                       type="text"
                       size="mini"
                       icon="el-icon-document-copy"
                       @click="handleCopy(row)">拷贝</el-button>
            <el-button v-if="permission.wf_design_form_history"
                       type="text"
                       size="mini"
                       icon="el-icon-time"
                       @click="handleHistory(row)">历史</el-button>
          </template>
        </avue-crud>
      </el-main>
    </el-container>


    <el-dialog :visible.sync="copyVisible"
               append-to-body
               title="拷贝表单">
      <avue-form :option="copyOption"
                 v-model="form"
                 ref="copyForm"
                 @submit="handleCopySubmit">
      </avue-form>
    </el-dialog>

    <el-dialog :visible.sync="categoryVisible"
               append-to-body
               title="选择分类">
      <avue-form v-model="form"
                 :option="{column:[{type:'tree',label:'流程分类',span:24,props:{label:'name',value:'id'},prop:'category',dicUrl:'/api/sinoma-workflow/design/category/tree',required:true,rules:[{required:true,message:'请选择分类'}]}]}"
                 @submit="handleChangeCategorySubmit"></avue-form>
    </el-dialog>
  </basic-container>-->
    <el-dialog :visible.sync="formVisible"
               append-to-body
               :title="title"
               fullscreen>
      <avue-form-design v-if="formVisible"
                        style="height: 88vh"
                        ref="formDesign"
                        :toolbar="['clear', 'preview', 'import', 'generate']"
                        :includeFields="['group', 'dynamic', 'input', 'textarea', 'number', 'map', 'radio','checkbox','select','tree','cascader', 'date','time','datetime','daterange','datetimerange','timerange','switch','rate','color','icon','slider']"
                        :customFields="customFields"
                        :default-values="defaultValues"
                        :options="options">
        <template #toolbar>
          <el-button type="text"
                     size="medium"
                     icon="el-icon-download"
                     @click="handleSubmit">{{ $t(`cip.cmn.btn.saveBtn`) }}</el-button>
        </template>
      </avue-form-design>
    </el-dialog>
    <el-dialog :visible.sync="copyVisible"
               append-to-body
               :title="$t('cip.plat.wfDesign.form.title.copyHeadTitle')">
      <avue-form :option="copyOption"
                 v-model="form"
                 ref="copyForm"
                 @submit="handleCopySubmit">
      </avue-form>
    </el-dialog>

    <el-dialog :visible.sync="categoryVisible"
               append-to-body
               :title="$t('cip.plat.wfDesign.form.title.arrangeHeadTitle')">
      <avue-form v-model="form"
                   :option="{
                     column:
                       [{
                         type: 'tree',
                         label: this.$t(`cip.plat.wfDesign.form.field.categoryId`),
                         span: 24,
                         props: { label: 'name', value: 'id' },
                         prop: 'category',
                         dicUrl: '/api/sinoma-workflow/design/category/tree',
                         required: true,
                         rules: [
                           {
                             required: true,
                             message: this.$t(`cip.cmn.rule.selectWarning`)+this.$t(`cip.plat.wfDesign.form.field.categoryId`),
                           }
                         ]
                       }]
                   }"
                 @submit="handleChangeCategorySubmit"></avue-form>
    </el-dialog>
  </div>
</template>

<script>
import { getList, add, update, remove, listType, changeCategory } from "@/api/plugin/workflow/form";

import { mapGetters } from "vuex";

import customFields from '../mixins/custom-fields'
import WfCategory from '../process/components/category.vue'
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

export default {
  components: { WfCategory, GridLayout, HeadLayout },
  mixins: [customFields],
  data () {
    return {
      formVisible: false,
      options: {},
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,

      },
      selectionList: [],
      option: {
        menuWidth:300,
        column: [
          {
            label: this.$t('cip.plat.wfDesign.form.field.formKey'),
            prop: "formKey",
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.form.field.formKey`),
              trigger: "blur"
            }],
            search: true,
            width: 200,
            overHidden: true,
            align: "left",
          },
          {
            label: this.$t('cip.plat.wfDesign.form.field.name'),
            prop: "name",
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.form.field.name`),
              trigger: "blur"
            }],
            search: true,
            width: 200,
            overHidden: true,
            align: "left",
          },
          {
            label: this.$t('cip.plat.wfDesign.form.field.categoryId'),
            prop: 'categoryId',
            type: 'tree',
            width: 120,
            overHidden: true,
            align: "center",
            props: {
              label: 'name',
              value: 'id'
            },
            dicData: [],
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.selectWarning`)+this.$t(`cip.plat.wfDesign.form.field.categoryId`),
              trigger: "change"
            }],
          },
          {
            label: this.$t('cip.plat.wfDesign.form.field.version'),
            prop: 'version',
            align:'right',
            display: false,
            width: 80,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.wfDesign.form.field.status'),
            prop: 'status',
            type: 'select',
            align:'center',
            width: 100,
            overHidden: true,
            value: 1,
            // dicData: [{
            //   label: this.$t('cip.plat.wfDesign.form.field.available'),
            //   value: 1
            // }, {
            //   label: this.$t('cip.plat.wfDesign.form.field.forbidden'),
            //   value: 2
            // }],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_available",
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.selectWarning`)+this.$t(`cip.plat.wfDesign.form.field.status`),
              trigger: "change"
            }],
            search: true
          },
          {
            label: this.$t('cip.plat.wfDesign.form.field.remark'),
            prop: 'remark',
            type: 'textarea',
            align:'left',
            overHidden: true,
            span: 24
          },
        ]
      },
      data: [],
      defaultValues: {},
      copyOption: {
        column: [{
          label: this.$t('cip.plat.wfDesign.form.field.formKey'),
          prop: "formKey",
          rules: [{
            required: true,
            message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.form.field.formKey`),
            trigger: "blur"
          }],
        },
        {
          label: this.$t('cip.plat.wfDesign.form.field.name'),
          prop: "name",
          rules: [{
            required: true,
            message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.form.field.name`),
            trigger: "blur"
          }],
        },
        {
          label: this.$t('cip.plat.wfDesign.form.field.categoryId'),
          prop: 'categoryId',
          type: 'tree',
          props: {
            label: 'name',
            value: 'id'
          },
          dicData: [],
          rules: [{
            required: true,
            message: this.$t(`cip.cmn.rule.selectWarning`)+this.$t(`cip.plat.wfDesign.form.field.categoryId`),
            trigger: "change"
          }],
        },
        {
          label: this.$t('cip.plat.wfDesign.form.field.status'),
          prop: 'status',
          type: 'select',
          value: 1,
          // dicData: [{
          //   label: this.$t('cip.plat.wfDesign.form.field.availabl'),
          //   value: 1
          // }, {
          //   label: this.$t('cip.plat.wfDesign.form.field.forbidden'),
          //   value: 2
          // }],
          dataType: 'number',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_available",
          rules: [{
            required: true,
            message: this.$t(`cip.cmn.rule.selectWarning`)+this.$t(`cip.plat.wfDesign.form.field.status`),
            trigger: "change"
          }],
          search: true
        },
        {
          label: this.$t('cip.plat.wfDesign.form.field.remark'),
          prop: 'remark',
          type: 'textarea',
          span: 24
        },]
      },
      isNewVersion: false,
      isCopy: false,
      copyVisible: false,
      categoryVisible: false,
      title: '表单设计'
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.wf_design_form_add, false),
        viewBtn: this.vaildData(this.permission.wf_design_form_view, false),
        delBtn: this.vaildData(this.permission.wf_design_form_delete, false),
        editBtn: this.vaildData(this.permission.wf_design_form_edit, false)
      };
    },
    ids () {
      let ids = [];
      let selectionList = this.$refs.gridLayOut.selectionList
      selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns () {
      return [
        {
          label: this.$t('cip.plat.wfDesign.form.field.formKey'),
          prop: "formKey",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.form.field.formKey`),
        },
        {
          label: this.$t('cip.plat.wfDesign.form.field.name'),
          prop: "name",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.form.field.name`),
        },
      ]
    },
    headBtnOptions () {
      let result = [];
      //新增按钮
      if (this.permission.wf_design_form_add) {
        result.push({
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      //删除按钮 add by steve
      //更改分类
      if (this.permission.wf_design_form_change_category) {
        result.push({
          label: this.$t(`cip.plat.wfDesign.model.btn.changeTypeBtn`),
          emit: "head-category",
          type: "danger",
          icon: "el-icon-delete",
        });
      }
      return result;
    },
    // 行按钮添加 add by steve
    gridRowBtn () {
      let result = [];
      //新增按钮
      if (this.permission.wf_design_form_edit) {
        result.push({
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.wf_design_form_delete) {
        result.push({
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-del",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.wf_design_form_design) {
        result.push({
          label: this.$t(`cip.plat.wfDesign.form.btn.designBtn`),
          emit: "row-design",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.wf_design_form_copy) {
        result.push({
          label: this.$t(`cip.plat.wfDesign.form.btn.copyBtn`),
          emit: "row-copy",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.wf_design_form_history) {
        result.push({
          label: this.$t(`cip.plat.wfDesign.form.btn.historysBtn`),
          emit: "row-history",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      //新增按钮
      return result
    },
  },
  mounted () {
    this.getDefaultValues()
    this.onLoad(this.page, {});
  },
  methods: {
    // 列表查询 add by steve
    gridHeadSearch (searchForm) {
      this.onLoad(this.page, searchForm);
    },
    //列表的新增
    headAdd(){
      this.$router.push({
        path: '/workflow/design/formEdit',
        query: {
          type: 'add',
        }
      });
    },
    // 查询重置 add by steve
    gridHeadEmpty (searchForm) {
      this.onLoad(this.page, searchForm);
    },
    handleChangeCategorySubmit (form, done) {
      const { category } = form
      changeCategory({ ids: this.ids, category }).then(() => {
        this.$message.success(this.$t(`cip.cmn.msg.success.operateSuccess`))
        done()
        this.categoryVisible = false
        this.onLoad(this.page, this.query)
      })
    },
    handleChangeCategory () {
      if (this.$refs.gridLayOut.selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.categoryVisible = true
    },
    nodeClick ({ id }) {
      this.categoryId = id
      this.searchChange(this.query)
    },
    handleSubmit () {
      this.$refs.formDesign.getData('string').then(data => {
        this.$refs.formDesign.getData('app').then(appData => {
          if (this.isCopy) {
            this.copyVisible = true
            this.form.content = data
            this.form.appContent = JSON.stringify(appData)
          } else {
            this.row.content = data
            this.row.appContent = JSON.stringify(appData)

            if (this.isNewVersion) {
              this.row.newVersion = false

              update(this.row).then(() => {
                this.$message.success(this.$t(`cip.cmn.msg.success.operateSuccess`))
                this.onLoad(this.page, this.query)
                this.formVisible = false
              })
            } else {
              this.$confirm(this.$t(`cip.plat.wfDesign.form.msg.save`), {
                distinguishCancelAndClose: true,
                confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
                cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
                type: 'warning'
              }).then(() => {
                this.row.newVersion = false

                update(this.row).then(() => {
                  this.$message.success(this.$t(`cip.cmn.msg.success.operateSuccess`))
                  this.onLoad(this.page, this.query)
                  this.formVisible = false
                })
              }).catch(action => {
                if (action == 'cancel') {
                  this.row.newVersion = true

                  update(this.row).then(() => {
                    this.$message.success(this.$t(`cip.cmn.msg.success.operateSuccess`))
                    this.onLoad(this.page, this.query)
                    this.formVisible = false
                  })
                }
              })
            }
          }
        })
      })
    },
    handleDesign (row) {
      this.options = this.deepClone(row.content || '{column: []}')
      if (row.content) this.isNewVersion = false
      else this.isNewVersion = true
      this.row = row
      this.isCopy = false
      this.formVisible = true
      this.title = this.$t('cip.plat.wfDesign.form.title.indexHeadTitle')+` - ${row.name}`
    },
    handleCopy (row) {
      this.options = this.deepClone(row.content || '{column: []}')
      this.isCopy = true
      this.formVisible = true
    },
    handleCopySubmit (form, done) {
      const param = {
        ...form,
        content: this.form.content
      }
      add(param).then(() => {
        this.onLoad(this.page);
        this.$message({
          type: "success",
          message: this.$t(`cip.cmn.msg.success.operateSuccess`),
        });
        this.$refs.copyForm.resetFields()
        done()
        this.copyVisible = false
        this.formVisible = false
      }).catch(() => {
        done()
      })
    },
    handleHistory (row) {
      this.$router.push('/workflow/design/form/history/' + row.id)
    },
    getDefaultValues () {
      listType().then(res => {
        this.defaultValues = res.data.data
      })
    },
    rowEdit (row) {
      let data = encodeURIComponent(JSON.stringify(row))
      this.$router.push({
        path: '/workflow/design/formEdit',
        query: {
          type: 'edit',
          data: data
        }
      })
    },
    rowDel (row) {
      this.$confirm(this.$t(`cip.plat.wfDesign.form.msg.remove`), {
        distinguishCancelAndClose: true,
        confirmButtonText: this.$t(`cip.plat.wfDesign.form.btn.returnBtn`),
        cancelButtonText: this.$t(`cip.plat.wfDesign.form.btn.allRemoveBtn`),
        type: "warning"
      }).then(() => {
        const param = {
          id: row.id,
          rollback: true
        }
        remove(param).then(() => {
          this.onLoad(this.page);
          this.$message.success(this.$t(`cip.cmn.msg.success.operateSuccess`))
        })
      }).catch(action => {
        if (action == 'cancel') {
          const param = {
            id: row.id,
            rollback: false
          }
          remove(param).then(() => {
            this.onLoad(this.page);
            this.$message.success(this.$t(`cip.cmn.msg.success.operateSuccess`))
          })
        }
      })
    },
    handleDelete () {
      let selectionList = this.$refs.gridLayOut.selectionList
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t(`cip.cmn.msg.success.operateSuccess`),
          });
        });
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.onLoad(this.page, params);
      if (done && typeof done == 'function') done()
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    onLoad (page, params = {}) {
      this.page = page;
      this.loading = true;

      if (this.categoryId) params['categoryId_equal'] = this.categoryId
      else delete params['categoryId_equal']

      getList(page.currentPage, page.pageSize, Object.assign(params, this.$refs.gridHeadLayout.searchForm)).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.data = data.records;
        this.loading = false;
      });
    },
    mounted() {
      this.onLoad(this.page);
    },
  }

};
</script>

<style scoped lang="scss">
::v-deep #pane-widget {
  background-color: #FFFFFF;
}
::v-deep #pane-form {
  background-color: #FFFFFF;
}
</style>
