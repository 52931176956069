<template>
  <div>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>

    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page" @page-size-change="onLoad" @page-current-change="onLoad"
      @page-refresh-change="onLoad"
      :grid-row-btn="gridRowBtn"
      @row-view="rowView"
      @grid-row-detail-click="rowView"
    >
      <template #customBtn="{row}">
        <el-button
          @click="msgResend(row)"
          size="small"
          type="text"
          v-if="row.status === 2"
        >{{ $t('cip.plat.message.sendRecord.sendRecord.btn.resend') }}
        </el-button>
      </template>
    </grid-layout>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {detailselectPage, saveOrUpdate, remove, startOrStop, reSend} from "@/api/message/sendInfo";


export default {
  name: "messageDetai",
  components: {
    GridLayout,
    HeadLayout,
  },
  props: {
    headForm: {
      type: Object,
      default: {},
    },
    type: {
      type: String,
      default: 'view',
    }
  },
  data() {
    return {
      // table的加载
      tableLoading: true,
      // table的数据
      tableData: [],
      id:'',
      // table的数据条数
      dataTotal: 0,
      // TODO 点击行表的时候给获取行绑定的数据
      form: {},
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };

  },
  computed: {

// 搜索栏
    searchColumns() {
      return [
        {
          label: "",
          type: "select",
          // dicData: [
          //   {
          //     label: this.$t("cip.plat.message.sendRecord.sendRecord.field.success"),
          //     value: 1
          //   },
          //   {
          //     label: this.$t("cip.plat.message.sendRecord.sendRecord.field.fail"),
          //     value: 2
          //   }
          // ],
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=success_fail",
          placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.message.sendRecord.field.sendStatus"),
          prop: "sendStatus",
        },
      ];
    },
    // 表格配置
    tableOptions() {
      return {
        index: true,
        indexLabel: '序号',
        linklabel: "detailCode",
        column: [
          {
            label: this.$t("cip.plat.message.sendRecord.sendRecord.field.detailCode"),
            prop: "detailCode",
            align: "center",
            search: true,
            width: 300
          },
          {
            label: this.$t("cip.plat.message.sendRecord.sendRecord.field.channelName"),
            prop: "channelName",
            align: "center",
            search: true
          },
          {
            label: this.$t("cip.plat.message.sendRecord.sendRecord.field.sendAccount"),
            prop: "sendAccount",
            align: "center",
            search: true
          },
          {
            label: this.$t("cip.plat.message.sendRecord.sendRecord.field.receiveAccount"),
            prop: "receiveAccount",
            align: "center",
            search: true
          },
          {
            label: this.$t("cip.plat.message.sendRecord.sendRecord.field.msgTile"),
            prop: "msgTile",
            align: "center",
            search: true
          },
          {
            label: this.$t("cip.plat.message.sendRecord.sendRecord.field.msgContent"),
            prop: "msgContent",
            align: "center",
            search: true,
            overHidden: true
          },
          {
            label: this.$t("cip.plat.message.sendRecord.field.sendStatus"),
            minWidth : 30,
            prop: "sendStatus",
            align: "center",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=send_status`,
            props: {
              label: "dictValue",
              value: "dictKey"
            }
          },
        ]
      };
    },

    // table的menu按钮
    gridRowBtn() {
      let result = [];

      result.push({
        label: this.$t(`cip.cmn.btn.viewBtn`),
        emit: "row-view",
        type: "button",
        icon: ""
      })
      return result;
    },
  },
  watch: {
    headForm: {
      deep: true,
      immediate: true,
      handler(val){
        this.$emit("update:headForm", val)
      }
    },
    'headForm.itemCode'() {
      if(this.headForm.itemCode != null && this.type !== 'add'){
        this.onLoad(this.page)
      }
    },
  },
  mounted() {
    if (!this.headForm || !this.headForm.id) {
      this.tableLoading = false;
      return
    }
    this.id = this.headForm.id;
    this.onLoad(this.page, {});
    this.getHeight();
  },
  methods: {
    rowView(row,index) {
      this.$router.push({
        path: '/message/sendInfo/common/detailInfo',
        query: {
          type: 'view',
          id: row.id
        }
      })
    },
    getHeight() {
      let height = document.body.clientHeight - 500;
      this.tableOptions.height = height;
      this.$refs.gridLayOut.option.height = height;
    },
    gridHeadSearch(searchForm) {
      this.onLoad({pageSize: 20, currentPage: 1}, searchForm);
    },
    gridHeadEmpty(searchForm) {
      this.onLoad({pageSize: 20, currentPage: 1}, searchForm);
    },
    msgResend(row){

      reSend(row.id).then(res => {
        const msg = res.data.msg;
        const code = res.data.code;
        if(code == 200){
          this.$message({
            type: "success",
            message: msg
          });
          this.onLoad(this.page, {});
        }else{
          this.$message({
            type: "fail",
            message: msg
          });
        }
      });
    },
    onLoad(page, params = {}) {
      this.page = page;
      params.msgId = this.id;
      this.tableLoading = true;
      detailselectPage(page.currentPage, page.pageSize, Object.assign(this.$refs.gridHeadLayout.searchForm,params)).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableData = data.records;
        this.$nextTick(() =>{
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        })
        this.tableLoading = false;
      });
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .avue-crud .el-table {
  height: calc(100vh - 530px) !important;
  max-height: calc(100vh - 530px) !important;
}
</style>
