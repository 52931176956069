<template>
  <div>
    <el-container>
      <CommonTree
        :treeData="treeData"
        :defaultProps="treeProps"
        :searchTitle="searchTitle"
        :isShowdig="false"
        :showCheckbox="false"
        @getNodeClick="getNodeClick"
      />

      <el-container>
        <el-main>

<!--          <head-layout :head-btn-options="headBtnOptions" :head-title="$t('cip.plat.message.sendRecord.title.indexHeadTitle')"
                       @head-send="sendMessage">
          </head-layout>-->
          <div class="headers">
            <grid-head-layout ref="searchFrom" :search-columns="searchColumns" v-model="searchForm"
                              @grid-head-search="searchChange" @grid-head-empty="searchReset">
            </grid-head-layout>
          </div>

          <grid-layout ref="gridLayout" :data-total="page.total" :page="page" @page-size-change="onLoad" @page-current-change="onLoad"
                       @page-refresh-change="onLoad" @row-details="rowDetail" @gird-handle-select-click="selectionChange" :tableOptions="tableOptions" @grid-row-detail-click="rowDetail"
                       :tableData="tableData" :tableLoading="tableLoading" :searchColumns="searchColumns" :gridRowBtn="gridRowBtn">
          </grid-layout>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import {selectPage, getMsgTypeTree} from "@/api/message/sendInfo";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import ServerNameEnum from "@/util/ServerNameEnum";
import CommonTree from "@/views/components/com_tree/index"
import {mapGetters} from "vuex";

export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
  },
  data() {
    return {
      searchForm: {},
      searchTitle: 'msgTypeName',
      activeName: 'deviceNet',
      searchColumns: [
        {
          label: "",
          type: "select",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=message_form",
          span: 3,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.message.sendRecord.field.msgForm"),
          prop: "msgForm",
        },
        {
          label: "",
          type:"input",
          prop: "templateCode",
          span: 3,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.message.sendRecord.field.templateCode")
        },
        {
          label: "",
          prop: "templateName",
          type:"input",
          span: 3,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.message.sendRecord.field.templateNames")
        },
        {
          label: "",
          type: "select",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=send_status",
          span: 3,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.message.sendRecord.field.sendStatus"),
          prop: "sendStatus",
        },
        {
          label: "",
          prop: "startDate",
          type: 'date-format',
          span: 3,
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd',
          placeholder: this.$t(`cip.plat.message.sendRecord.field.startDate`)
        },
        {
          label: "",
          prop: "endDate",
          type: 'date-format',
          span: 3,
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd',
          placeholder: this.$t(`cip.plat.message.sendRecord.field.endDate`),
        },
      ],
      dataTotal: 0,
      tableData: [],
      tableLoading: true,
      form: {},
      search: {},
      selectionList: [],
      query: {
        msgType: ''
      },
      loading: true,
      platformLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      treeProps: {
        label: "msgTypeName",
        value: "id"
      },
      treeData: [],
      msgType: '',
      treeOptions: {
        defaultExpandAll: true,
        nodeKey: 'id',
        lazy: true,
        treeLoad: function (node, resolve) {
          const parentId = (node.level === 0) ? 0 : node.data.id;
          getMsgTypeTree(parentId).then(res => {
            resolve(res.data.data.map(item => {
              return {
                ...item,
                leaf: !item.hasChildren
              }
            }))
          });
        },
        menu: false,
        size: 'mini',
        props: {
          labelText: this.$t("cip.plat.sys.user.field.title"),
          label: 'msgTypeName',
          value: 'value',
          children: 'children'
        },
        formOption: {
          labelWidth: 100,
          column: [{
            label: this.$t("cip.plat.sys.user.field.customize"),
            prop: 'test'
          }],
        },
      },
      tableOptions: {
       // customRowAddBtn: true,
        linklabel: "msgCode",
        menuWidth: 80,
        column: [
          {
            label: this.$t("cip.plat.message.sendRecord.field.msgCode"),
            prop: "msgCode",
            search: true
          },
          {
            label: this.$t("cip.plat.message.sendRecord.field.msgForm"),
            labelWidth: 130,
            width: 80,
            prop: "msgForm",
            align: "center",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=message_form`
          },
          {
            label: this.$t("cip.plat.message.sendRecord.field.msgType"),
            labelWidth: 130,
            width: 80,
            prop: "msgType",
            align: "center",
            props: {
              label: 'msgTypeName',
              value: 'msgTypeCode',
              key: 'parentId',
            },
            dicUrl: `${ServerNameEnum.SERVER_MESSAGE_}/msgTypeItem/selectTree`
          },
          {
            label: this.$t("cip.plat.message.sendRecord.field.templateCode"),
            prop: "templateCode",
            align: "center",
            search: true
          },
          {
            label: this.$t("cip.plat.message.sendRecord.field.templateNames"),
            prop: "templateName",
            minWidth: 100,
            align: "center",
            search: true
          },
          {
            label: this.$t("cip.plat.message.sendRecord.field.sendModel"),
            minWidth: 50,
            prop: "sendModel",
            align: "center",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=send_model`
          },
          {
            label: this.$t("cip.plat.message.sendRecord.field.sendNumCensus"),
            prop: "sendNumCensus",
            width: 80,
            align: "center",
            search: true
          },
          {
            label: this.$t("cip.plat.message.sendRecord.field.sendStatus"),
            width: 80,
            prop: "sendStatus",
            align: "center",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=send_status`,
            props: {
              label: "dictValue",
              value: "dictKey"
            }
          },
          {
            label: this.$t('cip.plat.message.sendRecord.field.sendTime'),
            width: 110,
            prop: 'sendTime',
            align: "center",
          }
        ]
      },
      data: []
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      if (this.permission.sendInfo_add) {
        result.push(      {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-send",
          type: "button",
          icon: "",
          btnOptType: 'add',
        });
      }
      return result
    },
    gridRowBtn() {
      let result = [];
      //查看按钮
      if (this.permission.sendInfo_view) {
        result.push({
          label: this.$t(`cip.cmn.btn.viewBtn`),
          emit: "row-details",
          type: "button",
          icon: "",
          remark: 'sendInfo_view',
        });
      }
      return result;
    },
  },
  created() {
  },
  mounted() {
    this.initData();
    this.getTreeData();
  },
  methods: {
    initData(){
      //加载右侧表格数据
      this.onLoad(this.page, {});
    },
    getTreeData() {
      getMsgTypeTree().then((res) => {
        this.treeData = res.data.data
      })
    },
    getNodeClick(data){
      this.msgType = data.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    sendMessage(){
      this.$router.push({
        path: '/message/sendInfo/add',
        query: {
          type: 'add'
        }
      })
    },
    rowDetail(row, index) {
      this.$router.push({
        path: '/message/sendInfo/details',
        query: {
          data: row,
          type: '',
          id: row.id,
          templateId: row.templateId,
        }
      })
    },
    searchReset(params) {
      this.query = params;
      this.treeCode = '';
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    searchChange(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      this.query.msgType = this.msgType;
      selectPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayout.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
