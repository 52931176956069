<template>
  <el-dialog ref="wf-dialog"
             custom-class="wf-dialog"
             :visible.sync="visible"
             :title="$t('cip.plat.workflow.components.userOption.title.indexHeadTitle')"
             width="60%"
             :before-close="handleClose"
             append-to-body>
    <el-table v-if="visible"
              class="avue-crud"
              :data="data"
              border
              size="mini">
      <el-table-column align="center"
                       header-align="center"
                       width="80">
        <template #header>
          <el-button circle
                     type="primary"
                     size="mini"
                     icon="el-icon-plus"
                     @click="data.push({})"></el-button>
        </template>
        <template #default="{$index}">
          <el-button circle
                     type="danger"
                     size="mini"
                     icon="el-icon-delete"
                     @click="data.splice($index, 1)"></el-button>
        </template>
      </el-table-column>
      <el-table-column :label="$t('cip.plat.workflow.components.userOption.field.type')"
                       prop="type"
                       align="center"
                       header-align="center">
        <template #default="{row, $index}">
          <el-select v-model="row.type"
                     size="mini"
                     :placeholder="$t('cip.plat.workflow.components.userOption.field.type')"
                     @change="handleTypeChange($index)">
            <el-option v-for="item in typeList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"
                       :disabled="Boolean(data.find(d => d.type == item.value))"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column :label="$t('cip.plat.workflow.components.userOption.field.value')"
                       prop="text"
                       align="center"
                       header-align="center">
        <template #default="{row, $index}">
          <template v-if="row.type == 'user'">
            <el-input v-model="row.text"
                      size="mini"
                      :placeholder="$t('cip.plat.workflow.components.userOption.field.user')"
                      readonly
                      @click.native="handleSelect($index, 'user-select')">
              <template #append>
                <el-button icon="el-icon-plus"></el-button>
              </template>
            </el-input>
          </template>
          <template v-else-if="row.type == 'role'">
            <avue-input-tree :ref="`role_${$index}`"
                             v-model="row.value"
                             size="mini"
                             dataType="string"
                             multiple
                             clearable
                             :placeholder="$t('cip.plat.workflow.components.userOption.field.role')"
                             :dic="roleList"
                             :props="roleProps || {label: 'roleName', value: 'id'}"
                             @change="handleChange($index, `role_${$index}`)">
            </avue-input-tree>
          </template>
          <template v-else-if="row.type == 'dept'">
            <avue-input-tree :ref="`dept_${$index}`"
                             v-model="row.value"
                             size="mini"
                             dataType="string"
                             multiple
                             clearable
                             :placeholder="$t('cip.plat.workflow.components.userOption.field.dept')"
                             :dic="deptList"
                             :props="deptProps || {label: 'deptName', value: 'id'}"
                             @change="handleChange($index, `dept_${$index}`)">
            </avue-input-tree>
          </template>
          <template v-else-if="row.type == 'post'">
            <avue-input-tree :ref="`post_${$index}`"
                             v-model="row.value"
                             size="mini"
                             dataType="string"
                             multiple
                             clearable
                             :placeholder="$t('cip.plat.workflow.components.userOption.field.post')"
                             :dic="postList"
                             :props="postProps || {label: 'postName', value: 'id'}"
                             @change="handleChange($index, `post_${$index}`)">
            </avue-input-tree>
          </template>
          <template v-else-if="row.type == 'platform'">
            <avue-input-tree :ref="`platform_${$index}`"
                             v-model="row.value"
                             size="mini"
                             dataType="string"
                             multiple
                             clearable
                             :placeholder="$t('cip.plat.workflow.components.userOption.field.platform')"
                             :dic="platformList"
                             @change="handleChange($index, `platform_${$index}`)">
            </avue-input-tree>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <el-button @click="handleClose"
                 size="mini">{{$t('cip.cmn.btn.celBtn')}}</el-button>
      <el-button type="primary"
                 @click="handleSubmit"
                 size="mini">{{$t('cip.cmn.btn.defBtn')}}</el-button>
    </template>

    <user-select ref="user-select"
                 check-type="checkbox"
                 :user-url="userUrl"
                 :custom-option="customOption"
                 :default-checked="defaultChecked"
                 @onConfirm="handleSelectConfirm"></user-select>
  </el-dialog>
</template>
<script>
import UserSelect from './user-select.vue'

export default {
  props: {
    userOption: Object
  },
  components: {
    UserSelect,
  },
  computed: {
    userUrl() {
      return this.userOption.userUrl
    },
    roleUrl() {
      return this.userOption.roleUrl
    },
    deptUrl() {
      return this.userOption.deptUrl
    },
    postUrl() {
      return this.userOption.postUrl
    },
    customUrl() {
      return this.userOption.customUrl
    },
    customOption() {
      return this.userOption.customOption
    },
    roleProps() {
      return this.customOption ? this.customOption.roleProps : null
    },
    deptProps() {
      return this.customOption ? this.customOption.deptProps : null
    },
    postProps() {
      return this.customOption ? this.customOption.postProps : null
    }
  },
  watch: {
    visible(val) {
      if (!this.init) {
        this.getRoleList()
        this.getDeptList()
        this.getPostList()
        this.init = true
      }
      if (val && this.userOption && this.userOption.data) this.$set(this, 'data', JSON.parse(JSON.stringify(this.userOption.data)))
    },
  },
  data() {
    return {
      init: false,
      visible: false,
      data: [],
      roleList: [],
      deptList: [],
      postList: [],
      selectIndex: 0,
      defaultChecked: '',
      typeList: [{
        label: this.$t('cip.plat.workflow.components.userOption.field.user'),
        value: 'user'
      }, {
        label: this.$t('cip.plat.workflow.components.userOption.field.role'),
        value: 'role'
      }, {
        label: this.$t('cip.plat.workflow.components.userOption.field.dept'),
        value: 'dept'
      }, {
        label: this.$t('cip.plat.workflow.components.userOption.field.post'),
        value: 'post'
      }, 
      // {
      //   label: this.$t('cip.plat.workflow.components.userOption.field.platform'),
      //   value: 'platform'
      // }
    ],
      typeDic: {
        user: this.$t('cip.plat.workflow.components.userOption.field.user'),
        role: this.$t('cip.plat.workflow.components.userOption.field.role'),
        dept: this.$t('cip.plat.workflow.components.userOption.field.dept'),
        post: this.$t('cip.plat.workflow.components.userOption.field.post'),
        // platform: this.$t('cip.plat.workflow.components.userOption.field.platform'),
      },
      // platformList: [
      //   // {
      //   //   label: 'PC',
      //   //   value: 'pc'
      //   // },
      //   {
      //     label: this.$t('cip.plat.workflow.components.userOption.field.h5'),
      //     value: 'h5'
      //   }, {
      //     label: this.$t('cip.plat.workflow.components.userOption.field.mpWX'),
      //     value: 'mp-wx'
      //   }, {
      //     label: 'IOS',
      //     value: 'ios'
      //   }, {
      //     label: this.$t('cip.plat.workflow.components.userOption.field.android'),
      //     value: 'android'
      //   }
      // ]
    }
  },
  methods: {
    handleSelect(index, ref) {
      this.selectIndex = index
      this.defaultChecked = this.data[index].value
      this.$refs[ref].visible = true
    },
    handleSelectConfirm(id, name) {
      this.$set(this.data[this.selectIndex], 'value', id)
      this.$set(this.data[this.selectIndex], 'text', name)
    },
    handleTypeChange(index) {
      this.$set(this.data, index, {
        type: this.data[index].type
      })
    },
    handleChange(index, ref) {
      this.$nextTick(() => {
        const text = this.$refs[ref].labelShow.join(',')
        if (text) this.$set(this.data[index], 'text', text)
      })
    },
    getRoleList() {
      this.$axios.get(this.roleUrl).then(res => {
        this.roleList = res.data.data
      })
    },
    getDeptList() {
      this.$axios.get(this.deptUrl).then(res => {
        this.deptList = res.data.data
      })
    },
    getPostList() {
      this.$axios.get(this.postUrl, { current: 1, size: 999 }).then(res => {
        this.postList = res.data.data.records
      })
    },
    handleSubmit() {
      this.$emit('submit', this.data.filter(d => d.type && d.value))
      this.visible = false
    },
    handleClose(done) {
      this.visible = false
      if (done && typeof done == 'function') done()
    }
  }
}
</script>
<style lang="scss">
.wf-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);

  .el-dialog__body {
    flex: 1;
    overflow: auto;
  }

  .el-select {
    width: 100%;
  }
}
</style>