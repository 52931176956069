<template>
  <avue-tree :option="option"
             :data="data"
             @node-click="handleNodeClick"
             style="border-right: 1px solid #cccccc;background-color: #ffffff;width: 280px;"></avue-tree>
</template>

<script>
import {tree} from '@/api/plugin/workflow/category';

export default {
  name: 'wf-category',
  data() {
    return {
      data: [],
      option: {
        size: 'mini',
        filterText: this.$t('cip.cmn.msg.warning.filterateWarning'),
        defaultExpandAll: true,
        menu: false,
        addBtn: false,
        props: {
          label: 'name',
          value: 'id'
        }
      }
    }
  },
  mounted() {
    this.getCategoryList()
  },
  methods: {
    handleNodeClick({id}) {
      this.$emit('node-click', {id})
    },
    getCategoryList() {
      tree().then(res => {
        const data = res.data.data
        this.$emit('list-change', this.deepClone(data))
        let dataTree = [{
          id: '',
          name: '全部',
          children: data
        }]
        this.data = dataTree
      })
    },
  }
}
</script>
