<template>
  <div>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      :grid-head-btn="gridHeadBtn"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
      @head-preview="headPreview()"
    ></grid-head-layout>
    <div class="scopeWrap">
      <div v-for="(item,index) in scopeTable" :key="item" class="scopeForm">
        <el-row>
          <el-col :span="23">
            <formItem :data-form="item" :index="index"  @itemForm="itemForm" :themeId="themeId"></formItem>
          </el-col>
          <el-col :span="1">
            <div class="deleteForm" @click="deleteForm(item,index)">X</div>
          </el-col>
        </el-row>
      </div>
<!--      <div class="addWrap" @click="addForm">-->
<!--        + 新增-->
<!--      </div>-->
<!--      <div>-->
<!--        <form-layout-->
<!--          ref="formLayout"-->
<!--          :column="formColumn"-->
<!--          :dataForm.sync="dataForm"-->
<!--        ></form-layout>-->
<!--      </div>-->
    </div>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import formItem from "./formItem.vue"
import FormLayout from "@/views/components/layout/form-layout";
import {
  checkSqlById,getDimensionByCuboid
} from "@/api/index/indexManage";
export default {
  name: "measure",
  props:{
    themeId: {
      default: undefined
    },
    indexId: {
      default: undefined
    },
    headForm: {
      default: {}
    },
    scopeTable: {
      type: Array
    }
  },
  components: {
    GridLayout,
    HeadLayout,
    formItem,
    FormLayout
  },
  data() {
    return {
      tableLoading: true,
      tableData: [],
      dataTotal: null,
      teamForm: {},
      cuboidId:'',
      searchExpression: '',
      searchAlias: '',
      themeDimensionDict:[],
      query: {
        expression: '',
        alias: ''
      },
      dataForm:{
        textarea:''
      }
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    formColumn() {
      return [
        {
          label:'SQl脚本',
          labelWidth: 130,
          type: "textarea",
          overHidden: "15",
          prop: "textarea",
          readonly: true,
          rows:10,
          span: 24,
          showWordLimit: true,
        //  placeholder: this.$t(`sinoma.md.item.code`),
        //   rules: [{
        //     required: true,
        //     message: this.$t('cip.cmn.rule.inputWarning') + this.$t('sinoma.md.item.code'),
        //     trigger: "blur"
        //   }],
        }]
    },
    searchColumns() {
      return [
        // {
        //   prop: "expression",
        //   span: 6,
        //   placeholder: this.$t("cip.index.measure.expression"),
        // },
        // {
        //   prop: "alias",
        //   span: 6,
        //   placeholder: this.$t("cip.index.measure.alias"),
        // },
      ]
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: '序号',
        editBtn:false,   // 行编辑按钮
        delBtn:false,
        cellBtn:true,
        menuWidth: 250,
        height: 0,
        customAddBtn: true,
        column: [
          {
            label: this.$t('cip.index.scope.connectedRelation'),
            prop: 'connectedRelation',
            cell: true,
            type: 'select',
            align: 'center',
            filterable: true,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=connected_relation",
            rules: [
              {
                required: true,
                message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.index.scope.connectedRelation'),
                trigger: 'change'
              }
            ]
          },
          {
            label: this.$t('cip.index.scope.dimension'),
            prop: 'dimension',
            overHidden: true,
            cell: true,
          },
          {
            label: this.$t('cip.index.scope.tableName'),
            prop: 'tableName',
            overHidden: true,
            cell: true,
          },
          {
            label: this.$t('cip.index.scope.computeSign'),
            prop: 'computeSign',
            overHidden: true,
            cell: true,
          },
          {
            label: this.$t('cip.index.scope.value'),
            prop: 'value',
            overHidden: true,
            cell: true,
          }
        ],
      }
    },
    gridHeadBtn() {
      let result = [];
        // result.push({
        //   label: '预览数据',
        //   emit: "head-preview",
        //   type: "button",
        //   icon: "",
        //   btnOptType: '',
        // })
      return result;
    },
    // table的menu按钮
    gridRowBtn() {
      let result = [];
      return result;
    },
    calTableData() {
      return this.tableData.filter(e => {
        return (!this.searchExpression || e.expression.indexOf(this.searchExpression) !== -1) && (!this.searchAlias || e.alias.indexOf(this.searchAlias) !== -1)
      })
    }
  },
  mounted() {

    this.tableLoading = false;

  },
  created() {

  },
  methods: {
    gridHeadSearch(query) {
      this.searchExpression = query.expression
      this.searchAlias = query.alias
    },
    gridHeadEmpty(query) {
      this.searchExpression = query.expression
      this.searchAlias = query.alias
    },
    addForm(){
      this.scopeTable.push(
        {
          connectedRelation:'',
          dimension:'',
          computeSign:"",
          value:'',
          indexId:'',
          tableName:''
        }
      )
    },
    itemForm(data){
      this.scopeTable[data.index] = data.val
    },
    getSaveInfo(){
      // if (this.headForm.id){
      //   this.$message({
      //     message: this.$t("cip.index.scope.message"),
      //     type: "warning",
      //   });
      //   return;
      // }
      // this.scopeTable.forEach(e=>{
      //   e.indexId=this.headForm.id;
      // });
      return this.scopeTable;
    },
    headTest(){
      checkSqlById(this.headForm.id).then(res => {
        const {data} = res.data;
        this.dataForm.textarea = data;
      });
    },
    deleteForm(item,index){
      this.scopeTable.splice(index,1)
    }
  }
};
</script>
<style scoped>
.scopeWrap{
  padding-bottom: 0px;
  background: #ffffff;
}
.scopeForm{
  width: 100%;
  background: #ffffff;
}
.deleteForm{
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  textalign: center;
  cursor: pointer;
  color: #ebebeb;
}
.addWrap{
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  textalign: center;
  line-height: 40px !important;
  border: 2px dashed #e6e6e6;
  cursor: pointer;
}
</style>
