var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "组织架构图",
                  showIcon: false,
                  "head-btn-options": _vm.headBtnOrangeOptions,
                },
                on: {
                  "head-upload": _vm.headUpload,
                  "head-download": _vm.headDownload,
                },
              }),
              _c(
                "div",
                { staticClass: "uploadBox" },
                [
                  _c("el-upload", {
                    ref: "elUpload",
                    staticStyle: { display: "none" },
                    attrs: {
                      accept: ".jpg,.jpeg,.png,.JPG,.JPEG,.gif",
                      action:
                        "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                      "show-file-list": false,
                      headers: _vm.headers,
                      "on-success": _vm.uploadSuccess,
                      "before-upload": _vm.beforeAvatarUpload,
                    },
                  }),
                  !_vm.imageUrl
                    ? _c("div", { staticClass: "uploadBoxImage" }, [
                        _c("i", {
                          staticClass:
                            "el-icon-picture-outline avatar-uploader-icon",
                        }),
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.pageDisabled
                                  ? "暂无组织架构图"
                                  : "请上传组织架构图"
                              ) +
                              "\n          "
                          ),
                        ]),
                      ])
                    : _c(
                        "div",
                        { staticClass: "image__preview" },
                        [
                          _c("el-image", {
                            staticClass: "avatarImage",
                            attrs: {
                              fit: "contain",
                              src: _vm.imageUrl,
                              "preview-src-list": _vm.srcList,
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("head-layout", {
                attrs: {
                  "head-title":
                    _vm.erStatus == "PREPARE" ? "人员清单" : "人员到场情况",
                  showIcon: false,
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-add": _vm.handleAdd,
                  "head-delete": _vm.handleDelete,
                  "head-signIn": _vm.headSignIn,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOption,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "grid-row-detail-click": _vm.rowView,
                  "gird-handle-select-click": _vm.selectionChange,
                  "page-current-change": _vm.currentChange,
                  "page-size-change": _vm.sizeChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "signIn",
                    fn: function ({ row }) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(row.signIn == 1 ? "已签到" : "")),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "action",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          [
                            _vm.erStatus == "PREPARE"
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { margin: "0 3px" },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.rowEdit(row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑\n            ")]
                                )
                              : _vm._e(),
                            _vm.erStatus == "PREPARE"
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { margin: "0 3px" },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.rowDel(row)
                                      },
                                    },
                                  },
                                  [_vm._v("移除\n            ")]
                                )
                              : _vm._e(),
                            _vm.erStatus == "APPROVING"
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { margin: "0 3px" },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.rowSignIn(row)
                                      },
                                    },
                                  },
                                  [_vm._v("签到\n            ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("personnelDialog", { ref: "personnelDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }