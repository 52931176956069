<template>
  <div>
    <div>
      <head-layout head-title="输入参数"
                   :head-btn-options="headBtnOptions"
                   @head-add="gridAdd"
                   @head-save="gridSave()"
                   @head-romve="handleDelete">
      </head-layout>
      <grid-head-layout ref="searchFrom" :search-columns="searchColumns" v-model="searchForm"
                        @grid-head-search="searchChange" @grid-head-empty="searchReset">
      </grid-head-layout>
    </div>
    <!-- 表格 -->
    <grid-layout ref="gridLayOut" :tableOptions="tableOptions" :table-data="data" :table-loading="loading"
                 :data-total="dataTotal" :page="page" @page-size-change="onLoad" @page-current-change="onLoad"
                 @page-refresh-change="onLoad" @gird-handle-select-click="selectionChange" :gridRowBtn="gridRowBtn"
                 @grid-edit="gridEdit" @grid-romve="rowCell" @submit-add="rowSave"
                 @submit-edit="addUpdate"></grid-layout>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import {saveOrUpdate, removeLine, getInfoList} from "@/api/message/templateParam";

export default {
  name: "templateParam",
  components: {HeadLayout, GridLayout},
  props: {
    headForm: {
      type: Object,
      default: {},
    },
    type: {
      type: String,
      default: 'view',
    },
  },
  data() {
    return {
      searchForm: {},//搜索框对应数据
      data: [],//表格数据
      dataTotal: 0,//总数据量
      saveLoading: false,
      query: {},
      page: {
        pageSize: 20,
        pageSizes: [10, 20, 30, 40, 50, 100],
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      loading: false,
      tableOptions: {
        cellBtn: false,
        menuWidth: 200,
        menu: false,
        selection: ['add', 'edit'].includes(this.type),
        column: [
          {
            label: this.$t("cip.plat.message.template.parameter.field.paramKey"),
            prop: "paramKey",
            headerAlign: 'center',
            align: 'center',
            cell: true,
            sortable: true,
            maxlength: 40,
            showWordLimit: true,
            placeholder: this.$t("cip.plat.message.template.parameter.field.paramKey"),
            rules: [
              {
                required: true,
                message: this.$t('cip.cmn.rule.inputWarning') + this.$t("cip.plat.message.template.parameter.field.paramKey"),
                trigger: ['blur']
              },
              {
                pattern: /^[^\s]+(\s+[^\s]+)*$/,
                message: this.$t('cip.cmn.rule.deleteWarning'),
              }
            ],
          },
          {
            label: this.$t("cip.plat.message.template.parameter.field.paramName"),
            prop: "paramName",
            headerAlign: 'center',
            align: 'center',
            cell: true,
            sortable: true,
            maxlength: 80,
            showWordLimit: true,
            placeholder: this.$t("cip.plat.message.template.parameter.field.paramName"),
            rules: [
              {
                required: true,
                message: this.$t('cip.cmn.rule.inputWarning') + this.$t("cip.plat.message.template.parameter.field.paramName"),
                trigger: ['blur']
              },
              {
                pattern: /^[^\s]+(\s+[^\s]+)*$/,
                message: this.$t('cip.cmn.rule.deleteWarning'),
              }
            ],
          },
          {
            label: this.$t("cip.plat.message.template.parameter.field.paramRequired"),
            prop: 'paramRequired',
            cell: true,
            type: 'select',
            align: 'center',
            filterable: true,
            readonly: !['edit', 'add'].includes(this.type),
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no",
            rules: [
              {
                required: true,
                message: this.$t('cip.cmn.rule.selectWarning') + this.$t("cip.plat.message.template.parameter.field.paramRequired"),
                trigger: ['blur']
              },
            ],
          },
          {
            label: this.$t("cip.plat.message.template.parameter.field.paramRule"),
            prop: "paramRule",
            headerAlign: 'center',
            align: 'center',
            cell: true,
            sortable: true,
            maxlength: 80,
            showWordLimit: true,
            placeholder: this.$t("cip.plat.message.template.parameter.field.paramRule"),
          },
        ]
      },
    }
  },
  computed: {
    //表格行按钮
    gridRowBtn() {
      let result = [];
      if (['add', "edit"].includes(this.type)) {
        result.push({
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "grid-edit",
          type: "button",
          icon: "",
          cellEdit: true,
        });
        result.push({
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "grid-romve",
          type: "text",
          icon: "",
          cellEdit: true,
        });
      }
      return result;
    },
    ids() {
      let ids = [];
      this.selectionList.filter(e => e.id).forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "paramKey",
          placeholder: this.$t("cip.plat.message.template.parameter.field.paramKey"),
        }
      ];
    },
    headBtnOptions() {
      let result = [];
      if (['add', "edit"].includes(this.type)) {
        result = [
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: ""
          },
          {
            label: this.$t("cip.cmn.btn.addBtn"),
            emit: "head-add",
            type: "button",
            icon: ""
          },
          {
            label: this.$t("cip.cmn.btn.delBtn"),
            emit: "head-romve",
            type: "button",
            icon: ""
          },
        ];
      }
      return result;
    },
  },
  created() {
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    //新增
    gridAdd() {
      let headId = this.headForm.id;
      if (!headId) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.saveHeadWarning'));
        return;
      }
      let row = {
        templateId: this.headForm.id,
        paramKey: '',
        paramName: '',
        paramRequired: '',
        paramRule: '',
        $cellEdit: true,
      };
      this.data.push(row);
      this.$refs.gridLayOut.getGrid().toggleRowSelection(row, false);
    },
    rowSave(form, index, done) {
      this.saveLoading = true;
      let selection = [];
      selection.push(form);
      let row = {
        selectionList: '',
      }
      row.selectionList = selection;
      if (this.valid()) {
        saveOrUpdate(selection).then(res => {
          this.saveLoading = false;
          const {code} = res.data;
          if (code === 200) {
            this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'));
          }
          this.onLoad(this.page);
        });
      } else {
        this.saveLoading = false;
      }
      done();
    },
    addUpdate(form, index, done) {
      this.saveLoading = true;
      let selection = [];
      selection.push(form);
      let row = {
        selectionList: '',
      }
      row.selectionList = selection;
      saveOrUpdate(selection).then(res => {
        this.saveLoading = false;
        const {code} = res.data;
        if (code === 200) {
          this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'));
        }
        // this.onLoad(this.page);
      });
      done();
    },
    gridSave() {
      // 避免新增取消后依然勾选的空行，通过校验而产生空数据
      this.selectionList = this.selectionList.filter(e => e.paramKey != "");
      this.saveLoading = true;
      try {
        if (this.valid()) {
          saveOrUpdate(this.selectionList).then(res => {
            this.saveLoading = false;
            const {code, msg, data} = res.data;
            if (code === 200) {
              this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
            }
            this.onLoad(this.page);
          });
        } else {
          this.saveLoading = false;
        }

      } catch (e) {
        this.$message.warning(e.message)
        this.saveLoading = false;
        return
      }

    },
    //校验表格数据
    valid() {
      let result = true;
      if (!this.selectionList || this.selectionList.length === 0) {
        throw new Error(this.$t("cip.plat.message.template.parameter.msg.listNotEmpty"))
        result = false;
      } else {
        try {
          let obj = {};
          this.data.forEach((e, index) => {
            if (!e.paramKey || e.paramKey == '') {
              this.$message.warning(this.$t("cip.plat.message.template.parameter.field.paramKey") + this.$t("cip.cmn.rule.noEmptyWarning"));
              result = false;
              throw new Error(this.$t("cip.plat.message.template.parameter.msg.ending"));
            }
            if (!e.paramName || e.paramName == '') {
              this.$message.warning(this.$t("cip.plat.message.template.parameter.field.paramName") + this.$t("cip.cmn.rule.noEmptyWarning"));
              result = false;
              throw new Error(this.$t("cip.plat.message.template.parameter.msg.ending"));
            }
            if (!e.paramRequired || e.paramRequired == '') {
              this.$message.warning(this.$t("cip.plat.message.template.parameter.field.paramRequired") + this.$t("cip.cmn.rule.noEmptyWarning"));
              result = false;
              throw new Error(this.$t("cip.plat.message.template.parameter.msg.ending"));
            }
            if ("001" === this.headForm.templateMode && e.paramRequired == "y") {
              this.$message.warning(this.$t("cip.plat.message.template.parameter.msg.templateMode"));
              result = false;
              throw new Error(this.$t("cip.plat.message.template.parameter.msg.ending"));
            }
          })
          for (let i = 0; i < this.data.length; i++) {
            for (let j = i + 1; this.data.length; j++) {

              if (this.data[i].paramKey === this.data[j].paramKey) {
                this.$message.warning(this.$t("cip.plat.message.template.parameter.msg.repeated"));
                result = false;
                throw new Error(this.$t("cip.plat.message.template.parameter.msg.ending"));
              }
            }
          }
        } catch (e) {
        }
        return result;
      }
    },
    searchChange(params) {
      this.query = params;
      this.page = this.$refs.gridLayOut.page;
      this.$refs.gridLayOut.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    searchReset() {
      this.query = {};

      this.page = this.$refs.gridLayOut.page;
      this.$refs.gridLayOut.page.currentPage = 1;
      this.onLoad(this.page);
    },
    onLoad(page, params = {}) {
      if (!this.headForm || !this.headForm.id) {
        this.loading = false;
        return
      }
      this.page = this.$refs.gridLayOut.page;
      this.loading = true;

      this.query.templateId = this.headForm.id;

      if (this.type !== 'jump') {
        //重新加载信息
        getInfoList(page.currentPage, page.pageSize, this.query).then(res => {
          const data = res.data.data;
          this.page.total = data.total;
          this.data = data.records;
          this.data.forEach(e => {
            e.$cellEdit = false;
          })//对所有附表数据赋值的$cellEdit赋值
          this.$nextTick(() => {
            this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
          })
          this.loading = false;
          // this.selectionClear();
        });
      }
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    gridEdit(row, index) {
      if (!row.$cellEdit) {
        this.$refs.gridLayOut.getGrid().rowCell(row, index);
        this.$refs.gridLayOut.getGrid().toggleRowSelection(row, true);
      }
    },
    rowDelete(row, index) {
      if (row.$cellEdit) {
        if (row.paramKey != "") {
          this.data.splice(index, 1);
          this.onLoad(this.page, {});
        } else {
          this.data.splice(index, 1);
        }
      }
    },
    rowCell(row, index) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      }).then(() => {
        this.rowDelete(index);
        if (row.id) {

          removeLine(row.id);
          this.onLoad(this.page, {});
          this.$message.success(this.$t('cip.cmn.msg.success.delSuccess'))
        }
      });
    },
    /*cellClick(data) {
      let {row, column, cell, event} = data;
      selRow: undefined,
    },*/
    cellClick({row}) {
      this.selRow = row;
    },
    //删除选中行
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'))
        return
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      }).then(() => {
        if (this.ids) {
          return removeLine(this.ids)
        }
      }).then(() => {
        this.selectionList.sort((a, b) => {
          return b.$index - a.$index
        });
        this.selectionList.forEach((e) => {
          this.data.splice(e.$index, 1)
        })
        this.$message({
          type: 'success',
          message: this.$t("cip.cmn.msg.success.operateSuccess"),
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .avue-crud .el-table {
  height: calc(100vh - 530px) !important;
  max-height: calc(100vh - 530px) !important;
}
</style>
