<template>
  <div style="width: 100%;overflow: hidden;padding: 10px;background-color:#fff;">
    <div class="topContainer">
      <div style="position: relative;margin-top: auto;margin-bottom: auto">{{ title }}</div>
      <el-button style="margin: 10px" @click="celcen">取消</el-button>
    </div>
    <el-tabs class="SNOWS-el_tabs" v-model="activeTab">
      <el-tab-pane label="流程信息" v-loading="loading" name="comment">
        <Process v-if="processShow" :conf="flowTemplateJson"/>
      </el-tab-pane>
      <el-tab-pane label="流转记录"  v-loading="loading">
        <recordList v-if="processShow" :list='flowTaskOperatorRecordList'  :flowId="setting.flowId"/>
      </el-tab-pane>
    </el-tabs>
  </div>

</template>

<script>
import {FlowBeforeInfo} from "@/api/workFlow/FlowBefore";
import Process from '@/components/Process/Preview'
import recordList from "@/views/workFlow/components/RecordList";
import {mapGetters} from "vuex";
export default {
  name: "FlowChart",
  components: {Process,recordList},
  data(){
    return{
      flowTemplateJson:{},
      activeTab: 'comment',
      loading: false,
      setting: {},
      fullName: '',
      thisStep: '',
      flowTaskOperatorRecordList: [],
      processShow:false,
      flowUrgentList: [
        {name: '普通', color: '#409EFF', state: 1,},
        {name: '重要', color: '#E6A23C', state: 2,},
        {name: '紧急', color: '#F56C6C', state: 3,},
      ],
    }
  },
  computed:{
    title() {
      if ([2, 3, 4].includes(this.setting.opType)) return this.fullName
      return this.thisStep ? this.fullName + '/' + this.thisStep : this.fullName
    },
    selectState() {
      if (this.flowUrgent == 0 || this.flowUrgent == -1) this.flowUrgent = 1
      const index = this.flowUrgentList.findIndex(c => this.flowUrgent === c.state)
      return index
    },
    ...mapGetters(['userInfo'])
  },
  methods:{
    goBack(isRefresh) {
      this.$emit('close', isRefresh)
    },
    celcen(){
      this.$parent.processVisible = false
      this.processShow = false
    },
    async process(data){
      this.setting = data
      data.fullName = "test";
      await FlowBeforeInfo(data.id || 0, {
        taskNodeId: data.taskNodeId,
        taskOperatorId: data.taskId,
        flowId: data.flowId
      }).then(res => {
        this.flowFormInfo = res.data.data.flowFormInfo
        this.flowTaskInfo = res.data.data.flowTaskInfo || {}
        this.flowTemplateInfo = res.data.data.flowTemplateInfo;
        const fullName = data.opType == '-1' ? this.flowTemplateInfo.fullName : this.flowTaskInfo.fullName
        data.fullName = fullName
        this.fullName = fullName
        this.thisStep = this.flowTaskInfo.thisStep
        this.flowUrgent = this.flowTaskInfo.flowUrgent || 1
        data.type = this.flowTemplateInfo.type
        data.draftData = res.data.data.draftData || null
        data.formData = res.data.data.formData || {}
        // const formUrl = 'workFlow/workFlowForm/dynamicForm';
        const formUrl = this.flowFormInfo.formType == 2 ? 'workFlow/workFlowForm/dynamicForm' : this.flowFormInfo.urlAddress ? this.flowFormInfo.urlAddress.replace(/\s*/g, "") : `workFlow/workFlowForm/${this.flowFormInfo.enCode}`
        this.currentView = (resolve) => require([`@/views/${formUrl}`], resolve)
        this.flowTaskNodeList = res.data.data.flowTaskNodeList || []
        this.flowTemplateJson = this.flowTemplateInfo.flowTemplateJson ? JSON.parse(this.flowTemplateInfo.flowTemplateJson) : null
        this.isComment = this.flowTemplateJson.properties.isComment
        this.isSummary = this.flowTemplateJson.properties.isSummary
        this.summaryType = this.flowTemplateJson.properties.summaryType
        this.processShow = true
        this.flowTaskOperatorRecordList = res.data.data.flowTaskOperatorRecordList || []
        this.flowTaskOperatorRecordList = this.flowTaskOperatorRecordList.reverse()
        this.properties = res.data.data.approversProperties || {}
        this.endTime = this.flowTaskInfo.completion == 100 ? this.flowTaskInfo.endTime : 0
        data.formConf = this.flowFormInfo.propertyJson
        if (data.opType != 1 && data.opType != '-1') data.readonly = true
        data.formOperates = res.data.data.formOperates || []
        if (data.opType == 0) {
          for (let i = 0; i < data.formOperates.length; i++) {
            data.formOperates[i].write = false
          }
        }
        // workflow/Engine/FlowBefore/1688806528431296512?taskNodeId=1688482490609700865&taskOperatorId=1688806528431296512&flowId=1688466843746045952
          // workflow/Engine/FlowBefore/1688482486351757313?taskNodeId=1688482490609700865&taskOperatorId=1688806528431296512&flowId=1688466843746045952
          data.flowTemplateJson = this.flowTemplateJson
        if (this.flowTaskNodeList.length) {
          let assignNodeList = []
          for (let i = 0; i < this.flowTaskNodeList.length; i++) {
            const nodeItem = this.flowTaskNodeList[i]
            if (nodeItem.type == '') {
              nodeItem.type = null;
            }
            data.opType == 4 && nodeItem.type == 1 && nodeItem.nodeType === 'approver' && assignNodeList.push(nodeItem)
            const loop = data => {
              if (Array.isArray(data)) data.forEach(d => loop(d))
              if (data.nodeId === nodeItem.nodeCode) {
                if (nodeItem.type == 0) data.state = 'state-past'
                if (nodeItem.type == 1) data.state = 'state-curr'
                if (nodeItem.nodeType === 'approver' || nodeItem.nodeType === 'start' || nodeItem.nodeType === 'subFlow') data.content = nodeItem.userName
                return
              }
              if (data.conditionNodes && Array.isArray(data.conditionNodes)) loop(data.conditionNodes)
              if (data.childNode) loop(data.childNode)
            }
            loop(this.flowTemplateJson)
          }
          this.assignNodeList = assignNodeList

        } else {
          this.flowTemplateJson.state = 'state-curr'
        }
        this.initBtnList();

      }).catch(() => {
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .topContainer{
    display: flex;
    justify-content: space-between;
    background-color:#fff;
    width: 100%;
    height: 50px;
    text-align: right;
  }
</style>
