<template>
  <div class="SNOWS-common-layout">
    <div class="SNOWS-common-layout-center">
      <head-layout head-title="我发起的" />
      <el-row class="SNOWS-common-search-box" :gutter="16">
        <el-form @submit.native.prevent>
          <el-col :span="4">
            <el-form-item>
              <el-input v-model="fullName" placeholder="请输入流程标题" clearable
                @keyup.enter.native="search()" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-date-picker v-model="pickerVal" type="daterange" start-placeholder="开始日期"
                end-placeholder="结束日期" :picker-options="pickerOptions" value-format="timestamp"
                clearable :editable="false">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item>
              <el-select v-model="status" placeholder="选择流程状态" clearable>
                <el-option v-for="(item,i) in statusList" :key="i" :label="item.fullName"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item>
              <el-select v-model="urgent" placeholder="选择紧急程度" clearable>
                <el-option v-for="(item,i) in urgentList" :key="i" :label="item.fullName"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
<!--          <el-col :span="6">
            <el-form-item label="所属分类">
              <el-select v-model="flowCategory" placeholder="选择所属分类" clearable>
                <el-option v-for="item in categoryList" :key="item.id" :label="item.fullName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <template v-if="showAll">
            <el-col :span="6">
              <el-form-item label="所属流程">
                <el-select v-model="flowId" placeholder="选择所属流程" clearable>
                  <el-option-group v-for="group in flowEngineList" :key="group.id"
                    :label="group.fullName+'【'+group.num+'】'">
                    <el-option v-for="item in group.children" :key="item.id" :label="item.fullName"
                      :value="item.id">
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>-->

<!--          </template>-->
          <el-col :span="2">
            <el-button-group>
              <el-button size="mini" type="primary" icon="el-icon-search" @click="search()"></el-button>
              <el-button size="mini" icon="reset-refresh icon-refresh" @click="refresh()"></el-button>
            </el-button-group>
          </el-col>
<!--          <el-col :span="6">
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="search()">
                {{$t('common.search')}}</el-button>
              <el-button icon="el-icon-refresh-right" @click="refresh()">{{$t('common.reset')}}
              </el-button>
              <el-button type="text" icon="el-icon-arrow-down" @click="showAll=true"
                v-if="!showAll">展开</el-button>
              <el-button type="text" icon="el-icon-arrow-up" @click="showAll=false" v-else>
                收起</el-button>
            </el-form-item>
          </el-col>-->
        </el-form>
      </el-row>
      <div class="SNOWS-common-layout-main SNOWS-flex-main">
<!--        <div class="SNOWS-common-head">
          <topOpts @add="addFlow()" addText="新建流程"></topOpts>
          <div class="SNOWS-common-head-right">
            <el-tooltip effect="dark" :content="$t('common.refresh')" placement="top">
              <el-link icon="icon-ym icon-ym-Refresh SNOWS-common-head-icon" :underline="false"
                @click="initData()" />
            </el-tooltip>
          </div>
        </div>-->
        <SNOWS-table v-loading="listLoading" :data="list">
          <el-table-column prop="fullName" label="流程标题" show-overflow-tooltip min-width="150" />
          <el-table-column prop="flowName" label="所属流程" width="130" />
          <el-table-column prop="startTime" label="发起时间" width="130"
            :formatter="snows.tableDateFormat" />
          <el-table-column prop="thisStep" label="审批节点" width="150" />
          <el-table-column prop="flowUrgent" label="紧急程度" width="100" align="center">
            <template slot-scope="scope">
<!--              {{ scope.row.flowUrgent | urgentText() }}-->
                <el-tag type="warning" v-if="scope.row.flowUrgent==2">{{ scope.row.flowUrgent | urgentText() }}</el-tag>
                <el-tag type="danger" v-else-if="scope.row.flowUrgent==3">{{ scope.row.flowUrgent | urgentText() }}</el-tag>
                <el-tag type="primary" v-else>{{ scope.row.flowUrgent | urgentText() }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="流程状态" width="130" align="center">
            <template slot-scope="scope">
              <el-tag type="primary" v-if="scope.row.status==1">等待审核</el-tag>
              <el-tag type="success" v-else-if="scope.row.status==2">审核通过</el-tag>
              <el-tag type="danger" v-else-if="scope.row.status==3">审核退回</el-tag>
              <el-tag type="info" v-else-if="scope.row.status==4">流程撤回</el-tag>
              <el-tag type="info" v-else-if="scope.row.status==5">审核终止</el-tag>
              <el-tag type="warning" v-else>等待提交</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="completion" label="流程进度" width="150">
            <template slot-scope="scope">
              <p class="text-grey" v-if="scope.row.status==5 || scope.row.completion == 0">----</p>
              <p v-else-if=" scope.row.completion == 100">已完成</p>
              <el-progress :percentage="scope.row.completion" v-else></el-progress>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="toDetail(scope.row,'-1')"
                :disabled="[1,2,4,5].indexOf(scope.row.status)>-1">编辑
              </el-button>
              <el-button size="mini" type="text" class="SNOWS-table-delBtn"
                @click="handleDel(scope.$index,scope.row.id)"
                :disabled="[1,2,3,5].indexOf(scope.row.status)>-1">删除
              </el-button>
              <el-button size="mini" type="text" @click="toDetail(scope.row,0)"
                :disabled="!scope.row.status">详情
              </el-button>
              <el-button size="mini" type="text" @click="toProcess(scope.row)">流程追踪</el-button>
            </template>
          </el-table-column>
        </SNOWS-table>
        <pagination :total="total" :page.sync="listQuery.currentPage"
          :limit.sync="listQuery.pageSize" @pagination="initData" />
      </div>
    </div>
    <FlowChart v-if="processVisible" ref="flowChart"  @close="closeForm"/>
    <flow v-if="flowVisible" ref="flow" @close="flowVisible=false" @choiceFlow="choiceFlow" />
    <FlowBox v-if="formVisible" ref="FlowBox" @close="closeForm" />
  </div>
</template>

<script>
import { FlowLaunchList, Delete } from '@/api/workFlow/FlowLaunch'
import { getFlowCateTree } from "@/util/util";
import { FlowEngineListAll } from '@/api/workFlow/FlowEngine'
import FlowChart from "@/views/workFlow/components/FlowChart";
import FlowBox from '../components/FlowBox'
import flow from './Flow'
export default {
  name: 'workFlow-flowLaunch',
  components: { FlowBox, flow,FlowChart },
  data() {
    return {
      list: [],
      total: 0,
      showAll: false,
      listLoading: false,
      processVisible: false,
      listQuery: {
        currentPage: 1,
        pageSize: 20,
        sort: 'desc',
        sidx: ''
      },
      fullName: '',
      formVisible: false,
      flowVisible: false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      statusList: [{
        id: 0,
        fullName: '等待提交'
      }, {
        id: 1,
        fullName: '等待审核'
      }, {
        id: 2,
        fullName: '审核通过'
      }, {
        id: 3,
        fullName: '审核退回'
      }, {
        id: 4,
        fullName: '流程撤回'
      }, {
        id: 5,
        fullName: '审核终止'
      }],
      urgentList: [
        {
          id: 1,
          fullName: '普通'
        }, {
          id: 2,
          fullName: '重要'
        }, {
          id: 3,
          fullName: '紧急'
        }
      ],
      keyword: '',
      pickerVal: [],
      startTime: '',
      endTime: '',
      flowId: '',
      status: '',
      urgent: '',
      flowCategory: '',
      categoryList: [],
      flowEngineList: []
    }
  },
  filters: {
    getCategoryText(id, categoryList) {
      let item = categoryList.filter(o => o.enCode == id)[0]
      return item && item.fullName ? item.fullName : ''
    }
  },
  created() {
    // this.getDictionaryData()
    this.getFlowEngineList()
    this.initData()
  },
  methods: {
    toProcess(item){
      let data = {
        id: item.processId,
        enCode: item.flowCode,
        flowId: item.flowId,
        opType: 1,
        taskNodeId: item.thisStepId,
        taskId: item.id
      }
      this.processVisible = true
      this.$nextTick(()=>{
        this.$refs.flowChart.process(data)
      })
    },
    search() {
      if (this.pickerVal && this.pickerVal.length) {
        this.startTime = this.pickerVal[0]
        this.endTime = this.pickerVal[1]
      } else {
        this.startTime = ''
        this.endTime = ''
      }
      this.listQuery = {
        currentPage: 1,
        pageSize: 20,
        sort: 'desc',
        sidx: ''
      }
      this.initData()
    },
    getFlowEngineList() {
      FlowEngineListAll().then((res) => {
        this.flowEngineList = res.data.list
      })
    },
    getDictionaryData() {
      this.$store.dispatch('base/getDictionaryData', { sort: 'WorkFlowCategory' }).then((res) => {
        this.categoryList = res
      })
    },
    initData() {
      this.listLoading = true
      let query = {
        ...this.listQuery,
        fullName: this.fullName,
        startTime: this.startTime,
        endTime: this.endTime,
        flowId: this.flowId,
        status: this.status,
        flowUrgent: this.urgent,
        flowCategory: this.flowCategory
      }
      FlowLaunchList(query).then(res => {
        console.log(res)
        this.list = res.data.data.records
        this.total = res.data.data.total
        this.listLoading = false
      })
    },
    handleDel(index, id) {
      this.$confirm(this.$t('common.delTip'), this.$t('common.tipTitle'), {
        type: 'warning'
      }).then(() => {
        this.asyncDel(index, id);
      }).catch(() => { });
    },
    asyncDel(index, id) {
      Delete(id).then(res => {
        this.list.splice(index, 1);
        this.$message({
          type: 'success',
          message: res.msg
        });
      })
    },
    addFlow() {
      this.flowVisible = true
      this.$nextTick(() => {
        this.$refs.flow.init()
      })
    },
    choiceFlow(item) {
      let data = {
        id: '',
        enCode: item.enCode,
        flowId: item.id,
        opType: '-1'
      }
      this.formVisible = true
      this.$nextTick(() => {
        this.$refs.FlowBox.init(data)
        this.flowVisible = false
      })
    },
    toDetail(item, opType) {
      this.formVisible = true
      let data = {
        id: item.processId,
        enCode: item.flowCode,
        flowId: item.flowId,
        opType,
        status: item.status,
        parentId: item.parentId,
        taskId: item.id
      }
      this.$nextTick(() => {
        this.$refs.FlowBox.init(data)
      })
    },
    closeForm(isRefresh) {
      this.formVisible = false
      if (isRefresh) this.refresh()
    },
    refresh() {
      this.pickerVal = ''
      this.startTime = ''
      this.endTime = ''
      this.keyword = ''
      this.flowId = ''
      this.status = ''
      this.urgent = ''
      this.flowCategory = ''
      this.listQuery = {
        currentPage: 1,
        pageSize: 20,
        sort: 'desc',
        sidx: ''
      }
      this.initData()
    }
  }
}
</script>
<style lang="scss" scoped>
.SNOWS-flex-main {
  padding: 0 10px 10px 10px;
}

.pagination-container {
  padding-top: 10px;
  border-top: 1px solid #EBEEF5;
}
</style>
