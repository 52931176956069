<template>
  <div class="center">
    <el-container>
      <wf-category
        @node-click="nodeClick"
        @list-change="findObject(option.column, 'categoryId').dicData = $event"
      ></wf-category>

      <el-main>
        <!--列表label-->
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="$t('cip.plat.wfDesign.model.title.indexHeadTitle')"
          @head-add="handleDesign({})"
          @head-category="handleChangeCategory"
          @head-message="handleChangeMessage"
        ></head-layout>
        <!--列表查询条件和头部按钮-->
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>

        <grid-layout
          ref="gridLayOut"
          :table-options="option"
          :table-data="data"
          :table-loading="loading"
          :data-total="page.total"
          :grid-row-btn="gridRowBtn"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
          @row-sj="rowSj"
          @row-bs="rowBs"
          @row-qx="rowQx"
          @commandClick="commandClick"
        ></grid-layout>
      </el-main>
    </el-container>
    <CommonDialog v-if="categoryVisible" :dialogTitle="$t('cip.plat.wfDesign.model.title.arrangeHeadTitle')"  width="30%" @cancel="categoryVisible = false" @confirm="deploySubmit">
      <avue-form
        v-model="form"
        ref="deploySubmit"
        :option="{
          menuBtn: false,
          column: [
            {
              type: 'tree',
              label: this.$t(`cip.plat.wfDesign.model.field.category`),
              span: 24,
              props: { label: 'name', value: 'id' },
              prop: 'category',
              dicUrl: '/api/sinoma-workflow/design/category/tree',
              required: true,
              rules: [{
                required: true,
                message: this.$t(`cip.cmn.rule.selectWarning`)+this.$t(`cip.plat.wfDesign.model.field.category`),
              }],
            },
          ],
        }"
        @submit="handleDeploySubmit"
      ></avue-form>
    </CommonDialog>
    <CommonDialog v-if="iconVisible" :dialogTitle="$t('cip.plat.wfDesign.model.title.uploadIconHeadTitle')"  width="30%" @cancel="iconVisible = false" @confirm="iconSubmit">
      <avue-form
        v-model="form"
        ref="iconSubmit"
        :option="{
          menuBtn: false,
          column: [
            {
              label: this.$t(`cip.plat.wfDesign.model.field.icon`),
              type: 'upload',
              propsHttp: { res: 'data', url: 'link', name: 'originalName' },
              tip: this.$t(`cip.plat.wfDesign.model.msg.suggestedSize`),
              action: '/api/sinoma-resource/oss/endpoint/put-file',
              display: true,
              showFileList: true,
              prop: 'icon',
              listType: 'picture-img',
              required: true,
              rules: [{ required: true, message: this.$t(`cip.plat.wfDesign.model.msg.requireIcon`), }],
              accept: 'image/*',
            },
          ],
        }"
        @submit="handleIconSubmit"
      ></avue-form>
    </CommonDialog>
    <!-- 选择消息模板 -->
    <CommonDialog v-if="messageVisible" dialogTitle="选择消息模板"  width="30%" @cancel="messageVisible = false" @confirm="messageSubmit">
      <avue-form
        v-if="messageVisible"
        v-model="form"
        ref="messageSubmit"
        :option="{
          menuBtn: false,
          column: [
            {
              type: 'tree',
              label: '消息模板',
              span: 24,
              props: {
                label: 'templateName',
                value: 'templateCode',
              },
              prop: 'messageTemplateCode',
              dicUrl: '/api/sinoma-message/msgTemplate/selectPage?current=1&size=99999',
              dicFormatter: (res) => {
                return res.data.records;
              },
              required: false,
            },
          ],
        }"
        @submit="handleMessageSubmit"
      ></avue-form>
    </CommonDialog>
    <user-option
      ref="userOption"
      :user-option="userOption"
      @submit="handleScopeSubmit"
    ></user-option>
  </div>
</template>

<script>
import {
  getList,
  remove,
  deploy,
  changeCategory,
  getDetail,
  changeIcon,
  setUpMessageTemplate
} from "@/api/plugin/workflow/model";
import {getList as scopeList, submit as scopeSubmit} from '@/api/plugin/workflow/model-scope'
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

import {mapGetters} from "vuex";

import UserOption from '../process/components/user-option.vue'
import WfCategory from '../process/components/category.vue'
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import CommonDialog from "@/components/CommonDialog"
export default {
  mixins: [exForm],
  components: {UserOption, WfCategory, GridLayout, HeadLayout, CommonDialog},
  data() {
    return {
      form: {},
      query: {
        categoryId_equal: null,
      },
      loading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        menuWidth: 200,
        index: true,
        indexLabel: '序号',
        column: [
          {
            label: this.$t(`cip.plat.wfDesign.model.field.icon`),
            prop: "icon",
            type: 'upload',
            width: 120,
            overHidden: true,
            align: "center",
          },
          {
            label: this.$t(`cip.plat.wfDesign.model.field.modelKey`),
            prop: "modelKey",
            overHidden: true,
            align: "center",
            width: 240,
          },
          {
            label: this.$t(`cip.plat.wfDesign.model.field.name`),
            prop: "name",
            overHidden: true,
            align: "center",
            width: 240,
          },
          {
            label: this.$t(`cip.plat.wfDesign.model.field.categoryId`),
            prop: 'categoryId',
            align: 'center',
            type: 'tree',
            overHidden: true,
            width: 100,
            props: {
              label: 'name',
              value: 'id'
            },
            dicData: [],
          },
          {
            label: this.$t(`cip.plat.wfDesign.model.field.description`),
            prop: "description",
            align: 'center',
            overHidden: true,
          },
          {
            label: this.$t(`cip.plat.wfDesign.model.field.version`),
            prop: "version",
            align: 'center',
            overHidden: true,
            width: 80
          },
        ]
      },
      data: [],
      row: '',
      categoryVisible: false,
      messageVisible: false,
      userOption: {
        userUrl: '/api/sinoma-user/search/user',
        roleUrl: '/api/sinoma-system/search/role',
        deptUrl: '/api/sinoma-system/search/dept',
        postUrl: '/api/sinoma-system/search/post',
      },
      iconVisible: false,
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.wf_design_model_add, false),
        viewBtn: this.vaildData(this.permission.wf_design_model_view, false),
        delBtn: this.vaildData(this.permission.wf_design_model_delete, false),
        editBtn: this.vaildData(this.permission.wf_design_model_edit, false)
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: this.$t(`cip.plat.wfDesign.model.field.modelKey`),
          prop: "modelKey",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.wfDesign.model.field.modelKey`),
        },
        {
          label: this.$t(`cip.plat.wfDesign.model.field.name`),
          prop: "name",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.wfDesign.model.field.name`),
        },
      ]
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      if (this.permission.wf_design_model_add) {
        result.push({
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
        });
      }
      //更改分类
      if (this.permission.wf_design_model_change_category) {
        result.push({
          label: this.$t(`cip.plat.wfDesign.model.btn.changeTypeBtn`),
          emit: "head-category",
        });
      }
      //更改消息模板
      if (this.permission.wf_design_model_change_category) {
        result.push({
          label: '更改消息模板',
          emit: "head-message",
        });
      }
      return result;
    },
    // 行按钮添加 add by steve
    gridRowBtn() {
      let result = [];
      //新增按钮
      if (this.permission.wf_design_model_design) {
        result.push({
          label: this.$t(`cip.plat.wfDesign.model.btn.designBtn`),
          emit: "row-sj",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.wf_design_model_deploy) {
        result.push({
          label: this.$t(`cip.plat.wfDesign.model.btn.deployBtn`),
          emit: "row-bs",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.wf_design_model_scope) {
        result.push({
          label: this.$t(`cip.plat.wfDesign.model.btn.permissionsBtn`),
          emit: "row-qx",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      let dropBtn = [];
      if (this.permission.wf_design_model_icon) {
        dropBtn.push(
          {
            label: this.$t(`cip.plat.wfDesign.model.btn.iconBtn`),
            emit: "row-icon",
            type: "primary",
            icon: "el-icon-plus"

          });
      }
      if (this.permission.wf_design_model_history) {
        dropBtn.push(
          {
            label: this.$t(`cip.plat.wfDesign.model.btn.historysBtn`),
            emit: "row-history",
            type: "primary",
            icon: "el-icon-plus",
          });
      }
      if (this.permission.wf_design_model_copy) {
        dropBtn.push(
          {
            label: this.$t(`cip.plat.wfDesign.model.btn.copyBtn`),
            emit: "row-copy",
            type: "primary",
            icon: "el-icon-plus",
          });
      }
      if (this.permission.wf_design_model_delete) {
        dropBtn.push(
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "row-del",
            type: "primary",
            icon: "el-icon-plus",
          });
      }
      result.push({
        label: this.$t(`cip.plat.wfDesign.model.btn.moreBtn`),
        emit: "row-more",
        type: "primary",
        icon: "el-icon-plus",
        dropList: dropBtn
      });
      return result;
    },
  },
  methods: {
    // 更多内的点击事件
    commandClick(data) {
      if (data.command.emit == 'row-icon') {
        this.handleIcon(data.row)
      } else if (data.command.emit == 'row-history') {
        this.handleHistory(data.row)
      } else if (data.command.emit == 'row-copy') {
        this.handleCopy(data.row)
      } else if (data.command.emit == 'row-del') {
        this.rowDel(data.row)
      }
    },
    rowSj(row) {
      this.handleDesign(row)
    },
    rowBs(row) {
      if (row.categoryId == -1) {
        this.$message.warning(this.$t(`cip.plat.wfDesign.model.msg.changeType`))
      } else {
        this.handleDeploy(row)
      }

    },
    rowQx(row) {
      this.handleScope(row)
    },
    // 列表查询 add by steve
    gridHeadSearch(searchForm) {
      this.onLoad(this.page, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty(searchForm) {
      this.onLoad(this.page, searchForm);
    },
    handleIcon(row) {
      this.form = {id: row.id, icon: row.icon}
      this.iconVisible = true
    },
    iconSubmit(){
      this.$refs.iconSubmit.submit()
    },
    handleIconSubmit() {
      const {id, icon} = this.form
      changeIcon({id, icon}).then(() => {
        const row = this.data.find(d => d.id == id)
        if (row) row.icon = icon
        this.$message.success(this.$t(`cip.plat.wfDesign.model.msg.setIcon`))
        this.iconVisible = false
      })
    },
    handleCopy(row) {
      getDetail(row.id).then(res => {
        const {xml} = res.data.data
        this.$Clipboard({
          text: xml
        }).then(() => {
          this.$message.success(this.$t(`cip.plat.wfDesign.model.msg.copy`))
        }).catch(() => {

        })
      })
    },
    handleChangeCategory() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.categoryType = 'change'
      this.categoryVisible = true
    },
    handleChangeMessage() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.messageVisible = true;
    },
    // 选择消息模板
    messageSubmit(){
      this.$refs.messageSubmit.submit();
    },
    handleMessageSubmit(form, done) {
      let ids = [];
      this.selectionList.forEach((val) => {
        ids.push(val.id);
      });
      ids = ids.toString();
      done();
      setUpMessageTemplate({
        ids: ids,
        messageTemplateCode: form.messageTemplateCode ? form.messageTemplateCode : '',
      }).then((res) => {
        if (res.data.code == 200) {
          this.messageVisible = false;
          this.onLoad(this.page, this.query);
        }
      });
    },
    nodeClick({id}) {
      this.categoryId = id
      this.searchChange(this.query)
    },
    handleScopeSubmit(list) {
      list.forEach(l => l.val = l.value)

      const {id, modelKey} = this.row
      const param = {
        modelId: id, modelKey,
        scopeList: list
      }
      scopeSubmit(param).then(() => {
        this.$message.success(this.$t(`cip.cmn.msg.success.operateSuccess`))
      })
    },
    handleScope(row) {
      scopeList({modelId: row.id}).then(res => {
        this.$set(this.userOption, 'data', res.data.data.map(d => {
          return {
            value: d.val,
            text: d.text,
            type: d.type
          }
        }))
        this.row = row
        this.$refs.userOption.visible = true
      })
    },
    deploySubmit(){
      this.$refs.deploySubmit.submit();
    },
    handleDeploySubmit(form, done) {
      const {id, category} = form
      if (this.categoryType == 'change') {
        changeCategory({ids: this.ids, category}).then(() => {
          this.$message.success(this.$t(`cip.cmn.msg.success.updataSuccess`))
          this.categoryType = ''
          done()
          this.categoryVisible = false
          this.onLoad(this.page, this.query)
        })
      } else {
        deploy({id, category}).then(() => {
          this.$message.success(this.$t(`cip.plat.wfDesign.model.msg.deploy`))
          done()
          this.categoryVisible = false
        })
      }
    },
    handleDeploy(row) {
      this.form.id = row.id
      this.form.category = row.categoryId
      this.categoryVisible = true
    },
    handleDesign(row) {
      this.$router.push('/workflow/design/process/' + (row.id || 0))
    },
    handleHistory(row) {
      this.$router.push('/workflow/design/model/history/' + row.id)
    },
    rowDel(row) {
      this.$confirm(this.$t(`cip.plat.wfDesign.model.msg.remove`), {
        distinguishCancelAndClose: true,
        confirmButtonText: this.$t(`cip.plat.wfDesign.model.btn.returnBtn`),
        cancelButtonText: this.$t(`cip.plat.wfDesign.model.btn.allRemoveBtn`),
        type: "warning"
      }).then(() => {
        const param = {
          id: row.id,
          rollback: true
        }
        remove(param).then(() => {
          this.onLoad(this.page);
          this.$message.success(this.$t(`cip.cmn.msg.success.operateSuccess`))
        })
      }).catch(action => {
        if (action == 'cancel') {
          const param = {
            id: row.id,
            rollback: false
          }
          remove(param).then(() => {
            this.onLoad(this.page);
            this.$message.success(this.$t(`cip.cmn.msg.success.operateSuccess`))
          })
        }
      })
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove({id: this.ids});
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t(`cip.cmn.msg.success.operateSuccess`),
          });
          this.$refs.crud.toggleSelection();
        });
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.onLoad(this.page, params);
      if (done && typeof done == 'function') done()
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    onLoad(page, params = {}) {
      this.page = page
      this.loading = true;
      this.$refs.gridHeadLayout.searchForm.categoryId_equal = this.categoryId
      getList(page.currentPage, page.pageSize, Object.assign(params, this.$refs.gridHeadLayout.searchForm)).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.data = data.records;
        this.loading = false;
      });
    },

  },
  mounted() {
    this.onLoad(this.page);
  },
};
</script>

<style lang="scss" scoped>
.center {
  padding: 0 !important;
}

::v-deep .avue-crud__img {
  img {
    width: 25px;
    height: 25px;
  }
}

::v-deep .avue-upload__icon {
  line-height: 178px !important;
}
</style>
