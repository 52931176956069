<template>
  <div class="center">
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="待办" name="second"></el-tab-pane>
      <el-tab-pane label="已办" name="third"></el-tab-pane>
    </el-tabs>
    <!--列表label-->
<!--    <head-layout :head-title="$t(`cip.desk.workflow.title.indexHeadTodo`)" :head-btn-options="headTopBtnOptions" @batch-audit="batchAudit"></head-layout>-->
    <!--列表查询条件和头部按钮-->
    <grid-head-layout ref="gridHeadLayout"
                      v-model="searchForm"
                      :head-title="$t(`cip.desk.workflow.title.indexHeadTodo`)"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"></grid-head-layout>
    <grid-layout ref="gridLayOut"
                 :head-title="$t(`cip.desk.workflow.title.indexHeadTodo`)"
                 :table-options="option"
                 :table-data="data"
                 :table-loading="loading"
                 :data-total="page.total"
                 :page="page"
                 @gird-handle-select-click="selectionChange"
                 @page-current-change="onLoad"
                 @page-size-change="onLoad"
                 @page-refresh-change="onLoad"
               >
      <template #customBtn="{row}">
        <el-button style="margin: 0 3px"  type="text"  size="small" @click="rowTrackPending(row)">详情</el-button>
        <el-button style="margin: 0 3px"  v-if="row.businessType == 0" type="text" size="small" @click="handleFlow(row)">流程图</el-button>
      </template>
    </grid-layout>
    <common-dialog
      v-if="bpmnVisible"
      width="70%"
      customClass="wf-dialog"
      :dialogTitle="$t(`cip.desk.workflow.title.indexHeadVisible`)"
      :showBtn="false"
      @cancel="bpmnVisible = false">
      <wf-design ref="bpmn"
                 style="height: 60vh;"
                 :options="bpmnOption"></wf-design>
    </common-dialog>
  </div>
</template>

<script>
import CommonDialog from "@/components/CommonDialog";
import {sinomatodo as getList, detail, batchCompleteTask} from "@/api/plugin/workflow/process";
import {mapGetters} from "vuex";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import exForm from '../mixins/ex-form'
import website from "@/config/website";
import WfSearch from './components/search.vue'
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

export default {
  mixins: [exForm],
  components: {WfSearch, HeadLayout, GridLayout, CommonDialog},
  data() {
    return {
      activeName:'second',
      headTopBtnOptions:[
        {
          label: '批量通过',
          emit: "batch-audit",
        }
      ],
      searchForm: {},
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: PAGE_CONSTANT.TWENTY,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        index: true,
        indexLabel: "序号",
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        linklabel: "processDefinitionName",
        column: [
          {
            label: this.$t('cip.desk.todo.field.nameOfTheProcess'),
            prop: 'taskName',
            align: "left",
            overHidden: true
          },
          {
            label: this.$t('cip.desk.todo.field.ProcessClassification'),
            type: 'tree',
            align: "center",
            overHidden: true,
            dicData: [
              {name:'流程待办',value:0},
              {name:'任务待办',value:1},
            ],
            props: {
              label: 'name',
              value: 'value'
            },
            prop: "businessType",
          },
          {
            label: this.$t('cip.desk.todo.field.businessType'),
            prop: 'typeName',
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t('cip.desk.todo.field.Issuer'),
            prop: 'creatorRealName',
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t('cip.desk.todo.field.ApplicationTime'),
            prop: 'createTime',
            align: "center",
            type: 'datetime',
            format: 'yyyy-MM-dd HH:mm',
            overHidden: true,
          },
        ]
      },
      data: [],
      bpmnVisible: false,
      bpmnOption: {}
    };
  },
  computed: {
    ...mapGetters(["permission", "language"]),
    refresh() {
      return this.$store.state.common.refresh
    },
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.deployment_add, false),
        viewBtn: this.vaildData(this.permission.deployment_view, false),
        delBtn: this.vaildData(this.permission.deployment_delete, false),
        editBtn: this.vaildData(this.permission.deployment_edit, false)
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: this.$t('cip.desk.workflow.field.nameOfTheProcess'),
          prop: "taskName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.desk.workflow.field.nameOfTheProcess'),
        },
      ]
    },
  },
  methods: {
    handleClick(){
      this.onLoad(this.page, {});
    },
    batchAudit(){
      if(this.selectionList==null || this.selectionList.length ===0){
        this.$message.warning("请至少选择一条数据")
        return;
      }
      let paramArr = this.selectionList.map(e => {
        return {
          pass:true,
          processDefinitionId:e.processDefinitionId,
          processDefinitionName:e.processDefinitionName,
          processInstanceId:e.processInstanceId,
          taskId:e.taskId,
          variables:e.variables
        }
      })

      this.$loading()
      batchCompleteTask(paramArr).then((res) => {
        if (res.data.code == 200){
          this.page.currentPage = 1
          this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
          this.$message.success("审批成功")
        }else {
          this.$message.error(res.data.msg)
        }
        console.log(res)
        this.$loading().close()
        resolve()
      }).catch(() => {
        reject()
      })
    },
    // 列表查询 add by steve
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    rowEdit(row) {
      this.dynamicRoute(row, 'detail')
    },
    rowView(row) {
      const {taskId, processInstanceId} = row;
      detail({taskId, processInsId: processInstanceId}).then((res) => {
        const {process, flow} = res.data.data;
        const { id, taskId, processInstanceId, processId, processDefKey } =
          process;
        let param = Buffer.from(
          JSON.stringify({
            processId: id,
            taskId,
            processInsId: processInstanceId || processId,
            processDefKey,
            type:'view'
          })
        ).toString("base64");
        //先直接跳转安环侧流程详情页面，因为平台侧页面跳转会出现导航，安环系统已经去除导航
        let sUrl = window.location.origin + '#/process/external/iframe/detail?p=' + param + '&isNewWindow=true';
        // let sUrl = 'http://localhost:1888/#/process/external/iframe/detail?p=' + param;
        window.open(sUrl, "_blank");
      });
    },
    rowTrackPending(row) {
      if(!row.url)return this.$message.error('跳转地址错误，请检查下地址是否已经配置')
      let path = row.url.split("?")[0];
      if (path == "/#/process/external/iframe/detail") {
        window.open(row.url, "_blank");
      } else {
        this.$router.push(row.url);
      }
      // const {taskId, processInstanceId} = row;
      // detail({taskId, processInsId: processInstanceId}).then((res) => {
      //   const {process, flow} = res.data.data;
      //   const { id, taskId, processInstanceId, processId, processDefKey } =
      //     process;
      //   let param = Buffer.from(
      //     JSON.stringify({
      //       processId: id,
      //       taskId,
      //       processInsId: processInstanceId || processId,
      //       processDefKey,
      //     })
      //   ).toString("base64");
      //   //先直接跳转安环侧流程详情页面，因为平台侧页面跳转会出现导航，安环系统已经去除导航
      //   if(row.cilckFlag){
      //     let sUrl = window.location.origin + '#/process/external/iframe/detail?p=' + param + '&isNewWindow=true&isSelectUser=1';
      //     // let sUrl = 'http://localhost:1888/#/process/external/iframe/detail?p=' + param + '&isNewWindow=true';
      //     window.open(sUrl, "_blank");
      //   }else {
      //     let sUrl = window.location.origin + '#/process/external/iframe/detail?p=' + param + '&isNewWindow=true';
      //     // let sUrl = 'http://localhost:1888/#/process/external/iframe/detail?p=' + param + '&isNewWindow=true';
      //     window.open(sUrl, "_blank");
      //   }
      // });
    },
    handleFlow(row) {
      const {taskId, processInstanceId} = row
      detail({taskId, processInsId: processInstanceId}).then(res => {
        const {process, flow} = res.data.data

        this.bpmnOption = {
          mode: 'view',
          xml: process.xml,
          flows: this.handleResolveFlows(flow),
          lang: this.language
        }

        this.bpmnVisible = true
      })
    },
    handleDetail(row) {

      const param = {
        taskId: row.taskId,
        processInsId: row.processInstanceId
      }
      this.$router.push('/workflow/process/detail/' + Buffer.from(JSON.stringify(param)).toString('base64'))
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.loading = true;
      getList(this.page.currentPage, this.page.pageSize, Object.assign(params,{ownerName:this.userInfo.account,status:this.activeName == 'second' ?0 :1}, this.searchForm)).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.data = data.records;
        this.loading = false;
      });
    }
  },
  // 初始化数据 add by steve
  mounted() {
    this.onLoad(this.page, {});
    window.addEventListener('storage', (e) => {
      if (e.key === 'sinoma-refresh') {
        this.onLoad(this.page); //刷新列表
        localStorage.setItem('sinoma-refresh', JSON.stringify(false)) //变成false , 可以来回监听
        //要做的操作
      }
    })
  },
};
</script>

<style lang="scss" scoped>
::v-deep .bjs-powered-by {
  display: none;
}
::v-deep .el-tabs__content{
  display: none;
}
::v-deep .el-tabs--border-card{
  border-bottom: 0;
}
.center {
  padding: 0 !important;
}

.wf-container {
  .el-collapse-item__content {
    padding-top: 8px;
  }

  .el-badge__content.is-fixed {
    right: 16px;
  }
}

.wf-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);

  .el-dialog__body {
    flex: 1;
    overflow: auto;
  }
}

::v-deep .avue-crud .el-table {
  height: calc(100vh - 230px) !important;
  max-height: calc(100vh - 230px) !important;
}
</style>
