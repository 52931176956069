<template>
  <div>
    <el-container>

      <el-main>
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="$t(`cip.index.app.category.indexView`)"
          @head-remove="headRemove"
          @head-add-tabs="headShowLine"
        ></head-layout>
        <viewComponent ref="viewComponent" :sys-category-id="sysCategoryId"></viewComponent>
      </el-main>
    </el-container>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import CommonTree from "@/views/components/com_tree/index"
import viewComponent from "@/views/business/index/appCategory/common/viewComponent";
import ServerNameEnum from '@/util/ServerNameEnum';
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {
  tree,
  removeTree,
  saveOrUpdateTree,
  checkCategoryCodeExits,
  removeCategoryWithIndex
} from "@/api/index/appSystemCategory";
import addIndexManageWithCategoryWindow
  from "@/views/business/index/appCategory/common/addIndexManageWithCategoryWindow.vue";

const CONTROLLER_NAME = "/appSystemCategory";

export default {
  name: "index",
  props:{
    dataFormExt:{},
  },
  data() {
    return {
      modeoLable: this.$t(`cip.index.omission.convert`)+this.$t(`cip.index.omission.simulatedData`),//'转换模拟数据',
      page: {
        currentPage: 1,
        dataTotal: 0,
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
      },
      form: {
        parentId: 0
      },
      treeReadOnlyFlag: false,
      tableData: [],
      drawerBox: false,
      treeLoading: false,
      tableLoading: false,
      treeData: [],
      sysCategoryId: '', // 点击左侧树的code
      node: {},   //左侧树node
      fullscreen: false,
      showTreeDialog: false,
      searchTitle: 'title',
      defaultProps: {
        label: "categoryName",
        value: "key",
        key: "categoryCode"
      },

    };
  },
  computed: {
    ...mapGetters(["permission"]),
    headBtnOptions() {
      return [
        {
          label: this.$t(`cip.index.omission.convert`)+this.$t(`cip.index.omission.lineChart`),//'转换折线图',
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'zhexiantu',
        },
        {
          label: this.modeoLable,
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'momishuju',
        }
      ]
    },
    treeDialogOption() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: this.$t("cip.index.app.category.parentCode"),
            prop: "parentId",
            type: "tree",
            props: {
              label: 'categoryName',
              value: 'id',
              key: 'parentId',
            },
            placeholder: this.$t(`cip.index.app.category.parentCode`),
            dicUrl: `${ServerNameEnum.SERVER_INDEX_}/${CONTROLLER_NAME}/tree`,
          },
          {
            label: this.$t(`cip.index.app.category.categoryCode`),
            prop: "categoryCode",
            maxlength: 20,
            readonly: this.treeReadOnlyFlag,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.index.app.category.categoryCode`),
                trigger: "blur",
              },
            ],
          },
          {
            label: this.$t(`cip.index.app.category.categoryName`),
            prop: "categoryName",
            maxlength: 20,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.index.app.category.categoryName`),
                trigger: "blur",
              },
            ],
          },
        ]
      }
    },
  },
  components: {
    addIndexManageWithCategoryWindow,
    GridLayout,
    HeadLayout,
    CommonTree,
    viewComponent
  },
  created() {

  },
  methods: {
    headShowLine(){
      this.$refs.viewComponent.headShowLine();
    },
    saveExt() {
      this.$refs.queryIndexManageWithCategory.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {sysCategoryId: this.sysCategoryId});
    },
    //新增主题
    headAdd() {
      this.$refs.addIndexManageWithCategoryWindow.openDialog(this.sysCategoryId);
    },
    headRemove() {
      let isMode=null
      if(this.modeoLable.indexOf(this.$t(`cip.index.omission.convert`)+this.$t(`cip.index.omission.simulatedData`))==-1){
        isMode=false
        this.modeoLable=this.$t(`cip.index.omission.convert`)+this.$t(`cip.index.omission.simulatedData`)//'转换模拟数据'
      }else {
        this.modeoLable=this.$t(`cip.index.omission.convert`)+this.$t(`cip.index.omission.originalData`)//'转换实际数据'
        isMode=true
      }
      this.$refs.viewComponent.onLoad(  {
        currentPage: 1,
          dataTotal: 0,
          pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
      },{},isMode);
    },
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },





    close() {
      this.drawerBox = false;
      this.page.currentPage = 1;
      this.$refs.queryIndexManageWithCategory.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {sysCategoryId: this.sysCategoryId});
    },
  },
};
</script>


