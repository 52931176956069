<template>
  <div class="center">
    <!--列表label-->
    <head-layout :head-title="$t(`cip.desk.workflow.title.indexHeadDone`)"></head-layout>
    <!--列表查询条件和头部按钮-->
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"></grid-head-layout>
    <grid-layout ref="gridLayOut"
                 :data-total="page.total"
                 :grid-row-btn="gridRowBtn"
                 :page="page"
                 :table-data="data"
                 :table-loading="loading"
                 :table-options="option"
                 @grid-row-detail-click="rowView"
                 @page-current-change="onLoad"
                 @page-size-change="onLoad"
                 @page-refresh-change="onLoad"
                 @row-detail="rowTrackPending"
                 @row-flow="handleFlow"></grid-layout>
    <!--  <basic-container>
    <avue-crud :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               :permission="permissionList"
               v-model="form"
               @search-change="searchChange"
               @search-reset="searchReset"
               @selection-change="selectionChange"
               @current-change="currentChange"
               @size-change="sizeChange"
               @refresh-change="onLoad(page, query)"
               @on-load="onLoad">
      <template slot="menu"
                slot-scope="{row}">
        <el-button v-if="permission.wf_process_done_detail"
                   type="text"
                   size="small"
                   icon="el-icon-info"
                   @click="dynamicRoute(row, 'detail')">详情</el-button>
        <el-button v-if="permission.wf_process_done_follow"
                   type="text"
                   size="small"
                   icon="el-icon-search"
                   @click="handleFlow(row)">流程图</el-button>
      </template>
    </avue-crud>

    <el-dialog :visible.sync="bpmnVisible"
               append-to-body
               destroy-on-close
               title="流程图"
               width="70%"
               custom-class="wf-dialog">
      <wf-design ref="bpmn"
                 style="height: 60vh;"
                 :options="bpmnOption"></wf-design>
    </el-dialog>
  </basic-container>-->
    <common-dialog
      v-if="bpmnVisible"
      width="70%"
      customClass="wf-dialog"
      :dialogTitle="$t(`cip.desk.workflow.title.indexHeadVisible`)"
      :showBtn="false"
      @cancel="bpmnVisible = false">
      <wf-design ref="bpmn"
                 style="height: 60vh;"
                 :options="bpmnOption"></wf-design>
    </common-dialog>
  </div>
</template>

<script>
import CommonDialog from "@/components/CommonDialog";
import {detail, doneList as getList} from "@/api/plugin/workflow/process";
import {mapGetters} from "vuex";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import exForm from '../mixins/ex-form'
import website from "@/config/website";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

export default {
  mixins: [exForm],
  components: {HeadLayout, GridLayout, CommonDialog },
  data() {
    return {
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        index: true,
        indexLabel: "序号",
        selection: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        linklabel: "processDefinitionName",
        column: [
          {
            label: this.$t('cip.desk.workflow.field.nameOfTheProcess'),
            prop: 'processDefinitionName',
            overHidden: true,
            align: 'left',
          },
          {
            label: this.$t('cip.desk.workflow.field.category'),
            type: "tree",
            align: 'center',
            overHidden: true,
            dicUrl: '/api/sinoma-workflow/design/category/tree',
            props: {
              label: 'name',
              value: 'id'
            },
            prop: "category",
          },
          {
            label: this.$t('cip.desk.workflow.field.TheCurrentNode'),
            prop: 'taskName',
            overHidden: true,
            align: 'center',
          },
          {
            label: this.$t('cip.desk.workflow.field.applicant'),
            prop: 'startUsername',
            overHidden: true,
            align: 'center',
          },
          {
            label: this.$t('cip.desk.workflow.field.ApplicationTime'),
            prop: 'createTime',
            align: 'center',
            type: 'datetime',
            format: 'yyyy-MM-dd HH:mm',
            overHidden: true,
          },
          {
            label: this.$t('cip.desk.workflow.field.ProcessStatus'),
            prop: 'processIsFinished',
            align: 'center',
            overHidden: true,
            dicData: [{
              label: this.$t('cip.desk.workflow.msg.finished'),
              value: 'finished'
            }, {
              label: this.$t('cip.desk.workflow.msg.terminate'),
              value: 'terminate'
            }, {
              label: this.$t('cip.desk.workflow.msg.withdraw'),
              value: 'withdraw'
            }, {
              label: this.$t('cip.desk.workflow.msg.deleted'),
              value: 'deleted'
            }],
          },
        ]
      },
      data: [],
      bpmnVisible: false,
      bpmnOption: {}
    };
  },
  computed: {
    ...mapGetters(["permission","language"]),
    refresh() {
      return this.$store.state.common.refresh
    },
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.deployment_add, false),
        viewBtn: this.vaildData(this.permission.deployment_view, false),
        delBtn: this.vaildData(this.permission.deployment_delete, false),
        editBtn: this.vaildData(this.permission.deployment_edit, false)
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },

    searchColumns() {
      return [
        {
          label: this.$t('cip.desk.workflow.field.nameOfTheProcess'),
          prop: "processDefinitionName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.desk.workflow.field.nameOfTheProcess'),
        },
        {
          label: this.$t('cip.desk.workflow.field.ProcessIdentification'),
          prop: "processDefinitionKey",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.desk.workflow.field.ProcessIdentification'),
        },
      ]
    },
    // 行按钮添加 add by steve
    gridRowBtn() {
      return [{
        label: this.$t('cip.desk.workflow.btn.detailBtn'),
        emit: "row-detail",
        type: "primary",
        icon: "el-icon-plus",
      },
        {
          label: this.$t('cip.desk.workflow.btn.flowBtn'),
          emit: "row-flow",
          type: "primary",
          icon: "el-icon-plus",
        }]
    },
  },
  methods: {
    // 列表查询 add by steve
    gridHeadSearch(searchForm) {
      this.onLoad(this.page, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty(searchForm) {
      this.onLoad(this.page, searchForm);
    },
    rowEdit(row) {
      this.dynamicRoute(row, 'detail')
    },
    rowView(row) {
      const {taskId, processInstanceId} = row;
      detail({taskId, processInsId: processInstanceId}).then((res) => {
        const {process, flow} = res.data.data;
        const { id, taskId, processInstanceId, processId, processDefKey } =
          process;
        let param = Buffer.from(
          JSON.stringify({
            processId: id,
            taskId,
            processInsId: processInstanceId || processId,
            processDefKey,
            type:'view'
          })
        ).toString("base64");
        //先直接跳转安环侧流程详情页面，因为平台侧页面跳转会出现导航，安环系统已经去除导航
        let sUrl = window.location.origin + '#/process/external/iframe/detail?p=' + param + '&isNewWindow=true';
        // let sUrl = 'http://localhost:1888/#/process/external/iframe/detail?p=' + param;
        window.open(sUrl, "_blank");
      });
    },
    rowTrackPending(row) {
      const {taskId, processInstanceId} = row;
      detail({taskId, processInsId: processInstanceId}).then((res) => {
        const {process, flow} = res.data.data;
        const { id, taskId, processInstanceId, processId, processDefKey } =
          process;
        let param = Buffer.from(
          JSON.stringify({
            processId: id,
            taskId,
            processInsId: processInstanceId || processId,
            processDefKey,
          })
        ).toString("base64");
        //先直接跳转安环侧流程详情页面，因为平台侧页面跳转会出现导航，安环系统已经去除导航
        let sUrl = window.location.origin + '#/process/external/iframe/detail?p=' + param + '&isNewWindow=true';
        // let sUrl = 'http://localhost:1888/#/process/external/iframe/detail?p=' + param;
        window.open(sUrl, "_blank");
      });
    },
    handleDetail(row) {
      const param = {
        taskId: row.taskId,
        processInsId: row.processInstanceId
      }
      this.$router.push('/workflow/process/detail/' + Buffer.from(JSON.stringify(param)).toString('base64'))
    },
    handleFlow(row) {
      const {taskId, processInstanceId} = row
      detail({taskId, processInsId: processInstanceId}).then(res => {
        const {process, flow} = res.data.data

        this.bpmnOption = {
          mode: 'view',
          xml: process.xml,
          flows: this.handleResolveFlows(flow),
          lang: this.language
        }

        this.bpmnVisible = true
      })
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.loading = true;
      getList(page.currentPage, page.pageSize, Object.assign(params, this.$refs.gridHeadLayout.searchForm)).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.data = data.records;
        this.loading = false;
      });
    }
  },
  // 初始化数据 add by steve
  mounted() {
    this.onLoad(this.page, {});
    window.addEventListener('storage', (e) => {
      if (e.key === 'sinoma-refresh') {
        this.onLoad(this.page); //刷新列表
        localStorage.setItem('sinoma-refresh', JSON.stringify(false)) //变成false , 可以来回监听
        //要做的操作
      }
    })
  },
};
</script>

<style lang="scss" scoped>
::v-deep .bjs-powered-by {
  display: none;
}

.center {
  padding: 0 !important;
}

::v-deep .avue-crud .el-table {
  height: calc(100vh - 330px) !important;
  max-height: calc(100vh - 330px) !important;
}
</style>
