<template>
  <div>
      <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
        <el-col :span="12">
          <el-form-item label-width="120px" label="signName" prop="signName">
            <el-input v-model="formData.signName" :placeholder="$t('cip.plat.message.channel.msg.signName')" show-word-limit clearable
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="120px" label="accessKeyId" prop="accessKeyId">
            <el-input v-model="formData.accessKeyId" :placeholder="$t('cip.plat.message.channel.msg.accessKeyId')" clearable
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="120px" label="accessKeySecret" prop="accessKeySecret">
            <el-input v-model="formData.accessKeySecret" :placeholder="$t('cip.plat.message.channel.msg.accessKeySecret')" clearable
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="120px" label="templateCode" prop="templateCode">
            <el-input v-model="formData.templateCode" :placeholder="$t('cip.plat.message.channel.msg.templateCode')" clearable
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
  </div>
</template>
<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      formData: {
        signName: "",
        accessKeyId: "",
        accessKeySecret:"",
        templateCode: "",
      },
      rules: {
        signName: [{
          required: true,
          message: this.$t('cip.plat.message.channel.msg.signName'),
          trigger: 'blur'
        }],
        accessKeyId: [{
          required: true,
          message: this.$t('cip.plat.message.channel.msg.accessKeyId'),
          trigger: 'blur'
        }],
        accessKeySecret: [{
          required: true,
          message: this.$t('cip.plat.message.channel.msg.accessKeySecret'),
          trigger: 'blur'
        }],
        templateCode: [{
          required: true,
          message: this.$t('cip.plat.message.channel.msg.templateCode'),
          trigger: 'blur'
        }],
      },
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        // TODO 提交表单
      })
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
    },
  }
}

</script>
<style>
</style>
