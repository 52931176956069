<template>
  <div style="background-color:#fff;height:100%">
    <!--    <basic-container>-->
    <!--      <avue-form :option="option"-->
    <!--                 v-model="form"-->
    <!--                 ref="userInfo"-->
    <!--                 @tab-click="handleTabClick"-->
    <!--                 @submit="handleSubmit"></avue-form>-->
    <!--    </basic-container>-->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <!--      <el-tab-pane :label="this.$t('userinfo.personalDetails')" name="first">-->
      <!--        <avue-form :option="option"-->
      <!--                   v-model="form"-->
      <!--                   ref="userInfo"-->
      <!--                   @tab-click="handleTabClick"-->
      <!--                   @submit="handleSubmit0">-->
      <!--          <template slot="menuForm">-->
      <!--            <el-button style="width: 80px;height: 30px;"  size="small" :loading="btnLoading" class="saveBtn"-->
      <!--                           @click="close()">{{ this.$t('sinoma.btn.close') }}</el-button>-->
      <!--          </template>-->
      <!--        </avue-form>-->
      <!--      </el-tab-pane>-->


      <el-tab-pane :label="this.$t('userinfo.personalDetails')" name="first">
        <el-form ref="dataForm" :model="dataForm" :rules="rules" label-width="100px" style="background-color:#fff;">
          <el-row :gutter="20">
            <el-col :span="24">
              <form-layout
                ref="formLayout"
                :column="column"
                :dataForm.sync="dataForm"
              ></form-layout>
              <el-form-item label="电子签名:" label-width="115px">
                <el-button type="primary" @click="handleClear">清空</el-button>
                <img v-if="dataForm.electronicSignature != ''" :src="dataForm.electronicSignature" class="electronicSignatureImg">
                <canvas v-else style="display: block"/>
              </el-form-item>
              <el-form-item style="display: flex;align-items: center;justify-content: center;">
                <el-button style="width: 80px;height: 30px;" type="primary" size="small" :loading="btnLoading"
                           class="saveBtn"
                           @click="submitDataForm()">{{ this.$t('userinfo.save') }}
                </el-button>
                <el-button style="width: 80px;height: 30px;" size="small" :loading="btnLoading" class="saveBtn"
                           @click="close()">{{ this.$t('cip.cmn.btn.celBtn') }}
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="this.$t('userinfo.editPasswd')" name="second">
        <avue-form :option="option1"
                   v-model="form"
                   ref="userInfo"
                   @tab-click="handleTabClick"
                   @submit="handleSubmit1">
          <template slot="menuForm">
            <el-button style="width: 80px;height: 30px;" size="small" :loading="btnLoading" class="saveBtn"
                       @click="close()">{{ this.$t('cip.cmn.btn.celBtn') }}
            </el-button>
          </template>
        </avue-form>
      </el-tab-pane>
      <el-tab-pane v-if="userInfo.role_name.includes('administrator')" :label="this.$t('userinfo.sysConfig')"
                   name="san">
        <el-form ref="baseForm" :model="baseForm" :rules="rules" label-width="100px" style="background-color:#fff;">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item :label="this.$t('userinfo.sysIcon')">
                <div class="img-list">
                  <single-img v-model="baseForm.loginIcon" :tip="this.$t('userinfo.loginIcon')"/>
                  <single-img v-model="baseForm.navigationIcon" :tip="this.$t('userinfo.NavigationIcon')"/>
                  <single-img v-model="baseForm.logoIcon" :tip="this.$t('userinfo.LOGOIcon')"/>
                  <single-img v-model="baseForm.appIcon" :tip="this.$t('userinfo.AppIcon')"/>
                  <single-img v-model="baseForm.background" :tip="this.$t('userinfo.LoginBJ')"/>
                </div>
              </el-form-item>
              <form-layout
                ref="formLayout"
                :column="formColumn"
                :dataForm.sync="baseForm"
              >
              </form-layout>
              <el-form-item style="display: flex;align-items: center;justify-content: center;">
                <el-button style="width: 80px;height: 30px;" type="primary" size="small" :loading="btnLoading"
                           class="saveBtn"
                           @click="submitForm()">{{ this.$t('userinfo.save') }}
                </el-button>
                <el-button style="width: 80px;height: 30px;" size="small" :loading="btnLoading" class="saveBtn"
                           @click="close()">{{ this.$t('cip.cmn.btn.celBtn') }}
                </el-button>
              </el-form-item>

            </el-col>

          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import singleImg from '@/components/SingleImg'
import {getUserInfo, updateInfo, updatePassword, saveSysConfig, getSysConfigToUserInfo,putFile} from "@/api/system/user";
import md5 from 'js-md5';
import func from "@/util/func";
import ServerNameEnum from "@/util/ServerNameEnum";
import {mapGetters} from "vuex";
import SmoothSignature from "smooth-signature";

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='

export default {
  components: {singleImg},
  computed: {
    ...mapGetters(['userInfo', 'language'])
  },
  data() {
    return {
      formColumn: [
        {
          label: this.$t('userinfo.SysName'),
          prop: 'sysName',
          span: 12,
          isGlobal: true
        },
        {
          label: this.$t('userinfo.title'),
          prop: 'title',
          span: 12,
          isGlobal: true,
        },
        {
          label: this.$t('userinfo.Sysedition'),
          prop: 'sysVersion',
          span: 12,
          isGlobal: true
        },
        {
          label: this.$t('userinfo.companyName'),
          prop: 'companyName',
          span: 12,
          isGlobal: true
        },
        {
          label: this.$t('userinfo.copyright'),
          prop: 'copyright',
          span: 12,
          isGlobal: true
        },
        {
          label: this.$t('userinfo.companyCode'),
          prop: 'companyCode',
          span: 12,
          isGlobal: true
        },
        {
          label: this.$t('userinfo.companyAddress'),
          prop: 'companyAddress',
          span: 12,
          isGlobal: true
        },
        {
          label: this.$t('userinfo.companyContacts'),
          prop: 'companyContacts',
          span: 12,
          isGlobal: true
        },
        {
          label: this.$t('userinfo.companyTelePhone'),
          prop: 'companyTelePhone',
          span: 12,
          isGlobal: true
        },
        {
          label: this.$t('userinfo.companyEmail'),
          prop: 'companyEmail',
          span: 12,
          isGlobal: true
        },
        {
          label: this.$t('userinfo.weakPassword'),
          prop: 'weakPassword',
          span: 12,
          type: 'switch',
          dataType: 'string',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=WeakPassword_Switch",
        },
        {
          label: this.$t('userinfo.isTenant'),
          prop: 'isTenant',
          span: 12,
          type: 'switch',
          dataType: 'string',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_closeTenant",
        },
        {
          label: this.$t('userinfo.defaultConfig'),
          prop: 'defaultConfigId',
          span: 12,
          type: 'select',
          props: {
            label: "configName",
            value: "id"
          },
          clearable: false,
          dicUrl: "/api/sinoma-system/sys/defaultConfig/getAll",
          placeholder: this.$t(`cip.cmn.rule.selectWarning`) + this.$t('userinfo.defaultConfig'),
        },
        {
          label: this.$t('userinfo.sysDescription'),
          prop: 'sysDescription',
          span: 24,
          type: 'textarea'

        },

      ],
      activeName: 'first',
      btnLoading: false,
      baseForm: {
        weakPassword: '',
        loginIcon: '',
        sysName: '',
        sysVersion: '',
        navigationIcon: '',
        companyName: '',
        copyright: '',
        companyCode: '',
        companyContacts: '',
        companyTelePhone: '',
        companyEmail: '',
        sysDescription: '',
        companyAddress: '',
        logoIcon: '',
        appIcon: '',
        background: '',
        title: '',
        isTenant: '',
        defaultConfigId: '',
      },
      dataForm: {
        id: "",
        avatar: "",
        realName: "",
        phone: "",
        email: "",
        electronicSignature:""
      },
      column: [
        {
          label: this.$t('userinfo.avatar'),
          type: 'upload',
          listType: 'picture-img',
          propsHttp: {
            res: 'data',
            url: 'link',
          },
          canvasOption: {
            text: ' ',
            ratio: 0.1
          },
          action: ServerNameEnum.SERVER_RESOURCE_ + '/oss/endpoint/put-file',
          tip: this.$t('userinfo.userinfotip'),
          span: 12,
          row: true,
          prop: 'avatar'
        },
        {
          label: this.$t('userinfo.name'),
          span: 12,
          row: true,
          prop: 'realName',
          isGlobal: true,
          rules: [{
            required: true,
            validator: this.validateRealName,
            trigger: ["blur", "change"]
          }]
        },
        // {
        //   label: this.$t('userinfo.username'),
        //   span: 12,
        //   row: true,
        //   prop: 'name'
        // },
        {
          label: this.$t('userinfo.phone'),
          span: 12,
          row: true,
          prop: 'phone'
        }, {
          label: this.$t('userinfo.email'),
          prop: 'email',
          span: 12,
          row: true,
        },
        //   ]
        // },
      ],

      index: 0,
      option1: {
        emptyBtn: false,
        // column:[
        //   {
        //     label: this.$t('userinfo.editPasswd'),
        //     prop: 'password',
        column: [{
          label: this.$t('userinfo.oldpasswd'),
          span: 12,
          row: true,
          type: 'password',
          prop: 'oldPassword'
        }, {
          label: this.$t('userinfo.newPasswd'),
          span: 12,
          row: true,
          maxlength: 30,
          type: 'password',
          prop: 'newPassword',
          rules: [
            {
              pattern: /^[^\s]*$/,
              message: this.$t("cip.cmn.rule.removeSpacesWarning"),
            },
          ],
        }, {
          label: this.$t('userinfo.confirmPasswd'),
          span: 12,
          row: true,
          type: 'password',
          prop: 'newPassword1',
        }]
        //   }
        // ]
      },
      option: {
        emptyBtn: false,
        //  group: [
        // {
        //   label: this.$t('userinfo.personalDetails'),
        //   prop: 'info',
        column: [],
      },
      form: {}
    };
  },
  created() {
    this.handleWitch();
    this.getSysConfig();
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const canvas = document.querySelector("canvas");
      const options = {
        width: Math.min(window.innerWidth, 1000),
        height: 600,
        minWidth: 4,
        maxWidth: 12,
        // color: '#1890ff',
        bgColor: '#f6f6f6'
      };
      this.signature = new SmoothSignature(canvas, options);
    },
    handleClear() {
      if (this.dataForm.electronicSignature != '') {
        this.dataForm.electronicSignature = '';
        setTimeout(() =>{
          this.init();
        },500)
      }
      this.signature.clear();
    },
    validateRealName(rule, value, callback) {
      if (value != '') {
        let val = JSON.parse(value)
        let language = this.language
        let val2 = val[language]
        if (val2.substring(0, 1) == " " || val2.substring(val2.length - 1) == " ") {
          callback(new Error(this.$t("cip.cmn.rule.deleteWarning")));
        }
        if (language == "zh") {
          if (val2.length < 2 || val2.length > 5) {
            callback(new Error(this.$t("cip.plat.sys.user.msg.nameLength")));
          } else {
            callback();
          }
        } else {
          if (val2.length < 2 || val2.length > 20) {
            callback(new Error(this.$t("cip.plat.sys.user.msg.nameLength")));
          } else {
            callback();
          }
        }
      } else {
        callback(new Error(this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.realName")));
      }
    },
    getSysConfig() {
      getSysConfigToUserInfo().then(res => {
        let data = res.data.data
        this.baseForm.loginIcon = data.login_icon
        this.baseForm.sysName = data.sys_name
        this.baseForm.sysVersion = data.sys_edition
        this.baseForm.navigationIcon = data.navigation_icon
        this.baseForm.companyName = data.company_name
        this.baseForm.copyright = data.copyright
        this.baseForm.companyCode = data.company_code
        this.baseForm.companyContacts = data.company_contacts
        this.baseForm.companyTelePhone = data.company_telephone
        this.baseForm.companyEmail = data.company_email
        this.baseForm.sysDescription = data.sys_description
        this.baseForm.companyAddress = data.company_address
        this.baseForm.logoIcon = data.logo_icon
        this.baseForm.appIcon = data.app_icon
        this.baseForm.title = data.title
        this.baseForm.background = data.background
        this.baseForm.weakPassword = data.weak_password == "true"
        this.baseForm.isTenant = data.is_tenant == "true"
        this.baseForm.defaultConfigId = data.default_config_id
      })
    },
    clearForm() {
      this.baseForm = {}
    },
    submitForm() {
      saveSysConfig(this.baseForm).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
        }
      })
    },
    validatorPwd(rule, value, callback) {
      if (value !== this.$refs.userInfo.form.newPassword) {
        callback(new Error(this.$t('userinfo.differentPassword')))
      }
    },
    submitDataForm() {
      const isEmpty = this.signature.isEmpty();
      if (isEmpty) {
        updateInfo(this.dataForm).then(res => {
          this.dataForm = res.data.data
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
          this.handleWitch();
        }, error => {
          window.console.log(error);
        })
      } else {
        const pngUrl = this.signature.getPNG();
        let file = this.base64toFile(pngUrl,'electronicSignature' + this.userInfo.user_id)
        const formData = new FormData()
        formData.append('file', file);
        console.log(file,'452')
        putFile(formData).then((res) =>{
          console.log(res,'454');
          this.dataForm.electronicSignature = res.data.data.link;
          updateInfo(this.dataForm).then(res => {
            this.dataForm = res.data.data
            this.$message({
              type: "success",
              message: this.$t('cip.cmn.msg.success.operateSuccess')
            });
            this.handleWitch();
          }, error => {
            window.console.log(error);
          })
        })
      }
    },
    base64toFile(urlString, fileName) {
      const dataArr = urlString.split(",");
      const byteString = atob(dataArr[1]);
      const options = {
        type: "image/png",
        endings: "native"
      };
      const u8Arr = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        u8Arr[i] = byteString.charCodeAt(i);
      }
      return new File([u8Arr], fileName + ".png", options);//返回文件流
    },
    handleSubmit1(form, done) {
      if (form.oldPassword == null || form.oldPassword == '' || form.oldPassword == undefined) {
        this.$message.warning(this.$t('userinfo.oldPasswordNotNull'))
        done()
        return false
      }

      if (form.newPassword == null || form.newPassword == '' || form.newPassword == undefined) {
        this.$message.warning(this.$t('userinfo.newPasswordNotNull'))
        done()
        return false
      }

      if (form.newPassword != form.newPassword1) {
        this.$message.warning(this.$t('userinfo.differentPassword'))
        done()
        return false
      }

      //开启弱口令，为true不进入判断
      if (!this.baseForm.weakPassword) {
        var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}');
        if (!pwdRegex.test(form.newPassword)) {
          this.$message.warning(this.$t('userinfo.passwdMsg'))
          done()
          return false
        }
        return false
      }

      this.$refs.userInfo.validate(valid => {
        if (valid) {
          updatePassword(md5(form.oldPassword), md5(form.newPassword), md5(form.newPassword1)).then(res => {
            if (res.data.success) {
              this.$message({
                type: "success",
                message: this.$t('cip.plat.sys.user.msg.updatePassword')
              });
            } else {
              this.$message({
                type: "error",
                message: res.data.msg
              });
            }
            done();
          }, error => {
            window.console.log(error);
            done();
          })
        }
      })
    },
    close() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    handleWitch() {
      if (this.index === 0) {
        getUserInfo().then(res => {
          const user = res.data.data;
          this.dataForm.id = user.id;
          this.dataForm.avatar = user.avatar;
          // name: user.name,
          this.dataForm.realName = user.realName;
          this.dataForm.phone = user.phone;
          this.dataForm.email = user.email;
          this.dataForm.electronicSignature = user.electronicSignature;
          const data = res.data.data;
          data.realName = JSON.parse(data.realName);
          this.$store.commit("SET_CURRENT_USER", data);
        });
      }
    },
    handleTabClick(tabs) {
      this.index = func.toInt(tabs.index);
      this.handleWitch();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__content {
  padding: 0px !important;
}

.img-list {
  display: flex;

  ::v-deep .singleImg-container {
    margin-right: 20px;

    :last-child {
      margin-right: 0;
    }
  }
}

.saveBtn {
  width: 220px;
}

::v-deep .avue-upload__icon {
  line-height: 178px !important;
}

.electronicSignatureImg {
  width: 1000px;
  height: 600px;
  display: block;
}
</style>
