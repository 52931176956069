<template>
  <div>
    <head-layout
      :head-title="this.$t('cip.index.atomIndex.composite')+this.$t('cip.index.atomIndex.drillDown')+`[${compositeParams['time']}]`"
    ></head-layout>
    <grid-layout
      ref="gridLayout"
      :page="page"
      :table-options="tableOptions"
      :table-data.sync="tableData"
      :table-loading="tableLoading"
      @tree-load="treeLoad"
    >
    </grid-layout>
  </div>
</template>

<script>
import gridLayout from "@/views/components/layout/grid-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";

import { lazyTreeCompositeIndexData } from "@/api/index/indexManage";

export default {
  name: "compositeIndexTable",
  components: {
    gridLayout,
  },
  props: {
    compositeParams: {
      default: {}
    }
  },
  data() {
    return {
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      tableOptions: {
        lazy: true,
        rowKey: 'rowKey',
        menu: false,
        selection: false,
        header: false,
        column: [
          {
            label: '指标编码',
            prop: 'indexCode',
          },
          {
            label: '_value',
            prop: '_value',
          },
          {
            label: '_simulation_value',
            prop: '_simulation_value',
          }
        ]
      },
      tableData: [],
      tableLoading: false,
    }
  },
  methods: {
    treeLoad(tree, treeNode, resolve) {
      lazyTreeCompositeIndexData({
        indexCode: tree.indexCode,
        time: this.compositeParams.time,
      }).then(res => {
        const { data } = res.data;
        resolve(data)
      })
    },
    onLoad() {
      this.tableLoading = true
      lazyTreeCompositeIndexData({
        indexCode: this.compositeParams.indexCode,
        time: this.compositeParams.time,
      }).then(res => {
        const { data } = res.data;
        this.tableData.splice(0, this.tableData.length)
        this.tableData = data;
        this.tableLoading = false
      })
    }
  },
}
</script>

<style scoped>

</style>
