<template>
  <div>
      <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="130px" style="padding:0 15px">
        <el-col :span="12">
          <el-form-item label-width="130px" label="企业ID:" prop="corpId">
            <el-input v-model="formData.corpId" placeholder="请输入企业ID" clearable
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="130px" label="Secret:" prop="corpSecret">
            <el-input v-model="formData.corpSecret" placeholder="请输入秘钥" clearable
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="130px" label="AgentId:" prop="agentId">
            <el-input v-model="formData.agentId" placeholder="请输入应用ID" clearable
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
  </div>
</template>
<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      formData: {
        corpId: "",
        corpSecret:"",
        agentId: "",
      },
      rules: {
        corpId: [{
          required: true,
          message: '请输入企业ID',
          trigger: 'blur'
        }],
        corpSecret: [{
          required: true,
          message: '请输入秘钥',
          trigger: 'blur'
        }],
        agentId: [{
          required: true,
          message: '请输入应用ID',
          trigger: 'blur'
        }],
      },
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {},
  methods: {
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        // TODO 提交表单
      })
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
    },
  }
}

</script>
<style>
</style>
