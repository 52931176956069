<!--
 * @Author: absorbedyy@163.com
 * @Date: 2024-05-21 13:33:37
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-06-12 17:02:47
 * @Description: 
-->
<template>
  <div>
    <el-dialog
      title="安全组织选择"
      v-dialogDrag
      :visible.sync="dialogVisible"
      :before-close="handleCancel"
      width="40%"
    >
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
      >
        <template #customBtn="{ row }">
          <el-button
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="rowSelect(row)"
            >选择
          </el-button>
        </template>
      </grid-layout>
      <template slot="footer">
        <el-button size="small" @click="handleCancel" v-loading="dialogLoading">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import { getOrgList, getPersonPage } from "@/api/responsibility/safetyOrg";
import { mapGetters } from "vuex";
export default {
  components: {
    GridLayout,
  },
  data() {
    return {
      dialogVisible: false,
      dialogLoading: false,
      tableLoading: true,

      selectionList: [],
      tableData: [],
      tableOption: {
        selection: false,
        column: [
          {
            label: "组织名称",
            prop: "soOrgName",
            align: "center",
            overHidden: true,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    init() {
      this.dialogVisible = true;
      this.getOrgTable();
    },
    getOrgTable() {
      this.tableLoading = true;
      getOrgList({ orgId: this.userInfo.dept_id }).then((res) => {
        if (res.data.code == 200) {
          // console.log("shuju", res.data.data);
          this.tableData = res.data.data;
          this.tableLoading = false;
        }
      });
    },
    // 选择公司
    rowSelect(row) {
      this.$emit("callback", row);
      this.handleCancel();
    },
    handleCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang="scss"></style>
