<template>
  <div>
    <el-container>
      <el-main>
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="$t(`cip.index.intf.title`)"
          @head-remove="headRemove"
          @head-add-tabs="headAdd"
        ></head-layout>
        <div class="headers">
          <grid-head-layout
            ref="gridHeadLayout"
            :grid-head-btn="gridHeadBtn"
            :search-columns="searchColumns"
            @grid-head-search="gridHeadSearch"
            @grid-head-empty="gridHeadEmpty"
          ></grid-head-layout>
        </div>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.dataTotal"
          @gird-handle-select-click="selectionChange"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @grid-row-detail-click="rowView"
          @row-remove="rowRemove"
          @row-view="rowView"
          @row-edit="rowEdit"
        ></grid-layout>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {remove, selectPage} from "@/api/index/appInterface";

export default {
  name: "index",
  data() {
    return {
      page: {
        currentPage: 1,
        dataTotal: 0,
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
      },
      tableData: [],
      tableLoading: false,
      selectionList: [],
      query: {},
      dataForm: {}

    };
  },
  computed: {
    ...mapGetters(["permission"]),
    headBtnOptions() {
      return [
        {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        },
        {
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        }
      ]
    },
    tableOptions() {
      return {
        menu: !this.shouIndexManage,
        linklabel: "indexCode",
        menuWidth: 120,
        column: [
          {
            label: this.$t('cip.index.intf.intfCode'),
            prop: "intfCode",
            search: true,
            width: 250
          },
          {
            label: this.$t('cip.index.intf.intfName'),
            prop: "intfName",
            search: true
          },
          {
            label: this.$t('cip.index.intf.timeDimension'),
            prop: "timeDimension",
            align: 'center',
            width: 90,
            value: 's',
            dataType: 'string',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_time_dimension",
          },
          {
            label: this.$t('cip.index.intf.intfUrl'),
            prop: "intfUrl",
            align: 'center',
            width: 600
          },
        ]
      }
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "intfCode",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.index.intf.intfCode`)
        },
        {
          label: "",
          prop: "intfName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.index.intf.intfName`)
        }
      ]
    },
    gridRowBtn() {
      return [
        {
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: ""
        },
        {
          label: this.$t(`cip.cmn.btn.viewBtn`),
          emit: "row-view",
          type: "button",
          icon: ""
        },
        {
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-remove",
          type: "button",
          icon: ""
        }
      ]
    },
    gridHeadBtn() {
      return []
    },
  },
  components: {
    GridLayout,
    HeadLayout,
  },
  created() {
    this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1});
  },
  methods: {
    gridHeadSearch(query) {
      this.query = query;
      this.onLoad(this.page);
    },
    gridHeadEmpty(query) {
      this.query = query;
      this.onLoad(this.page);
    },
    headAdd() {
      this.$router.push({
        path: `/index/appIntf/edit`,
        query: {
          pageType: 'add'
        }
      })
    },
    headRemove() {
      let selectionList = this.$refs.gridLayOut.selectionList
      let ids = selectionList.filter(e => e.id).map(e => e.id)
      if (selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.rule.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.$func.join(ids))
            .then(() => {
              this.onLoad(this.page);
              this.$refs.gridLayOut.selectionClear();
              this.$message({
                type: "success",
                message: this.$t('cip.cmn.msg.success.operateSuccess')
              });
            });
        }).catch(() => {
      })
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          remove(row.id).then((res) => {
            if (res.data.code === 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              this.onLoad(this.page);
            }
          });
        })
    },
    rowView(row) {
      this.$router.push({
        path: `/index/appIntf/view`,
        query: {
          id: row.id,
          pageType: 'view'
        }
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: `/index/appIntf/edit`,
        query: {
          id: row.id,
          pageType: 'edit'
        }
      })
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    onLoad(page = {pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, params = {}) {
      this.crudLoading = true;
      let connect = selectPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      );
      connect.then((res) => {
        const data = res.data.data;
        this.page.dataTotal = data.total;
        this.tableData = data.records;
        this.crudLoading = false;
      })
        .catch(() => {
          this.crudLoading = true;
        });
    }
  }
};
</script>


