<template>
  <div class="content">
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane :label="$t(`cip.desk.workflow.title.indexHeadTodo`)" name="first">
        <Todo v-if="activeName == 'first'"></Todo>
      </el-tab-pane>
      <el-tab-pane :label="$t(`cip.desk.workflow.title.indexHead1Title`)" name="second">
        <Send v-if="activeName == 'second'"></Send>
      </el-tab-pane>
      <el-tab-pane :label="$t('cip.desk.workflow.title.indexHeadMyDone')" name="third">
        <MyDone v-if="activeName == 'third'"></MyDone>
      </el-tab-pane>
      <el-tab-pane :label="$t(`cip.desk.workflow.title.indexHeadDone`)" name="fourth">
        <Done v-if="activeName == 'fourth'"></Done>
      </el-tab-pane>
      <el-tab-pane label="待签事务" name="fifth">
        <Claim v-if="activeName == 'fifth'"></Claim>
      </el-tab-pane>
      <el-tab-pane :label="$t(`cip.desk.workflow.title.indexHeadCopy`)" name="sixth">
        <Copy v-if="activeName == 'sixth'"></Copy>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import Todo from '@/views/plugin/workflow/process/todo.vue';
import Send from '@/views/plugin/workflow/process/send.vue';
import MyDone from '@/views/plugin/workflow/process/my-done.vue';
import Done from '@/views/plugin/workflow/process/done.vue';
import Claim from '@/views/plugin/workflow/process/claim.vue';
import Copy from '@/views/plugin/workflow/process/copy.vue';

export default {
  components: {
    Todo,
    Send,
    MyDone,
    Done,
    Claim,
    Copy
  },
  data() {
    return {
      activeName: 'first'
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  background-color: #FFFFFF;

  ::v-deep .el-tabs__header {
    margin: 0;
  }
}

::v-deep .avue-crud .el-table {
  height: calc(100vh - 317px) !important;
  max-height: calc(100vh - 317px) !important;
}
</style>
