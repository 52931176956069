<template>
  <div class="center">
    <el-container>
      <wf-category @node-click="nodeClick"
                   @list-change="findObject(option.column, 'categoryId').dicData = $event"></wf-category>
      <el-main>
        <!--列表label-->
        <head-layout :head-btn-options="headBtnOptions"
                     :head-title="$t('cip.plat.wfDesign.deployment.title.indexHeadTitle')"
                     @head-delete="handleChangeCategory"></head-layout>

        <!--列表查询条件和头部按钮-->
        <grid-head-layout ref="gridHeadLayout"
                          :search-columns="searchColumns"
                          @grid-head-search="gridHeadSearch"
                          @grid-head-empty="gridHeadEmpty"></grid-head-layout>
        <grid-layout ref="gridLayOut"
                     :table-options="option"
                     :table-data="data"
                     :table-loading="loading"
                     :data-total="page.total"
                     :grid-row-btn="gridRowBtn"
                     :page="page"
                     @page-current-change="onLoad"
                     @page-size-change="onLoad"
                     @page-refresh-change="onLoad"
                     @gird-handle-select-click="selectionChange"
                     @row-sj="handleChangeStatus"
                     @row-bs="handleChangeStatus"
                     @row-qx="handleCategory"></grid-layout>
      </el-main>
    </el-container>

    <!--    <basic-container>
    <el-container>
      <el-aside width="200px">
        <wf-category @node-click="nodeClick"
                     @list-change="findObject(option.column, 'category').dicData = $event"></wf-category>
      </el-aside>
      <el-main style="margin-left: 10px;">
        <avue-crud :option="option"
                   :table-loading="loading"
                   :data="data"
                   :page.sync="page"
                   :permission="permissionList"
                   v-model="form"
                   @row-del="rowDel"
                   @search-change="searchChange"
                   @search-reset="searchReset"
                   @selection-change="selectionChange"
                   @current-change="currentChange"
                   @size-change="sizeChange"
                   @refresh-change="onLoad(page, query)"
                   @on-load="onLoad">
          <template #menuLeft>
            <el-button type="success"
                       size="mini"
                       icon="el-icon-connection"
                       v-if="permission.wf_design_deployment_category"
                       @click="handleChangeCategory"> 更改分类
            </el-button>
          </template>
          <template slot="status"
                    slot-scope="{row}">
            <el-tag size="small"
                    :type="row.status == 1? '': 'danger'">{{row.$status}}</el-tag>
          </template>
          <template slot="menu"
                    slot-scope="{row}">
            <el-button v-if="permission.wf_design_deployment_status && row.status == 1"
                       type="text"
                       size="small"
                       icon="el-icon-refresh-left"
                       @click="handleChangeStatus(row, 'suspended')">挂起</el-button>
            <el-button v-if="permission.wf_design_deployment_status && row.status == 2"
                       type="text"
                       size="small"
                       icon="el-icon-refresh-right"
                       @click="handleChangeStatus(row, 'active')">激活</el-button>
            <el-button v-if="permission.wf_design_deployment_category"
                       type="text"
                       size="small"
                       icon="el-icon-pie-chart"
                       @click="handleCategory(row)">更改分类</el-button>
          </template>
        </avue-crud>
      </el-main>
    </el-container>

    <el-dialog :visible.sync="categoryVisible"
               append-to-body
               title="选择分类">
      <avue-form v-if="categoryVisible"
                 v-model="form"
                 :option="{column:[{type:'tree',label:'流程分类',span:24,props:{label:'name',value:'id'},prop:'category',dicUrl:'/api/sinoma-workflow/design/category/tree',required:true,rules:[{required:true,message:'请选择分类'}]}]}"
                 @submit="handleCategorySubmit"></avue-form>
    </el-dialog>
  </basic-container>-->
    <CommonDialog v-if="categoryVisible" :dialogTitle="$t('cip.plat.wfDesign.deployment.title.arrangeHeadTitle')"  width="30%" @cancel="categoryVisible = false" @confirm="deploySubmit">
      <avue-form v-if="categoryVisible"
        v-model="form"
        ref="deploySubmit"
          :option="{
            menuBtn: false,
            column: [
              {
                type: 'tree',
                label: this.$t(`cip.plat.wfDesign.deployment.field.category`),
                span: 24,
                props: { label: 'name', value: 'id' },
                prop: 'category',
                dicUrl: '/api/sinoma-workflow/design/category/tree',
                required: true,
                rules: [{
                  required: true,
                  message: this.$t(`cip.cmn.rule.selectWarning`)+this.$t(`cip.plat.wfDesign.model.field.category`),
                }]
              }
            ]
          }"
        @submit="handleCategorySubmit"></avue-form>
    </CommonDialog>
  </div>
</template>

<script>
import { getList, changeStatus, changeCategory, remove } from "@/api/plugin/workflow/deployment";

import { mapGetters } from "vuex";

import WfCategory from '../process/components/category.vue'
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import CommonDialog from "@/components/CommonDialog"
export default {
  components: { WfCategory, GridLayout, HeadLayout, CommonDialog },
  data () {
    return {
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        size: 'mini',
        searchSize: 'mini',
        height: 'auto',
        calcHeight: 30,
        tip: false,
        border: true,
        selection: true,
        dialogType: 'drawer',
        addBtn: false,
        editBtn: false,
        align: 'center',
        searchMenuSpan: 6,
        menuWidth: 300,
        column: [
          {
            label: "id",
            prop: "id",
            overHidden: true,
            width: 200,
            align: "left",
          },
          {
            label: this.$t('cip.plat.wfDesign.deployment.field.name'),
            prop: "name",
            overHidden: true,
            search: true,
            width: 200,
            align: "left",
          },
          {
            label: this.$t('cip.plat.wfDesign.deployment.field.key'),
            prop: "key",
            overHidden: true,
            search: true,
            align: "left",
          },
          {
            label: this.$t('cip.plat.wfDesign.deployment.field.category'),
            prop: "category",
            overHidden: true,
            type: 'tree',
            dicData: [],
            width: 120,
            align: "left",
            props: {
              label: 'name',
              value: 'id'
            },
          },
          {
            label: this.$t('cip.plat.wfDesign.deployment.field.version'),
            prop: 'version',
            align:'right',
            overHidden: true,
            width: 90
          },
          {
            label: this.$t('cip.plat.wfDesign.deployment.field.status'),
            prop: 'status',
            align:'center',
            overHidden: true,

            // dicData: [{
            //   label: this.$t('cip.plat.wfDesign.deployment.field.activate'),
            //   value: 1
            // }, {
            //   label: this.$t('cip.plat.wfDesign.deployment.field.pending'),
            //   value: 2
            // }],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=activate_pending",
            width: 100,
            slot: true
          },
          {
            label: this.$t('cip.plat.wfDesign.deployment.field.deployTime'),
            prop: 'deployTime',
            align:'center',
            overHidden: true,
            width: 200
          },
        ]
      },
      data: [],
      categoryVisible: false,
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.deployment_add, false),
        viewBtn: this.vaildData(this.permission.deployment_view, false),
        delBtn: this.vaildData(this.permission.wf_design_deployment_delete, false),
        editBtn: this.vaildData(this.permission.deployment_edit, false)
      };
    },
    ids () {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.deploymentId);
      });
      return ids.join(",");
    },
    searchColumns () {
      return [
        {
          label: this.$t('cip.plat.wfDesign.deployment.field.name'),
          prop: "name",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.wfDesign.deployment.field.name'),
        },
        {
          label: this.$t('cip.plat.wfDesign.deployment.field.key'),
          prop: "key",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.wfDesign.deployment.field.key'),
        },
      ]
    },
    headBtnOptions () {
      let result = [];
      if (this.permission.wf_design_deployment_category) {
        result.push({
          label: this.$t(`cip.plat.wfDesign.deployment.btn.changeTypeBtn`),
          emit: "head-delete",
          type: "danger",
          icon: "el-icon-delete",
        });
      }
      return result;
    },
    // 行按钮添加 add by steve
    gridRowBtn () {

      let result = [];
      if (this.permission.wf_design_deployment_status) {
        result.push({
          label: this.$t(`cip.plat.wfDesign.deployment.btn.pendingBtn`),
          emit: "row-sj",
          type: "primary",
          icon: "el-icon-plus",
        });
      }

      if (this.permission.wf_design_deployment_delete) {
        result.push({
          label: this.$t(`cip.plat.wfDesign.deployment.btn.activateBtn`),
          emit: "row-bs",
          type: "primary",
          icon: "el-icon-plus",
        });
      }

      if (this.permission.wf_design_deployment_category) {
        result.push({
          label: this.$t(`cip.plat.wfDesign.deployment.btn.changeTypeBtn`),
          emit: "row-qx",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      return result;
    },
  },
  methods: {
    // 列表查询 add by steve
    gridHeadSearch (searchForm) {
      this.onLoad(this.page, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty (searchForm) {
      this.onLoad(this.page, searchForm);
    },
    handleChangeCategory () {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.categoryVisible = true
    },
    nodeClick ({ id }) {
      this.categoryId = id
      this.searchChange(this.query)
    },
    deploySubmit(){
      this.$refs.deploySubmit.submit()
    },
    handleCategorySubmit (form, done) {
      let { deploymentId, category } = form
      if (!deploymentId) deploymentId = this.ids
      changeCategory({ deploymentId, category }).then(() => {
        this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
        done()
        this.form = {}
        this.categoryVisible = false
        this.onLoad(this.page, this.query)
      })
    },
    handleCategory (row) {
      this.form.deploymentId = row.deploymentId
      this.categoryVisible = true
    },
    handleChangeStatus (row, status) {
      const param = {
        id: row.id,
        status
      }
      changeStatus(param).then(() => {
        this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
        this.onLoad(this.page, this.query)
      })
    },
    rowDel (row) {
      this.$confirm(this.$t(`cip.plat.wfDesign.deployment.msg.remove`), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      }).then(() => {
        this.$confirm(this.$t(`cip.plat.wfDesign.deployment.msg.reconfirm`), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: "warning"
        }).then(() => {
          remove({ deploymentId: row.deploymentId }).then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: this.$t('cip.cmn.msg.success.operateSuccess'),
            })
          })
        })
      })
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess'),
          });
          this.$refs.crud.toggleSelection();
        });
    },
    searchReset () {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange (params, done) {
      this.query = params;
      this.onLoad(this.page, params);
      done()
    },
    selectionChange (list) {
      this.selectionList = list;
    },
    currentChange (currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange (pageSize) {
      this.page.pageSize = pageSize;
    },
    onLoad (page, params = {}) {
      this.page = page
      this.loading = true;

      if (this.categoryId) params['category'] = this.categoryId
      else delete params['category']

      getList(page.currentPage, page.pageSize, Object.assign(params, this.$refs.gridHeadLayout.searchForm)).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.data = data.records;
        this.loading = false;
      });
    }
  },
  mounted () {
    this.onLoad(this.page, {});
  },
};
</script>

<style>
</style>
