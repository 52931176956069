<template>
  <div>
    <el-drawer
      direction="rtl"
      :with-header="false"
      size="1050px"
      title="$t(`cip.index.theme.dynQuery`)+$t(`cip.index.theme.list`)"
      :visible.sync="visible"
      v-if="visible"
      @close="closeddrawer"
    >
      <head-layout
        :head-btn-options="headBtnOptions"
        @head-line="headShowLine1"
        :head-title="headTitle1"
      ></head-layout>
      <grid-layout
        ref="gridLayOut"
        class="dynTable"
        :page="page1"
        :grid-row-btn="gridRowBtn"
        :table-options="tableOptions"
        :table-data.sync="tableData"
        :table-loading="tableLoading"
        :data-total="dataTotal"
        @page-current-change="handleCurrentChange1"
        @page-size-change="handleCurrentSize1"
        @page-refresh-change="onLoad"
        @row-query="rowQuery1"
        v-if="!showLine1"
      >
      </grid-layout>
      <div id="line1" style="height: 300px; width: 100%" v-if="showLine1"></div>

      <head-layout
        :head-btn-options="headBtnOptions"
        @head-line="headShowLine2"
        v-if="table2Show"
        :head-title="headTitle2 + headParam2"
      ></head-layout>
      <grid-layout
        ref="gridLayOut2"
        class="dynTable"
        :page="page2"
        v-if="table2Show && !showLine2"
        :grid-row-btn="gridRowBtn"
        :table-options="tableOptions2"
        :table-data.sync="tableData2"
        :table-loading="tableLoading2"
        :data-total="dataTotal2"
        @page-current-change="handleCurrentChange2"
        @page-size-change="handleCurrentSize2"
        @page-refresh-change="onLoad2"
        @row-query="rowQuery2"
      >
      </grid-layout>
      <div id="line2" style="height: 300px; width: 100%" v-if="showLine2"></div>
      <br />

      <head-layout
        :head-btn-options="headBtnOptions"
        @head-line="headShowLine3"
        v-if="table3Show"
        :head-title="headTitle3 + headParam3"
      ></head-layout>
      <grid-layout
        ref="gridLayOut3"
        class="dynTable"
        :page="page3"
        v-if="table3Show && !showLine3"
        :grid-row-btn="gridRowBtn"
        :table-options="tableOptions3"
        :table-data.sync="tableData3"
        :table-loading="tableLoading3"
        :data-total="dataTotal3"
        @page-current-change="handleCurrentChange3"
        @page-size-change="handleCurrentSize3"
        @page-refresh-change="onLoad3"
        @row-query="rowQuery3"
      >
      </grid-layout>
      <div id="line3" style="height: 300px; width: 100%" v-if="showLine3"></div>
      <br />
      <head-layout
        :head-btn-options="headBtnOptions"
        @head-line="headShowLine4"
        v-if="table4Show"
        :head-title="headTitle4 + headParam4"
      ></head-layout>
      <grid-layout
        ref="gridLayOut4"
        class="dynTable"
        v-if="table4Show && !showLine4"
        :page="page4"
        :grid-row-btn="gridRowBtn"
        :table-options="tableOptions4"
        :table-data.sync="tableData4"
        :table-loading="tableLoading4"
        :data-total="dataTotal4"
        @page-current-change="onLoad4"
        @page-size-change="onLoad4"
        @page-refresh-change="onLoad4"
      >
      </grid-layout>
      <div id="line4" style="height: 300px; width: 100%" v-if="showLine4"></div>
      <div id="compositeIndex" style="height: 300px; width: 100%" v-if="showIndexCompositeIndex">
        <composite-index-table
          ref="compositeIndexTable"
          :compositeParams="compositeParams"
        ></composite-index-table>
      </div>
      <div class="avue-dialog__footer">
        <el-button size="small" @click="closeBtn">{{
          $t("cip.cmn.btn.celBtn")
        }}</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import gridLayout from "@/views/components/layout/grid-layout";
import { mapGetters } from "vuex";
import { appIndexDataApi } from "@/api/index/indexManage";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import CompositeIndexTable from "./compositeIndexTable";

export default {
  name: "dynTableDialog",
  data() {
    return {
      rowInfo2: {},
      rowInfo3: {},
      rowInfo4: {},
      page1: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      page2: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      page3: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      page4: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      indexCode1: "",
      headTitle1: this.$t(`cip.index.omission.headTitle1`)+[],
      headTitle2: this.$t(`cip.index.omission.headTitle2`),
      headParam2: "",
      headTitle3: this.$t(`cip.index.omission.headTitle2`),
      headParam3: "",
      headTitle4: this.$t(`cip.index.omission.headTitle2`),
      headParam4: "",
      tableData: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
      dataForm: {},
      visible: false,
      tableLoading: false,
      tableLoading2: false,
      tableLoading3: false,
      tableLoading4: false,
      dataTotal: 0,
      dataTotal2: 0,
      dataTotal3: 0,
      dataTotal4: 0,
      tableOptionsDyn: [],
      tableOptionsDyn2: [],
      tableOptionsDyn3: [],
      tableOptionsDyn4: [],
      table2Show: false,
      table3Show: false,
      table4Show: false,
      query: {
        indexCode: "",
      },
      xdata: [[], [], [], []],
      ydata: [[], [], [], []],
      legend: [[], [], [], []],
      showLine1: false,
      showLine2: false,
      showLine3: false,
      showLine4: false,
      // 复合指标
      showIndexCompositeIndex: false,
      compositeParams: {},
    };
  },
  components: {
    gridLayout,
    CompositeIndexTable,
  },
  computed: {
    ...mapGetters(["permission"]),
    headBtnOptions() {
      return [
        {
          label: this.$t(`cip.index.omission.convert`),
          emit: "head-line",
          type: "button",
          icon: "",
          btnOptType: "",
        },
      ];
    },
    gridHeadBtn() {
      return [];
    },
    // 搜索栏
    searchColumns() {
      return [];
    },
    gridRowBtn() {
      return [
        {
          // label: this.$t(`cip.cmn.btn.delBtn`),
          label: this.$t(`cip.index.omission.seedata`),
          emit: "row-query",
          type: "button",
          icon: "",
        },
      ];
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: this.$t(`cip.index.omission.indexLabel`),
        align: "center",
        menu: true,
        selection: false,
        column: this.tableOptionsDyn,
      };
    },
    tableOptions2() {
      return {
        index: true,
        indexLabel: this.$t(`cip.index.omission.indexLabel`),
        align: "center",
        menu: true,
        selection: false,
        column: this.tableOptionsDyn2,
      };
    },
    tableOptions3() {
      return {
        index: true,
        indexLabel: this.$t(`cip.index.omission.indexLabel`),
        align: "center",
        menu: true,
        selection: false,
        column: this.tableOptionsDyn3,
      };
    },
    tableOptions4() {
      return {
        index: true,
        indexLabel: this.$t(`cip.index.omission.indexLabel`),
        align: "center",
        menu: false,
        selection: false,
        column: this.tableOptionsDyn4,
      };
    },
  },
  props: {
    headForm: {
      type: Object,
    },
    type: {
      type: String,
      default: "view",
    },
  },
  methods: {
    openDialog(data) {
      this.$nextTick(() => {
        this.initParam();
        this.dataForm.indexCode = data.indexCode

        if (data) {
          Object.assign(this.dataForm, data);
        }
        this.headForm.indexCode = data.indexCode
        if (this.headForm) {
          Object.assign(this.dataForm, this.headForm);
        }
        this.headTitle1 = this.$t(`cip.index.omission.headTitle1`)+"[" + this.dataForm.indexCode + "]";
        this.tableOptionsDyn = [];
        this.visible = !this.visible;
        this.onLoad(
          { pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 },
          { indexCode: this.dataForm.indexCode }
        );
      });
    },
    gridHeadSearch(query) {
      this.onLoad(
        { pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 },
        query
      );
    },
    gridHeadEmpty(query) {
      this.onLoad(
        { pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 },
        query
      );
    },
    getTableColumnCell(data) {
      const _this = this;
      if (data && data.length > 0) {
        Object.keys(data[0])
          .sort(function (a, b) {
            return a.length - b.length;
          })
          .forEach((key) => {
            _this.tableOptionsDyn.push({
              label: key,
              prop: key,
            });
          });
      }


    },
    getTableColumnCell2(data) {
      const _this = this;
      if (data && data.length > 0) {
        Object.keys(data[0])
          .sort(function (a, b) {
            return a.length - b.length;
          })
          .forEach((key) => {
            _this.tableOptionsDyn2.push({
              label: key,
              prop: key,
            });
          });
      }
    },
    getTableColumnCell3(data) {
      const _this = this;
      if (data && data.length > 0) {
        Object.keys(data[0])
          .sort(function (a, b) {
            return a.length - b.length;
          })
          .forEach((key) => {
            _this.tableOptionsDyn3.push({
              label: key,
              prop: key,
            });
          });
      }
    },
    getTableColumnCell4(data) {
      const _this = this;
      if (data && data.length > 0) {
        Object.keys(data[0])
          .sort(function (a, b) {
            return a.length - b.length;
          })
          .forEach((key) => {
            _this.tableOptionsDyn4.push({
              label: key,
              prop: key,
            });
          });
      }
    },
    closeBtn() {
      this.visible = false;
      this.initParam();
      console.log("关闭");
       this.page1.currentPage = 1
      this.page1.pageSize= PAGE_CONSTANT.DEFAULT_PAGE_SIZE
      this.page2.currentPage = 1
      this.page2.pageSize= PAGE_CONSTANT.DEFAULT_PAGE_SIZE
      this.page3.currentPage = 1
      this.page3.pageSize= PAGE_CONSTANT.DEFAULT_PAGE_SIZE
      this.page4.currentPage = 1
      this.page4.pageSize= PAGE_CONSTANT.DEFAULT_PAGE_SIZE
    },
    initParam() {
      this.dataForm = {};
      this.tableOptionsDyn = [];
      this.tableOptionsDyn1 = [];
      this.tableOptionsDyn2 = [];
      this.tableOptionsDyn3 = [];
      this.tableData = [];
      this.tableData1 = [];
      this.tableData2 = [];
      this.tableData3 = [];
      this.table2Show = false;
      this.table3Show = false;
      this.table4Show = false;
      this.showLine2 = false;
      this.showLine3 = false;
      this.showLine4 = false;
      this.showIndexCompositeIndex = false;
    },
    /**
     *
     * @param data 所有的数据
     * @param property 需要为key的字段
     * @returns {*}
     */
    assembleData(data, property) {
      return data.reduce((acc, obj) => {
        let key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        //返回的数据
        acc[key].push(obj.proDcsVal);
        return acc;
      }, {});
    },
    assembleData2(data, property) {
      let arr = [];
      data.forEach((element) => {
        arr.push(element[property]);
      });
      return arr;
    },
    onLoad(page, params = {}) {

      this.tableOptionsDyn = [];
      const _this = this;
      // this.visible=false
      _this.tableLoading = true;
      // console.log("_this.dataForm", _this.dataForm);
      // console.log("_this.query", _this.query);
      _this.query.indexCode = _this.dataForm.indexCode;
      _this.indexCode1 = _this.query.indexCode;
      // console.log("_this.indexCode1", _this.indexCode1);
      _this.dataTotal = 0;
      appIndexDataApi(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        _this.getTableColumnCell(data.records);
        _this.dataTotal = data.total;
        _this.tableData = data.records;
        _this.page1.total = data.total
        _this.tableLoading = false;
        _this.$nextTick(() => {
          // _this.visible=true

          // _this.$refs.gridLayOut.$refs.grid.$refs.table.refreshTable();
        });
      });
    },
    /**
     * @param {{pageSize: number, currentPage: number}} page
     * @param {*} params 请求参数，这里一般是下钻记录行数据
     * @param {*} level 下钻层级 1、2、3
     */
    onLoad2(page, params, level = 1, indexCode = "") {
      const _this = this;
      if (indexCode == "") {
        _this.query.indexCode = this.convertIndexCode(level);
      } else {
        _this.query.indexCode = indexCode;
      }
      if (!params._time) {
        params = this.rowInfo2;
      }
      params = this.genParam(_this.query.indexCode, params);
      console.log("params:", params);
      //构造下钻查询条件
      if (_this.query.indexCode == "") {
        this.$message.warning(this.$t(`cip.index.omission.message1`));
        return;
      }
      switch (level) {
        case 1:
          (_this.headTitle2 = this.$t(`cip.index.omission.headTitle2`)+"[" + _this.query.indexCode + "]"),
            (_this.headParam2 =
              "[" +
              JSON.stringify(params)
                .toString()
                .replaceAll('"', "")
                .replaceAll("{", "")
                .replaceAll("}", "") +
              "]");
          _this.table2Show = true;
          _this.tableOptionsDyn2 = [];
          // _this.table2Show = false
          _this.tableLoading2 = true;
          _this.dataTotal2 = 0;
          break;
        case 2:
          (_this.headTitle3 = this.$t(`cip.index.omission.headTitle2`)+"[" + _this.query.indexCode + "]"),
            (_this.headParam3 =
              "[" +
              JSON.stringify(params)
                .toString()
                .replaceAll('"', "")
                .replaceAll("{", "")
                .replaceAll("}", "") +
              "]");
          _this.table3Show = true;
          _this.tableOptionsDyn3 = [];

          _this.tableLoading3 = true;
          _this.dataTotal3 = 0;
          break;
        case 3:
          (_this.headTitle4 = this.$t(`cip.index.omission.headTitle2`)+"[" + _this.query.indexCode + "]"),
            (_this.headParam4 =
              "[" +
              JSON.stringify(params)
                .toString()
                .replaceAll('"', "")
                .replaceAll("{", "")
                .replaceAll("}", "") +
              "]");
          _this.table4Show = true;
          _this.tableOptionsDyn4 = [];

          _this.tableLoading4 = true;
          _this.dataTotal4 = 0;
          break;
        default:
          (_this.headTitle2 = this.$t(`cip.index.omission.headTitle2`)+"[" + _this.query.indexCode + "]"),
            (_this.headParam2 =
              "[" +
              JSON.stringify(params)
                .toString()
                .replaceAll('"', "")
                .replaceAll("{", "")
                .replaceAll("}", "") +
              "]");
          _this.table2Show = true;
          _this.tableOptionsDyn2 = [];
          // _this.table2Show = false
          _this.tableLoading2 = true;
          _this.dataTotal2 = 0;
          break;
      }
      appIndexDataApi(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        switch (level) {
          case 1:
            _this.getTableColumnCell2(data.records);

            _this.dataTotal2 = data.total;
            _this.page2.total = data.total
            _this.tableData2 = data.records;
            _this.tableLoading2 = false;
            _this.$nextTick(() => {
              _this.$refs.gridLayOut2.$refs.grid.$refs.table.doLayout();
            });
            break;
          case 2:
            _this.getTableColumnCell3(data.records);
            // _this.table3Show = true
            _this.dataTotal3 = data.total;
            _this.tableData3 = data.records;
            _this.page3.total = data.total
            _this.tableLoading3 = false;
            _this.$nextTick(() => {
              _this.$refs.gridLayOut3.$refs.grid.$refs.table.doLayout();
            });
            break;
          case 3:
            _this.getTableColumnCell4(data.records);
            // _this.table4Show = true
            _this.dataTotal4 = data.total;
            _this.tableData4 = data.records;
            _this.page4.total = data.total
            _this.tableLoading4 = false;
            _this.$nextTick(() => {
              this.$refs.gridLayOut4.$refs.grid.$refs.table.doLayout();
            });
            break;
          default:
            _this.getTableColumnCell2(data.records);
            // _this.table2Show = true
            _this.dataTotal2 = data.total;
            _this.tableData2 = data.records;
            _this.page2.total = data.total
            _this.tableLoading2 = false;
            _this.$nextTick(() => {
              _this.$refs.gridLayOut2.$refs.grid.$refs.table.doLayout();
            });
            this.getMatching(_this.tableData2, 1);
            break;
        }
      });
    },
    onLoad3(page, params) {
      this.onLoad2(page, params, 2);
    },
    onLoad4(page, params, level) {
      this.onLoad2(page, params, 3);
    },
    /**
     * 构造下钻查询条件，如果是年下钻到月，则传年份_year，如果是月下钻到日，则传年份、月份，如果是日下钻到小时，则传年份、月份、小时
     * @param {{}} param
     * @param {number} level
     */
    genParam(indexCode, params = {}) {
      let indexCodeLast = indexCode.substr(-2);
      switch (indexCodeLast) {
        case "_Y":
          return { _year: params._time };
          break;
        case "_M":
          return { _year: params._time };
          break;
        case "_D":
          console.log(params._time, "999999999");
          return {
            _year: params._time.substr(0, 4),
            _month: params._time.substr(5, 2),
          };
          break;
        case "_H":
          return {
            _year: params._time.substr(0, 4),
            _month: params._time.substr(5, 2),
            _day: params._time.substr(8, 2),
          };
          break;
        default:
          return {};
      }
    },
    convertIndexCode(level) {
      //根据指标编码和下钻层级，返回下钻指标编码，如 INDEX_ITEM1#_YIELD_SN325_Y 进行下钻，则指标编码为 INDEX_ITEM1#_YIELD_SN325_M
      let indexCodeLevelMap = new Map();
      indexCodeLevelMap.set("_Y", 1);
      indexCodeLevelMap.set("_M", 2);
      indexCodeLevelMap.set("_D", 3);
      indexCodeLevelMap.set("_H", 4);
      indexCodeLevelMap.set(1, "_Y");
      indexCodeLevelMap.set(2, "_M");
      indexCodeLevelMap.set(3, "_D");
      indexCodeLevelMap.set(4, "_H");
      //判断指标编码类型 _Y：年 _M 月 _D 日 _H 小时
      let indexCodeLast = this.dataForm.indexCode.substr(-2);
      let indexCodePre = this.dataForm.indexCode.substr(
        0,
        this.dataForm.indexCode.length - 2
      );
      if (indexCodeLevelMap.has(indexCodeLast)) {
        let indexCodeLevel = indexCodeLevelMap.get(indexCodeLast);
        indexCodeLevel = indexCodeLevel + level;
        if (indexCodeLevel <= 4) {
          return indexCodePre + indexCodeLevelMap.get(indexCodeLevel);
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    rowQuery1(row) {
      this.rowInfo2 = row;
      if (this.dataForm.confType == "COMPOSITE_INDEX") {
        this.compositeParams = {
          indexCode: this.dataForm.indexCode,
          time: row["_time"]
        }
        this.showIndexCompositeIndex = true
        this.$nextTick(() => {
          this.$refs.compositeIndexTable.onLoad()
        })
      } else {
        //如果是SQL公式或字段映射，则按照年、月、日、班组、小时下钻
        this.onLoad2(
          { pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 },
          row,
          1
        );
      }
    },
    rowQuery2(row) {
      this.rowInfo3 = row;
      this.onLoad2(
        { pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 },
        row,
        2
      );
    },
    rowQuery3(row) {
      this.rowInfo4 = row;
      this.onLoad2(
        { pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 },
        row,
        3
      );
    },
    genLineChart(data, lineNum) {
      let that = this;
      this.xdata[lineNum] = [];
      this.ydata[lineNum] = [];
      this.legend[lineNum] = [];
      let yseriesValue = this.assembleData2(data, "_value");
      let yseriesSValue = this.assembleData2(data, "_simulation_value");
      let xseries = this.assembleData(data, "_time");
      this.legend[lineNum].push(this.$t(`cip.index.omission.original`), this.$t(`cip.index.omission.analog`));
      let yvalue = [];
      for (const key in yseriesValue) {
        yvalue.push(key);
      }

      this.ydata[lineNum].push({
        // name: 'value',
        name: this.$t(`cip.index.omission.original`),
        type: "line",
        stack: "Total",
        data: yseriesValue,
        triggerLineEvent: true,
        emphasis: { focus: "series" },
      });
      let yvalue2 = [];
      for (const key in yseriesSValue) {
        yvalue2.push(key);
      }

      this.ydata[lineNum].push({
        // name: 'simulation_value',
        name: this.$t(`cip.index.omission.analog`),
        type: "line",
        stack: "Total",
        data: yseriesSValue,
        triggerLineEvent: true,
        emphasis: { focus: "series" },
      });
      for (const key in xseries) {
        this.xdata[lineNum].push(key);
      }
      let myChart = this.$echarts.getInstanceByDom(
        document.getElementById("line" + (lineNum + 1))
      );
      let option = {
        tooltip: {
          // trigger: 'line'+lineNum,
          // // position: function (pt) {
          // //   return [pt[0], '10%'];
          // // }
        },
        legend: {
          data: this.legend[lineNum],
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: that.xdata[lineNum],
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"],
        },
        series: that.ydata[lineNum],
      };
      //检测是否已经存在echarts实例，如果不存在，则不再去初始化
      if (myChart == null) {
        myChart = this.$echarts.init(
          document.getElementById("line" + (lineNum + 1))
        );
      }
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    headShowLine1() {
      let _this = this;
      _this.showLine1 = !_this.showLine1;
      console.log("show:" + _this.showLine1);
      if (_this.showLine1) {
        _this.$nextTick(() => {
          _this.genLineChart(this.tableData, 0);
        });
      }
      _this.dataTotal = 10;
    },
    headShowLine2() {
      let _this = this;
      _this.showLine2 = !_this.showLine2;
      console.log("show:" + _this.showLine2);
      if (_this.showLine2) {
        _this.$nextTick(() => {
          _this.genLineChart(this.tableData2, 1);
        });
      }
    },
    headShowLine3() {
      let _this = this;
      _this.showLine3 = !_this.showLine3;
      console.log("show:" + _this.showLine3);
      if (_this.showLine3) {
        _this.$nextTick(() => {
          _this.genLineChart(this.tableData3, 2);
        });
      }
    },
    headShowLine4() {
      let _this = this;
      _this.showLine4 = !_this.showLine4;
      console.log("show:" + _this.showLine4);
      if (_this.showLine4) {
        _this.$nextTick(() => {
          _this.genLineChart(this.tableData4, 3);
        });
      }
    },
    closeddrawer() {
      this.showLine1 = false;
      console.log("关闭");
      this.page1.currentPage = 1
      this.page1.pageSize= PAGE_CONSTANT.DEFAULT_PAGE_SIZE
      this.page2.currentPage = 1
      this.page2.pageSize= PAGE_CONSTANT.DEFAULT_PAGE_SIZE
      this.page3.currentPage = 1
      this.page3.pageSize= PAGE_CONSTANT.DEFAULT_PAGE_SIZE
      this.page4.currentPage = 1
      this.page4.pageSize= PAGE_CONSTANT.DEFAULT_PAGE_SIZE
    },
    handleCurrentChange1(val) {
      this.page1.currentPage = val.currentPage;
      this.onLoad(this.page1);
    },
    handleCurrentSize1(val) {
      this.page1.currentPage = 1;
      this.page1.pageSize = val.pageSize;
      this.onLoad(this.page1);
    },
    getCode(str){
      let index1 = str.indexOf("[");
      let index2 = str.indexOf("]");
      str = str.substring(index1 + 1, index2);
      return str
    },
    handleCurrentChange2(val) {
      this.page2.currentPage = val.currentPage;
      this.onLoad2(this.page2, this.rowInfo2, 1,this.getCode(this.headTitle2));
    },
    handleCurrentSize2(val) {
      this.page2.currentPage = 1;
      this.page2.pageSize = val.pageSize;
      this.onLoad2(this.page2, this.rowInfo2, 1, this.getCode(this.headTitle2));
    },
    handleCurrentChange3(val) {
      this.page3.currentPage = val.currentPage;
      this.onLoad2(this.page3, this.rowInfo3, 2, this.getCode(this.headTitle3));
    },
    handleCurrentSize3(val) {
      this.page3.currentPage = 1;
      this.page3.pageSize = val.pageSize;
      this.onLoad2(this.page3, this.rowInfo3, 2,this.getCode(this.headTitle3));
    },
    handleCurrentChange4(val) {
      this.page4.currentPage = val.currentPage;
      this.onLoad2(this.page4, this.rowInfo4, 3,this.getCode(this.headTitle4));
    },
    handleCurrentSize4(val) {
      this.page4.currentPage = 1;
      this.page4.pageSize = val.pageSize;
      this.onLoad2(this.page4, this.rowInfo4, 3,this.getCode(this.headTitle4));
    },
  },
  mounted() {

  },
};
</script>

<style scoped lang="scss">
::v-deep .dynTable .avue-crud .el-table {
  height: calc(100vh - 370px) !important;
  max-height: calc(100vh - 370px) !important;
}
</style>
